import React from "react";
import Typography from "@material-ui/core/Typography";
import {APP_NAME, APP_URL} from "../../constants";


function Footer(){
    return(
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a color="inherit" target='blank' href={APP_URL}>
                {APP_NAME}
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Footer;
