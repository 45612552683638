import React, {Component} from "react";
import {
    Button, Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel, List, ListItem, ListItemText, MenuItem,
    OutlinedInput,
    Select,
    TextField, Typography
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Phone} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import {number} from "prop-types";

@inject("DataStore", "LanguageStore","AddressStore","AccountStore")
@observer
class AddAddress extends Component {
    state = {
        name: "",
        phone: "",
        country: "212",
        city: "",
        town: "",
        district: "",
        neighborhood: "",
        text: "",
        lat:41.0082,
        long:28.9784,
        direction: "",
        cities: [],
        towns: [],
        districts: [],
        neighborhoods: []
    }

    async componentDidMount() {
        await this.props.DataStore.getCities();
        this.setState({cities: this.props.DataStore.cities});
    }

    async handleSelectCity(id) {
        if (id) {
            await this.setState({city: id});
            await this.props.DataStore.getCity(id);
            this.setState({towns: this.props.DataStore.city.towns, districts: [], neighborhoods: []});
        } else {
            await this.setState({city: id, town: "", district: "", neighborhood: ""});
        }
    }

    async handleSelectTown(id) {
        if (id) {
            await this.setState({town: id});
            //await this.props.DataStore.getCity(id);
            let town = this.state.towns.find((town) => (town.id === id));
            this.setState({districts: town.districts, neighborhoods: []});
        } else {
            await this.setState({town: id, district: "", neighborhood: ""});
        }
    }

    async handleSelectDistrict(id) {
        if (id) {
            await this.setState({district: id});
            await this.props.DataStore.getDistrict(id);
            this.setState({neighborhoods: this.props.DataStore.district.neighborhoods});
        } else {
            await this.setState({district: id, neighborhood: ""});
        }
    }

    async handleSelectNeighborhood(id) {
        await this.setState({neighborhood: id});
    }

    async handleSave() {
        console.log(this.state);
        await this.props.AddressStore.addAddress(this.state.name, this.state.phone, this.state.country, this.state.city, this.state.town, this.state.district, this.state.neighborhood, this.state.text, this.state.direction, 1, this.state.lat, this.state.long);
        await this.setState({name: "", phone: "", country: "212", city: "", town: "", district: "", neighborhood: "", text: "", direction: ""});
        this.props.modifyParentState(false);
        await this.props.AccountStore.getAccount();
    }

    render() {
        const {classes} = this.props;
        return (
            <Grid container justify={"center"} spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-name">{this.props.LanguageStore.language.NAME}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-name"
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-phone">{this.props.LanguageStore.language.PHONE}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-phone"
                            value={this.state.phone}
                            type={"number"}
                            onChange={(e) => this.setState({phone: e.target.value})}
                            startAdornment={<InputAdornment position="start"><Phone/></InputAdornment>}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <FormControl variant="outlined" className={clsx(classes.margin, classes.textField)}>
                        <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.CITY}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.city}
                            onChange={(e) => this.handleSelectCity(e.target.value)}
                            label={this.props.LanguageStore.language.CITY}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {this.state.cities.map((city, index) => (
                                <MenuItem key={index} value={city.id}>{city.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {(this.state.city && this.state.towns.length > 0) ? (
                        <FormControl variant="outlined" className={clsx(classes.margin, classes.textField)}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.TOWN}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.town}
                                onChange={(e) => this.handleSelectTown(e.target.value)}
                                label={this.props.LanguageStore.language.TOWN}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {this.state.towns?.map((town, index) => (
                                    <MenuItem key={index} value={town.id}>{town.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ):(<List><ListItem><ListItemText inset secondary={`*${this.props.LanguageStore.language.TOWN}`} /></ListItem></List>)}
                    {(this.state.town && this.state.districts.length > 0) ? (
                        <FormControl variant="outlined" className={clsx(classes.margin, classes.textField)}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.DISTRICT}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.district}
                                onChange={(e) => this.handleSelectDistrict(e.target.value)}
                                label={this.props.LanguageStore.language.DISTRICT}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {this.state.districts?.map((district, index) => (
                                    <MenuItem key={index} value={district.id}>{district.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ):(<List><ListItem><ListItemText inset secondary={`*${this.props.LanguageStore.language.DISTRICT}`} /></ListItem></List>)}
                    {(this.state.district && this.state.neighborhoods.length > 0) ? (
                        <FormControl variant="outlined" className={clsx(classes.margin, classes.textField)}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.NEIGHBORHOOD}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.neighborhood}
                                onChange={(e) => this.handleSelectNeighborhood(e.target.value)}
                                label={this.props.LanguageStore.language.NEIGHBORHOOD}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {this.state.neighborhoods?.map((neighborhood, index) => (
                                    <MenuItem key={index} value={neighborhood.id}>{neighborhood.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ):(<List><ListItem><ListItemText inset secondary={`*${this.props.LanguageStore.language.NEIGHBORHOOD}`} /></ListItem></List>)}
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-address">{this.props.LanguageStore.language.ADDRESS}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-address"
                            value={this.state.text}
                            multiline
                            rows={3}
                            fullWidth={true}
                            onChange={(e) => this.setState({text: e.target.value})}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-direction">{this.props.LanguageStore.language.DIRECTION}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-direction"
                            value={this.state.direction}
                            fullWidth={true}
                            onChange={(e) => this.setState({direction: e.target.value})}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}></Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-latitude">Latitude</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-latitude"
                            value={this.state.lat}
                            fullWidth={true}
                            readOnly
                            type={"number"}
                            onChange={(e) => this.setState({lat: e.target.value})}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-longitude">Longitude</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-longitude"
                            value={this.state.long}
                            fullWidth={true}
                            readOnly
                            type={"number"}
                            onChange={(e) => this.setState({long: e.target.value})}
                            labelWidth={70}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <Button disabled={!(this.state.name && this.state.phone && this.state.country && this.state.city && this.state.town && this.state.district && this.state.neighborhood && this.state.text && this.state.direction)} variant="contained" color="primary" className={clsx(classes.margin, classes.textField)} onClick={()=>this.handleSave()}>
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const useStyles = ((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%',
    },
}));
export default (withStyles(useStyles, {withTheme: true})(AddAddress));
