import React, {Component} from "react";
import AssignRole from "./AssignRole";
import {Button, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import {Business} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

@inject("RegistrationStore","LanguageStore","DataStore")
@observer
class AssignAccount extends Component{

    state={
        source:"3",
        mainColor:"#bc0018",
        headColor:"#e48407",
        temporalPass:""
    }
    async componentDidMount() {
        await this.props.DataStore.getExternalSources();

        this.setState({temporalPass: this.makeSlug(`${this.props.RegistrationStore.brand?this.props.RegistrationStore.brand.brand_name:this.props.RegistrationStore.account?.id}-${this.props.RegistrationStore.account?.account_title}`)})
    }
    validateForm() {
        return this.state.source && this.state.temporalPass.length>5;
    }
    makeSlug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
        const to = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
        let i = 0, l = from.length;
        for ( ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
    render() {
        const {classes}=this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Business/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Assign User to Account
                    </Typography>
                    <Typography component="p" variant="body1" color={"secondary"}>
                        *Assign user to account
                    </Typography>
                    <form className={classes.form} onSubmit={async (event) => {
                        event.preventDefault();
                        await this.props.RegistrationStore.assignAccount(this.state.source, this.props.RegistrationStore.user.id, this.props.RegistrationStore.account.id, this.state.temporalPass, this.state.mainColor, this.state.headColor);
                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label={this.props.LanguageStore.language.USERNAME}
                            name="name"
                            readOnly
                            disabled
                            autoComplete="name"
                            value={this.props.RegistrationStore.user?.name}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="account"
                            label={this.props.LanguageStore.language.ACCOUNT}
                            name="account"
                            readOnly
                            disabled
                            autoComplete="account"
                            value={this.props.RegistrationStore.account?.account_title}
                        />
                        <FormControl variant="outlined" className={classes.formControl} style={{marginTop:10}} fullWidth={true}>
                            <InputLabel htmlFor="outlined-age-native-simple">{"External Sources"}</InputLabel>
                            <Select
                                value={this.state.source}
                                onChange={(e)=>this.setState({source:e.target.value})}
                                label={"External Sources"}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {this.props.DataStore.externalSources.map((source, index)=>(
                                    <MenuItem key={index} value={source.id}>{source.api}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="main"
                            type={"color"}
                            label={"Main Color"}
                            name="main"
                            autoComplete="color"
                            value={this.state.mainColor}
                            onChange={(e)=>this.setState({mainColor:e.target.value})}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="secondary"
                            type={"color"}
                            label={"Secondary Color"}
                            name="secondary"
                            autoComplete="color"
                            value={this.state.headColor}
                            onChange={(e)=>this.setState({headColor:e.target.value})}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="temporal-pass"
                            label={"Temporal Pass"}
                            name="temporal-pass"
                            onChange={(e)=>this.setState({temporalPass:e.target.value})}
                            value={this.state.temporalPass}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!this.validateForm()}
                        >
                            Assign
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}
const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});
export default withStyles(useStyles, { withTheme: true })(AssignAccount);
