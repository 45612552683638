import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {inject, observer} from "mobx-react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import PayRule from "../../components/account/PayRule";
import WorkStatus from "../../components/account/WorkStatus";
import DeliveryPoints from "../../components/account/DeliveryPoints";
import Credit from "../../components/account/Credit";
import NearPlaces from "../../components/account/NearPlaces";
import RestaurantQrs from "../../components/account/RestaurantQrs";
import Documents from "../../components/account/Documents";
import Address from "../../components/account/Address";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



@inject('AccountStore', 'LanguageStore')
@observer
class Account extends Component {
    async componentDidMount() {
        await this.props.AccountStore.getAccount();
    }
    state = {
        value: 0
    }
    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };
    render() {
        const {classes} = this.props;
        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        //console.log(toJS(this.props.AccountStore.account));
        //console.log(toJS(this.props.AccountStore.accountPayRules));
        return (
            <div className={classes.root}>

                <AppBar position="static">
                    <Tabs value={this.state.value} onChange={(event, value) => {
                        this.handleChange(event, value)
                    }} aria-label="Definitions">
                        <Tab label={this.props.LanguageStore.language.ACCOUNT} {...a11yProps(0)} />
                        <Tab label={this.props.LanguageStore.language.CREDIT} {...a11yProps(1)} />
                        {/*<Tab label={this.props.LanguageStore.language.ACTIVE_GROUPS} {...a11yProps(2)} />*/}
                        <Tab label={this.props.LanguageStore.language.NEAR_PLACES} {...a11yProps(2)} />
                        <Tab label={this.props.LanguageStore.language.ACTIVITIES} {...a11yProps(3)} />
                        <Tab label={this.props.LanguageStore.language.PROMOTIONS} {...a11yProps(4)} />
                        <Tab label={"QR"} {...a11yProps(5)} />
                        <Tab label={this.props.LanguageStore.language.DOCUMENTS} {...a11yProps(6)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        </Grid>
                        {/* Chart */}
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography variant="overline" gutterBottom>{this.props.LanguageStore.language.BRAND}</Typography>
                                        <Typography variant="h6" gutterBottom>{this.props.AccountStore.brand?.brand_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography variant="overline" gutterBottom>{this.props.LanguageStore.language.BRANCH}</Typography>
                                        <Typography variant="h6" gutterBottom>{this.props.AccountStore.accountName}</Typography>
                                    </Grid>
                                </Grid>
                                <WorkStatus/>
                            </Paper>
                        </Grid>
                        {/* Recent Deposits */}
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={fixedHeightPaper}>
                                <Typography variant="overline"
                                            gutterBottom>{this.props.LanguageStore.language.DELIVERY_POINTS}</Typography>
                                {/*<Brand brand={this.props.AccountStore.brand}/>*/}
                                <DeliveryPoints account={this.props.AccountStore.account}/>
                            </Paper>
                        </Grid>
                        {/* Address */}
                        <Grid item xs={12} md={6} lg={6}>
                            <Address address={this.props.AccountStore.address}/>
                        </Grid>
                        {/* Payment Methods */}
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper className={classes.paper}>
                                <Typography variant="overline" gutterBottom>{this.props.LanguageStore.language.PAYMENT}</Typography>
                                {/*PAYRULE*/}
                                <PayRule/>
                            </Paper>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                            <Credit/>
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    <NearPlaces/>
                </TabPanel>
                <TabPanel value={this.state.value} index={5}>
                    <RestaurantQrs />
                </TabPanel>
                <TabPanel value={this.state.value} index={6}>
                    <Documents />
                </TabPanel>

            </div>);
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    fixedHeight: {
        height: 576,
    },

    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default withStyles(useStyles, {withTheme: true})(Account);
