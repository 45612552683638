import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import {withStyles} from "@material-ui/core/styles";


@inject('ProductGroupStore', 'LayoutStore','AddProductStore','LanguageStore')
@observer
class AddProductMenuItem extends Component {
    state={
        productGroups:[],
        productId:0,
        price:0,
        type:1
    }


    handleCloseModal(){
        this.props.LayoutStore.togglePMIModal(null)
    }
    handleModalConfirm(){
        console.log(this.state.productId, this.props.LayoutStore.PMId, this.state.price, this.state.type);
        this.props.AddProductStore.addProductMenuItem(this.props.LayoutStore.PMId, this.state.type,this.props.productId, this.state.productId,this.state.price);
        this.props.LayoutStore.togglePMIModal(null);
    }
    render() {
        //console.log(this.props);
        function modifyData(data) {
            let products=[];
            data.length>0 && data.map(group =>
                group.products.map(product =>{
                    product.group=group.id;
                    product.groupName=group.product_group_main.main_group_name;
                    products.push(product);
                    return null;
                })
            );
            return products;
        }
        //console.log('mod',modifyData(toJS(this.props.ProductGroupStore.productGroups)));
        const {price}=this.state;
        return (<>
            <Dialog open={this.props.LayoutStore.PMIModal} aria-labelledby="add-product-menu-item-form-dialog-title">
                <DialogTitle
                    id="add-product-menu-item-form-dialog-title">{this.props.LanguageStore.language.ADD_PRODUCT_MENU_ITEM}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{this.props.LanguageStore.language.ADD_PRODUCT_MENU_ITEM}</DialogContentText>
                    {/*<FormControl component="fieldset">
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup aria-label="type" name="type" value={type} onChange={(e)=>{this.setState({type: e.target.value})}}>
                            <FormControlLabel value="1" control={<Radio />} label="Singular" />
                            <FormControlLabel value="2" control={<Radio />} label="Multiple" />
                            <FormControlLabel value="3" control={<Radio />} label="Menu" />
                        </RadioGroup>
                    </FormControl>*/}
                    <Autocomplete
                        id="combo-box-demo"
                        onOpen={()=>{this.props.ProductGroupStore.getProductGroups(-1);}}
                        options={modifyData(toJS(this.props.ProductGroupStore.productGroups))}
                        getOptionLabel={(option) => option.product_name}
                        style={{ width: 300 }}
                        groupBy={(option)=>option.groupName}
                        loading={this.props.ProductGroupStore.loading}
                        getOptionSelected={(option, value)=> value.id===option.id}
                        onChange={(event, newValue) => {
                            //console.log("new value", newValue.id);
                           (newValue.id) &&  this.setState({productId: newValue.id});
                        }}
                        renderInput={(params) => <TextField {...params} autoFocus={true} label={this.props.LanguageStore.language.NAME} variant="outlined" />}
                    />
                    {(parseInt(this.props.LayoutStore.PMType)!==4)&&(<TextField
                        margin="dense"
                        variant={"outlined"}
                        id="menu-price"
                        defaultValue={price}
                        onChange={e => this.setState({price: e.target.value})}
                        label={this.props.LanguageStore.language.PRICE}
                        type="number"
                        fullWidth
                    />)}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleCloseModal()
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button disabled={(!this.state.productId || !this.state.type)}
                            onClick={() => (this.handleModalConfirm())} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    }
}
const useStyles =theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});
export default withStyles(useStyles, {withTheme: true})(AddProductMenuItem);
