import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Notifier from "../Notifier";
import LanguageSelector from "../LanguageSelector";
import TicketDialog from "../TicketDialog";
import NewNavbar from "./NewNavbar";
import {Alert} from "@material-ui/lab";
import {IconButton} from "@material-ui/core";
import {HelpOutline} from "@material-ui/icons";


@inject('AuthStore', 'TicketStore','AccountStore','LanguageStore')
@observer
class NewLayout extends Component{
    async componentDidMount() {
        this.props.AuthStore.all_props = this.props;
        await this.props.AuthStore.getToken();
        //await this.props.AccountStore.getAccount();
    }

     isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    suggestTicket(value){
        console.log("please create ticket", value);
        this.props.TicketStore.suggestedTicket(`"${value}"`, "Restoran durumu ile ilgili", 386);
    }

    render(){
        const {classes,navbar, children} = this.props;
        return (
            <div className={classes.root}>
                <Notifier/>
                {navbar && (<NewNavbar/>)}
                <LanguageSelector/>
                <main className={classes.content}>
                    {true && (<div className={classes.toolbar}/>)}
                    {(!this.isEmpty(this.props.AccountStore.account) && !this.props.AccountStore.account?.account_active) && (<Alert action={<IconButton color="inherit" onClick={()=>this.suggestTicket("Restoran aktif değil")} size="small"><HelpOutline/></IconButton>} className={classes.alert} severity={"error"}>{this.props.LanguageStore.language.NOT_ACTIVE_DESC}</Alert>)}
                    {(!this.isEmpty(this.props.AccountStore.account) && !this.props.AccountStore.account?.account_order_accept) && (<Alert action={<IconButton color="inherit" onClick={()=>this.suggestTicket("Restoran siparişlere kapalı")} size="small"><HelpOutline/></IconButton>}  className={classes.alert} severity={"error"}>{this.props.LanguageStore.language.CLOSED_FOR_ORDERS_DESC}</Alert>)}
                    {children}
                </main>
                {/*Create ticket dialog*/}
                <TicketDialog/>
            </div>
        );
    }
}


const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    alert:{
        marginTop:2,
        marginLeft:4,
        marginRight:4,
        marginBottom:4
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});
export default withRouter(withStyles(useStyles, { withTheme: true })(NewLayout));
