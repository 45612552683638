import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_ACTIVE_GROUPS, GET_PRODUCT_GROUPS} from "../graphql/Queries";
import {UPDATE_PRODUCT_GROUP} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";

class ProductGroupStore{
    @observable productGroups=[];
    @observable activeGroups=[];
    @observable loading=false;

    @action async getProductGroups(sort){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_PRODUCT_GROUPS(parseInt(accountId),parseInt(sort))}}`
                }
            });
            if (data.errors){
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.productGroups=data.data.product_groups;
        }catch (e) {
            console.log(e);
            alert("OOPS. Something went wrong!");
        }

    }

    @action async getActiveGroups(){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_ACTIVE_GROUPS(parseInt(accountId))}}`
                }
            });
            if (data.errors){
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.activeGroups=data.data.product_active_groups;
        }catch (e) {
            console.log(e);
            alert("OOPS. Something went wrong!");
        }

    }


    @action async updateProductGroup(id, mGroup, sort,active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRODUCT_GROUP(id, parseInt(accountId), parseInt(mGroup), parseInt(sort), parseInt(accountId), Number(active))}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                //alert("OOPS. Something went wrong on: ProductGroupStore at updateProductGroup!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: ProductGroupStore at updateProductGroup!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            await this.getProductGroups(-1)
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: ProductGroupStore at updateProductGroup!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }
}

export default new ProductGroupStore();
