import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage/lib";
import axios from "axios";
import {API_BASE} from "../constants";
import {ADD_DOCUMENT} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";
import {GET_DOCUMENT_TYPES, GET_DOCUMENTS} from "../graphql/Queries";

class DocumentStore {
    @observable loading=false;
    @observable documents=[];

    @action async getDocuments(){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_DOCUMENTS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: DocumentStore at getDocuments!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.documents = data.data.documents;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: DocumentStore at getDocuments!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async addDocument(url, type){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ ADD_DOCUMENT(parseInt(accountId), url,parseInt(type))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: DocumentStore at addDocument!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log('from store',this.justAddedMenuId, data.data.add_product_menu.id);
            //await this.getProduct(product);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Document Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: DocumentStore at addDocument!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
}

export default new DocumentStore();
