import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import GroupManagement from "../../components/definitions/GroupManagement";
import PriceGroupManagement from "../../components/definitions/PriceGroup/PriceGroupManagement";
import RoomManagement from "../../components/definitions/RoomAndTable/RoomManagement";
import {inject, observer} from "mobx-react";
import CopyProduct from "../../components/definitions/CopyProduct/CopyProduct";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

@inject("LanguageStore")
@observer
class Definitions extends Component {

    state = {
        value: 0
    }
    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={this.state.value} onChange={(event, value)=>{this.handleChange(event, value)}} aria-label="Definitions">
                        <Tab label={this.props.LanguageStore.language.PRODUCT_GROUP} {...a11yProps(0)} />
                        <Tab label={this.props.LanguageStore.language.PRODUCT_PRICE_GROUPS} {...a11yProps(1)} />
                        {/*<Tab label={this.props.LanguageStore.language.ACTIVE_GROUPS} {...a11yProps(2)} />*/}
                        <Tab label={this.props.LanguageStore.language.ROOM_AND_TABLE} {...a11yProps(2)} />
                        <Tab label={this.props.LanguageStore.language.COPY_PRODUCT} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <GroupManagement/>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <PriceGroupManagement/>
                </TabPanel>
                {/*<TabPanel value={this.state.value} index={2}>
                    <ActiveGroup/>
                </TabPanel>*/}
                <TabPanel value={this.state.value} index={2}>
                    <RoomManagement/>
                </TabPanel>
                <TabPanel value={this.state.value} index={3}>
                    <CopyProduct/>
                </TabPanel>
            </div>
        );
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
});

export default withStyles(useStyles, {withTheme: true})(Definitions);
