import {action, observable, toJS} from "mobx";
import axios from "axios";
import {API_BASE} from "../constants";
import {POST_QR_ORDER} from "../graphql/Mutations";

class VariablesStore{
    @observable groupId="";

    @action setGroup(group){
        this.groupId=group;
    }
    @action
    async testQuery() {
        try {
            const data = await axios({
                url:`${API_BASE}`,
                method:"post",
                headers:{"Authorization":'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJheXJhbWdlbGRpLjIxQG1lLmNvbSIsImlhdCI6MTYyNjEwMDczMSwiZXhwIjoxNjU3NjM2NzMxfQ.STf6xc8ktoTuvp4IWY9ZTASjKQBUXi-drhuWtt9b0-I'},data:{"query":"mutation{create_point_receipt_qr(prq_brand:6,prq_account:12,prq_receipt_type:0,prq_receipt_no:301008,prq_date:\"07-12-2021 19:14:30\",prq_table:21,prq_total:18){ id}}"}});

            console.log("data:", toJS(data));
        } catch (e) {
            console.log(e);
        }
    }
}

export default new VariablesStore();
