import React, {Component} from "react";
import ListItem from "@material-ui/core/ListItem";
import {
    Button,
    Collapse,
    FormControlLabel,
    FormGroup,
    Switch,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import List from "@material-ui/core/List";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Clear, Done, ExpandLess, ExpandMore} from "@material-ui/icons";
import {toJS} from "mobx";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    const {title}=props;
    let obj = {...props, title: (title?title:"-")}
    return <Tooltip arrow classes={classes} {...obj} />;
}



@inject("AccountStore", "LanguageStore")
@observer
class WorkStatus extends Component {


    state = {
        editMode: false,
        workHoursId:null,
        editField: "",
        fieldValue: "",
        showAllDays:false,
        setType:[
            {"id":1, "type_name":"Brand"},
            {"id":2, "type_name":"Account"},
            {"id":3, "type_name":"Except Account"},
        ]
    }

    async componentDidMount() {
        await this.props.AccountStore.getPointSet();
    }

    async handleToggles(delivery, takeaway, eatin) {
        console.log("set:", Boolean(delivery), Boolean(takeaway), Boolean(eatin));
        await this.props.AccountStore.toggleRestaurant(delivery, takeaway, eatin);
        await this.props.AccountStore.getAccount();
    }
    allStatus(delivery, takeaway, eatin) {
        return (delivery || takeaway || eatin);
    }
    handleDoubleClick(type, fieldValue, id) {
        this.setState({editMode: true, editField: type, fieldValue:fieldValue, workHoursId:id});
    }


    async handleSubmit() {
        console.log(this.state);
        if (this.state.editMode && this.state.editField === "email") {
            await this.props.AccountStore.updateRestaurantEmail(this.state.fieldValue);
        }else if (this.state.editMode && this.state.editField === "kep") {
            await this.props.AccountStore.updateRestaurantKep(this.state.fieldValue);
        }else if (this.state.editMode && this.state.editField === "gsm") {
            await this.props.AccountStore.updateRestaurantGSM(this.state.fieldValue);
        }else if (this.state.editMode && this.state.editField === "tax") {
            await this.props.AccountStore.updateRestaurantTaxNo(this.state.fieldValue);
        }else if (this.state.editMode && this.state.editField === "opening") {
            await this.props.AccountStore.updateRestaurantOpening(this.state.fieldValue);
        }else if (this.state.editMode && this.state.editField === "closing") {
            await this.props.AccountStore.updateRestaurantClosing(this.state.fieldValue);
        }else if(this.state.editMode && this.state.workHoursId){
            await this.props.AccountStore.updateWorkHours(this.state.workHoursId,this.state.editField,this.state.fieldValue);
        }else if(this.state.editMode && this.state.workHoursId==null){
            await this.props.AccountStore.addWorkHours();
        }
        this.setState({editMode: false, editField: "", fieldValue: "", workHoursId:null});
        await this.props.AccountStore.getAccount();
    }
    TodayTable(data){
        //console.log("data",data);
        let weekdays=new Array(7);
        let today=(new Date()).getDay();
        weekdays[0]={day:this.props.LanguageStore.language.SUNDAY,opening:data?.sunday_opening, closing:data?.sunday_closing};
        weekdays[1]={day:this.props.LanguageStore.language.MONDAY,opening:data?.monday_opening, closing:data?.monday_closing};
        weekdays[2]={day:this.props.LanguageStore.language.TUESDAY,opening:data?.tuesday_opening, closing:data?.tuesday_closing};
        weekdays[3]={day:this.props.LanguageStore.language.WEDNESDAY,opening:data?.wednesday_opening, closing:data?.wednesday_closing};
        weekdays[4]={day:this.props.LanguageStore.language.THURSDAY,opening:data?.thursday_opening, closing:data?.thursday_closing};
        weekdays[5]={day:this.props.LanguageStore.language.FRIDAY,opening:data?.friday_opening, closing:data?.friday_closing};
        weekdays[6]={day:this.props.LanguageStore.language.SATURDAY,opening:data?.saturday_opening, closing:data?.saturday_closing};

        //console.log(weekdays[today]);
        return(
            <Table size={"small"}>
                <TableBody>
                    <TableRow>
                        <TableCell>{weekdays[today].day}</TableCell>
                        <TableCell align={"right"}>{weekdays[today].opening}</TableCell>
                        <TableCell align={"right"}>{weekdays[today].closing}</TableCell>
                        <TableCell align={"right"}>{(this.state.showAllDays)?(<IconButton onClick={()=>this.setState({showAllDays:false})}><ExpandLess/></IconButton>):(<IconButton onClick={()=>this.setState({showAllDays:true})}><ExpandMore/></IconButton>)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
    DaysTable(data){
        let weekdays=new Array(7);
        let today=(new Date()).getDay();
        const {classes} = this.props;

        weekdays[0]={day:this.props.LanguageStore.language.SUNDAY,opening:data?.sunday_opening, closing:data?.sunday_closing, name:"sunday"};
        weekdays[1]={day:this.props.LanguageStore.language.MONDAY,opening:data?.monday_opening, closing:data?.monday_closing, name:"monday"};
        weekdays[2]={day:this.props.LanguageStore.language.TUESDAY,opening:data?.tuesday_opening, closing:data?.tuesday_closing, name:"tuesday"};
        weekdays[3]={day:this.props.LanguageStore.language.WEDNESDAY,opening:data?.wednesday_opening, closing:data?.wednesday_closing, name:"wednesday"};
        weekdays[4]={day:this.props.LanguageStore.language.THURSDAY,opening:data?.thursday_opening, closing:data?.thursday_closing, name:"thursday"};
        weekdays[5]={day:this.props.LanguageStore.language.FRIDAY,opening:data?.friday_opening, closing:data?.friday_closing, name:"friday"};
        weekdays[6]={day:this.props.LanguageStore.language.SATURDAY,opening:data?.saturday_opening, closing:data?.saturday_closing, name:"saturday"};
        //console.log(weekdays);
        return(
            <Table  aria-label="simple table" style={{"width":"100%"}}>
                <TableHead component={"thead"}>
                    <TableRow>
                        <TableCell>Days</TableCell>
                        <TableCell align="right">{this.props.LanguageStore.language.OPENING_HOURS}</TableCell>
                        <TableCell align="right">{this.props.LanguageStore.language.CLOSING_HOURS}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weekdays.map((day, index)=>(
                        <TableRow key={index} selected={today === index}>
                            <TableCell component="th" scope="row">
                                {day.day}
                            </TableCell>
                            <TableCell align="center">
                                <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                        {(this.props.AccountStore.account.account_work_hours) ? (
                                            (this.state.editMode && this.state.editField === `${day.name}_opening`) ? (
                                                <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                                    <OutlinedInput
                                                        id="outlined-adornment-field"
                                                        type={'time'}
                                                        value={this.state.fieldValue}
                                                        onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="submit field"
                                                                    onClick={() => {
                                                                        this.handleSubmit()
                                                                    }}
                                                                    color={'primary'}
                                                                    edge="end"
                                                                >
                                                                    <Done/>
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="cancel field edit"
                                                                    onClick={() => this.setState({
                                                                        editMode: false,
                                                                        editField: "",
                                                                        fieldValue: "",
                                                                    })}
                                                                    color={'secondary'}
                                                                    edge="end"
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </InputAdornment>

                                                        }
                                                    /></FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                         onDoubleClick={() => this.handleDoubleClick(`${day.name}_opening`, day.opening?day.opening:"", data.id)}
                                                                         className={classes.underlineDotted} color={"inherit"}
                                                                         underline={"none"}>
                                                {day.opening?day.opening:(<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                                            </Link>)
                                        ) : (
                                            (this.state.editMode && this.state.editField === `${day.name}_opening`) ? (
                                                <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                                    <OutlinedInput
                                                        id="outlined-adornment-field"
                                                        type={'text'}
                                                        value={this.state.fieldValue}
                                                        onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="submit field"
                                                                    onClick={() => {
                                                                        this.handleSubmit()
                                                                    }}
                                                                    color={'primary'}
                                                                    edge="end"
                                                                >
                                                                    <Done/>
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="cancel field edit"
                                                                    onClick={() => this.setState({
                                                                        editMode: false,
                                                                        editField: "",
                                                                        fieldValue: "",
                                                                    })}
                                                                    color={'secondary'}
                                                                    edge="end"
                                                                >
                                                                    <Clear/>
                                                                </IconButton>
                                                            </InputAdornment>

                                                        }
                                                    />
                                                </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                         onDoubleClick={() => this.handleDoubleClick(`${day.name}_opening`,"", data.id)}
                                                                         className={classes.underlineDotted} color={"inherit"}
                                                                         underline={"none"}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </Link>)
                                        )}
                                </BootstrapTooltip>
                            </TableCell>
                            <TableCell align="center">
                                        <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                            {(this.props.AccountStore.account.account_work_hours) ? (
                                                (this.state.editMode && this.state.editField === `${day.name}_closing`) ? (
                                                    <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-field"
                                                            type={'time'}
                                                            value={this.state.fieldValue}
                                                            onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="submit field"
                                                                        onClick={() => {
                                                                            this.handleSubmit()
                                                                        }}
                                                                        color={'primary'}
                                                                        edge="end"
                                                                    >
                                                                        <Done/>
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="cancel field edit"
                                                                        onClick={() => this.setState({
                                                                            editMode: false,
                                                                            editField: "",
                                                                            fieldValue: "",
                                                                        })}
                                                                        color={'secondary'}
                                                                        edge="end"
                                                                    >
                                                                        <Clear/>
                                                                    </IconButton>
                                                                </InputAdornment>

                                                            }
                                                        /></FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                                   onDoubleClick={() => this.handleDoubleClick(`${day.name}_closing`, day.closing?day.closing:"", data.id)}
                                                                                   className={classes.underlineDotted} color={"inherit"}
                                                                                   underline={"none"}>
                                                    {day.closing?day.closing:(<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                                                </Link>)
                                            ) : (
                                                (this.state.editMode && this.state.editField === `${day.name}_closing`) ? (
                                                    <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-field"
                                                            type={'text'}
                                                            value={this.state.fieldValue}
                                                            onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="submit field"
                                                                        onClick={() => {
                                                                            this.handleSubmit()
                                                                        }}
                                                                        color={'primary'}
                                                                        edge="end"
                                                                    >
                                                                        <Done/>
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="cancel field edit"
                                                                        onClick={() => this.setState({
                                                                            editMode: false,
                                                                            editField: "",
                                                                            fieldValue: "",
                                                                        })}
                                                                        color={'secondary'}
                                                                        edge="end"
                                                                    >
                                                                        <Clear/>
                                                                    </IconButton>
                                                                </InputAdornment>

                                                            }
                                                        />
                                                    </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                             onDoubleClick={() => this.handleDoubleClick(`${day.name}_closing`,"", data.id)}
                                                                             className={classes.underlineDotted} color={"inherit"}
                                                                             underline={"none"}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </Link>)
                                            )}
                                        </BootstrapTooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
    render() {
        const {classes} = this.props;
        //console.log(toJS(this.props.AccountStore.pointSet));
        return (
            <>
                {/*<Typography variant="h6"
                                            gutterBottom>{this.props.AccountStore.accountCenter}</Typography>*/}
                <List>
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.ACCOUNT_NUMBER}:`}/>
                        <ListItemText className={classes.listPrimaryText} primary={this.props.AccountStore.accountId}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.ACCOUNT_EMAIL}:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                {(this.props.AccountStore.account.account_e_mail) ? (
                                    (this.state.editMode && this.state.editField === "email") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("email", this.props.AccountStore.account.account_e_mail)}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        {this.props.AccountStore.account.account_e_mail}
                                    </Link>)
                                ) : (
                                    (this.state.editMode && this.state.editField === "email") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("email","")}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Link>)
                                )}
                            </BootstrapTooltip>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.ACCOUNT_KEP}:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                {(this.props.AccountStore.account.account_kep) ? (
                                    (this.state.editMode && this.state.editField === "kep") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("kep", this.props.AccountStore.account.account_kep)}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        {this.props.AccountStore.account.account_kep}
                                    </Link>)
                                ) : (
                                    (this.state.editMode && this.state.editField === "kep") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("kep","")}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Link>)
                                )}
                            </BootstrapTooltip>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.GSM_NO}:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                {(this.props.AccountStore.account.account_gsm_no) ? (
                                    (this.state.editMode && this.state.editField === "gsm") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("gsm", this.props.AccountStore.account.account_gsm_no)}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        {this.props.AccountStore.account.account_gsm_no}
                                    </Link>)
                                ) : (
                                    (this.state.editMode && this.state.editField === "gsm") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("gsm","")}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Link>)
                                )}
                            </BootstrapTooltip>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.ACCOUNT_TAX_NO}:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <BootstrapTooltip title={this.props.LanguageStore.language.DOUBLE_CLICK_TO_EDIT}>
                                {(this.props.AccountStore.account.account_tax_no) ? (
                                    (this.state.editMode && this.state.editField === "tax") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("tax", this.props.AccountStore.account.account_tax_no)}
                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        {this.props.AccountStore.account.account_tax_no}
                                    </Link>)
                                ) : (
                                    (this.state.editMode && this.state.editField === "tax") ? (
                                        <FormControl variant="outlined" size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                id="outlined-adornment-field"
                                                type={'text'}
                                                value={this.state.fieldValue}
                                                onChange={(event) => this.setState({fieldValue: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit field"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel field edit"
                                                            onClick={() => this.setState({
                                                                editMode: false,
                                                                editField: "",
                                                                fieldValue: "",
                                                            })}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>) : (<Link href="#" onClick={(e) => e.preventDefault()}
                                                                 onDoubleClick={() => this.handleDoubleClick("tax","")}

                                                                 className={classes.underlineDotted} color={"inherit"}
                                                                 underline={"none"}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Link>)
                                )}
                            </BootstrapTooltip>
                        </ListItemText>
                </ListItem>
                    {/*Point Set*/}
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`Puan Seçenekleri:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <BootstrapTooltip title={"Restoran Puan Seçenekleri"}>
                                {(this.props.AccountStore.pointSet)?(
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={this.props.AccountStore.pointSet?.set_title}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText secondary={this.props.LanguageStore.language.ORDER}/>
                                                <ListItemText primary={this.props.AccountStore.pointSet?.set_percent? `${this.props.AccountStore.pointSet?.set_percent}%`:`0%`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText secondary={this.props.LanguageStore.language.TAKEAWAY}/>
                                                <ListItemText primary={this.props.AccountStore.pointSet?.set_percent_takeaway? `${this.props.AccountStore.pointSet?.set_percent_takeaway}%`:`0%`}/>
                                            </ListItem>
                                        </List>
                                ):(<>...</>)}
                            </BootstrapTooltip>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText secondary={this.props.LanguageStore.language.WORK_HOURS}/>
                        <ListItemText>
                            {this.props.AccountStore.account.account_work_hours?(
                                <List>
                                        <ListItem>
                                            <ListItemText>
                                                {this.TodayTable(this.props.AccountStore.account?.account_work_hours)}
                                            </ListItemText>
                                        </ListItem>
                                    <Collapse in={this.state.showAllDays} timeout="auto" style={{"width":"inherit"}} unmountOnExit>
                                        <ListItem>
                                            {this.DaysTable(this.props.AccountStore.account?.account_work_hours)}
                                        </ListItem>
                                    </Collapse>
                            </List>
                            ):(
                                <Button onClick={async () => {
                                    await this.setState({editMode: true});
                                    this.handleSubmit();
                                }}>{this.props.LanguageStore.language.ADD}</Button>
                            )}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.listSecondaryText} secondary={`${this.props.LanguageStore.language.WORK_METHODS}:`}/>
                        <ListItemText className={classes.listPrimaryText}>
                            <List>
                                <ListItem>
                                    <FormGroup>
                                        <FormControlLabel className={classes.childSwitch}
                                                          control={<Switch
                                                              checked={Boolean(this.props.AccountStore.account.account_delivery)}
                                                              onChange={(e) => this.handleToggles(e.target.checked, Boolean(this.props.AccountStore.account.account_takeaway), Boolean(this.props.AccountStore.account.account_eatin))}
                                                              name="delivery"/>}
                                                          label={this.props.LanguageStore.language.DELIVERY}
                                        />
                                        <FormControlLabel className={classes.childSwitch}
                                                          control={<Switch
                                                              checked={Boolean(this.props.AccountStore.account.account_takeaway)}
                                                              onChange={(e) => this.handleToggles(Boolean(this.props.AccountStore.account.account_delivery), e.target.checked, Boolean(this.props.AccountStore.account.account_eatin))}
                                                              name="takeaway"/>}
                                                          label={this.props.LanguageStore.language.TAKEAWAY}
                                        />
                                        <FormControlLabel className={classes.childSwitch}
                                                          control={<Switch
                                                              checked={Boolean(this.props.AccountStore.account.account_eatin)}
                                                              onChange={(e) => this.handleToggles(Boolean(this.props.AccountStore.account.account_delivery), Boolean(this.props.AccountStore.account.account_takeaway), e.target.checked)}
                                                              name="eatin"/>}
                                                          label={this.props.LanguageStore.language.EATIN}
                                        />
                                    </FormGroup>
                                </ListItem>
                            </List>
                        </ListItemText>
                    </ListItem>
                </List></>);
    }
}

const useStyles = theme => ({
    listPrimaryText: {
        width: "60%"
    },
    listSecondaryText: {
        width: "40%"
    },
    underlineDotted: {
        borderBottom: "2px dotted"
    },
    childSwitch: {
        paddingLeft: 20
    },
    priceInput: {
        width: '100%'
    }
});
export default withStyles(useStyles, {withTheme: true})(WorkStatus);
