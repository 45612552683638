import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {CheckCircle, Edit, MonetizationOn} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

@inject('PriceGroupStore', 'PriceGroupStore', 'LanguageStore')
@observer
class PriceGroupManagement extends Component{
    componentDidMount() {
        this.props.PriceGroupStore.getPriceGroups();
    }
    state={
        priceGroupActive:false,
        priceGroupName:"",
        editIcon:true,
        edit:{id:null, name:"",modal:false, active:false}
    }

    handleSubmit(){
        console.log(this.state);
        this.props.PriceGroupStore.addPriceGroup(this.state.priceGroupName, this.state.priceGroupActive);
        this.setState({priceGroupActive:false, priceGroupName:""});
    }
    handleCloseModal(){
        this.setState({edit:{id:null, name:"", modal:false, active:false}})
    }
    handleModalConfirm(){
        console.log(this.state.edit);
        this.props.PriceGroupStore.updatePriceGroup(this.state.edit.id, this.state.edit.name, this.state.edit.active);
        this.handleCloseModal();
    }
    render() {
        const {classes}=this.props;
        return(
            <Grid container spacing={2}>
                <Backdrop className={classes.backdrop} open={this.props.PriceGroupStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                        <TextField id="price-group-name" label={this.props.LanguageStore.language.PRICE_GROUP_NAME} variant="outlined" value={this.state.priceGroupName} onChange={(e)=>{this.setState({priceGroupName:e.target.value})}} />
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.priceGroupActive} onChange={(event) => {
                                    this.setState({priceGroupActive:event.target.checked});
                                }}
                                                   name="default"/>}
                                label={this.props.LanguageStore.language.DEFAULT}/>
                        </FormGroup>
                        <Button variant="contained" color="primary" onClick={() => {this.handleSubmit()}}
                                disabled={!this.state.priceGroupName}>
                            {this.props.LanguageStore.language.ADD_TO_RESTAURANT}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.demo}>
                        <List dense={false}>
                            <FormControlLabel
                            value="edit"
                            control={<Switch color="secondary" onChange={(e)=>{this.setState({editIcon:!this.state.editIcon})}} />}
                            label={this.props.LanguageStore.language.EDIT}
                            labelPlacement="start"/>
                            {this.props.PriceGroupStore.priceGroups.map((priceGroup) => (
                                <ListItem key={priceGroup.id}>
                                    <ListItemAvatar>
                                                {(priceGroup.default==="true")?(<CheckCircle color={'primary'}/>):(<MonetizationOn/>)}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={priceGroup.price_group_name}
                                        secondary={(priceGroup.price_group_name) ? (priceGroup.price_group_name) : (null)}
                                    />
                                    <ListItemSecondaryAction hidden={this.state.editIcon}>
                                        <IconButton edge="end" aria-label="delete" onClick={()=>{this.setState({edit:{id:priceGroup.id, name:priceGroup.price_group_name, modal:true, active:false}})}}>
                                            <Edit/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        {/*Price Group update/edit modal*/}
                        <Dialog open={this.state.edit.modal} onClose={this.handleCloseModal} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.EDIT_PRICE_GROUP}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.props.LanguageStore.language.EDIT_ACTIVE_GROUP_DESC}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label={this.props.LanguageStore.language.NAME}
                                    type="text"
                                    variant={'outlined'}
                                    value={this.state.edit.name}
                                    onChange={e => this.setState({edit:{id:this.state.edit.id, name:e.target.value, modal:this.state.edit.modal, active:this.state.edit.active}})}
                                    fullWidth
                                />
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.edit.active} onChange={(event) => {
                                            this.setState({edit:{id:this.state.edit.id, name:this.state.edit.name, modal:this.state.edit.modal, active:event.target.checked}});
                                        }}
                                                           name="default"/>}
                                        label={this.props.LanguageStore.language.DEFAULT}/>
                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>{this.handleCloseModal()}} color="primary">
                                    {this.props.LanguageStore.language.CANCEL}
                                </Button>
                                <Button onClick={() => {this.handleModalConfirm()}} color="primary">
                                    {this.props.LanguageStore.language.SAVE}
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                </Grid>
            </Grid>
        );
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        height: '30vh',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

export default withStyles(useStyles, {withTheme: true})(PriceGroupManagement);
