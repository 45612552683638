import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {toJS} from "mobx";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import {DirectionsBike, DirectionsWalk, EventSeat, ExpandLess, ExpandMore} from "@material-ui/icons";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";

@inject('CopyProductStore','VariablesStore','ProductGroupStore','LanguageStore','TicketStore')
@observer
class CopyProduct extends Component{
    state={
        openP: true,
        openD: true,
        confirmD:false,
        groups:[],
        group:[],
        count:"all",
        groupId:-1,
        product:null,
        productId:-1,
        newProductId:null,
        suggestThis:"",
    }
    async componentDidMount() {
        await this.props.CopyProductStore.getProductGroups().then(r => {
            this.setState({groups: this.props.CopyProductStore.productGroups});
        });
    }

    suggestTicket(value){
        console.log("please create ticket", value);
        this.setState({productId: -1, product:null});

        this.props.TicketStore.suggestedTicket(`"${value.inputValue}"`, "Yeni ürün ekleme isteği", 386);
    }
    getGroup(groupId) {
        let group = this.state.groups.find(el => el.id === groupId);
        this.setState({group: group, productId: -1});
    }
    async getProduct(productId) {
        await this.props.CopyProductStore.getProduct(productId).then(() => {
            //this.modMenu(this.props.CopyMenuStore.product);
            this.setState({product:this.props.CopyProductStore.product});
        });
    }
    handleClick() {
        this.setState({openP: !this.state.openP});
    }

    async copyProduct(product){
        //console.log("copable", toJS(product));
        this.setState({confirmD:false});
        await this.props.CopyProductStore.addProduct(product.product_name, (product.product_number)?(product.product_number):(0), true, product.product_type.id, this.props.VariablesStore.groupId, (product.image_url)?(product.image_url):("https://dummyimage.com/600x400/000/fff"), product.product_is_menu);
        if (this.props.CopyProductStore.newProductId && product.product_price && this.props.CopyProductStore.defaultPriceGroupId){
            await this.props.CopyProductStore.addPrice(this.props.CopyProductStore.newProductId, product.product_price.price_value, product.product_price.price_takeaway, product.product_price.price_eatin, this.props.CopyProductStore.defaultPriceGroupId);
        }
        if (this.props.CopyProductStore.newProductId && product.product_detail) {
            await this.props.CopyProductStore.addDescription(this.props.CopyProductStore.newProductId, 2, product.product_detail.detail_text);
        }
        if(this.props.CopyProductStore.newProductId && product.product_menu.length>0){
            let iteration = 0;
            product.product_menu.map(async (menu, i) => {
                    //console.log("menu bu", toJS(menu));
                    iteration++;
                    console.log(iteration + "/" + this.state.count);
                    await this.props.CopyProductStore.addProductMenu(this.props.CopyProductStore.newProductId, menu.menu_name, 0, menu.menu_type, (menu.menu_sort)?(menu.menu_sort):(i+1));

                    if (this.props.CopyProductStore.newMenuId){
                        menu.product_items.map(async (item, j) => {
                            iteration++;
                            console.log(iteration + "/" + this.state.count);
                            await this.props.CopyProductStore.addProductMenuItem(this.props.CopyProductStore.newMenuId, 1, this.props.CopyProductStore.newProductId, item.product.id, item.item_price, (item.item_sort)?(item.item_sort):(j+1));
                        });
                    }
            })
        }
        await this.props.ProductGroupStore.getProductGroups(-1);
    }

    render() {
        const {classes}=this.props;
        const {openP, openD}=this.state;
        //console.log(toJS(this.state.product), toJS(this.props.VariablesStore.groupId));
        const filter = createFilterOptions();
        function modifyData(data) {
            let products=[];
            data.length>0 && data.map(group =>
                group.products.map(product =>{
                    product.group=group.id;
                    product.groupName=group.product_group_main.main_group_name;
                    products.push(product);
                    return null;
                })
            );
            return products;
        }
        return (
            <Grid container spacing={2}>
                <Backdrop className={classes.backdrop} open={this.props.CopyProductStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid item xs={12} md={4}>
                    {(this.state.groups?.length > 0) && (
                        <Autocomplete
                            id="combo-box-demo"
                            options={modifyData(toJS(this.props.CopyProductStore.productGroups))}
                            getOptionLabel={(option) => option.product_name}
                            style={{ width: 300 }}
                            groupBy={(option)=>option.groupName}
                            loading={this.props.CopyProductStore.loading}
                            getOptionSelected={(option, value)=> value.id===option.id}

                            onChange={(event, newValue) => {
                                //console.log("new value", newValue.id);
                                if ( newValue?.inputValue && typeof newValue.inputValue=== 'string') {
                                    //console.log("add now", newValue);
                                    this.setState({suggestThis: newValue});
                                    this.suggestTicket(newValue);
                                }else if(newValue?.id){
                                    this.setState({productId: newValue.id});
                                    this.getProduct(newValue.id);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // Suggest the creation of a new value
                                if (params.inputValue !== "") {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        product_name: `Ekleme isteği bildir "${params.inputValue}"`
                                    });
                                }
                                return filtered;
                            }}
                            freeSolo
                            clearOnBlur
                            renderInput={(params) => <TextField {...params} required={true} label={`${this.props.LanguageStore.language.PRODUCT_TO_ADD}`} variant="outlined" />}
                        />
                    )}
                    <br/>
                    <Typography variant={"body1"}>*Eklemek istediğiniz ürünü üstteki kutuda bulamadığınız durumda. Anasayfadan yada kutuya ürün ismini yazarak destek isteyebilirsiniz.</Typography>
                </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4} lg={3}>
                                <Card className={classes.productImg} elevation={0}>
                                    <CardMedia
                                        component="img"
                                        alt={`${this.props.CopyProductStore.product.product_name}`}
                                        image={`${(this.props.CopyProductStore.product.img_url) ? (this.props.CopyProductStore.product.img_url) : ("https://dummyimage.com/240x180/fff/aaa")}`}
                                        title={`${this.props.CopyProductStore.product.product_name}`}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={6} md={8} lg={9}>
                                {/*ProductDetailList*/}
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel id="product-group-label">{this.props.LanguageStore.language.PRODUCT_GROUP}</InputLabel>
                                    <Select
                                        labelId="product-group-label"
                                        id="product-group"
                                        label={this.props.LanguageStore.language.PRODUCT_GROUP}
                                        value={(this.props.VariablesStore.groupId)?(this.props.VariablesStore.groupId):("-1")}
                                        onChange={(event) => {
                                            this.props.VariablesStore.setGroup(event.target.value)
                                        }}
                                        fullWidth={true}
                                    >
                                        <MenuItem value="-1">
                                            <em>None</em>
                                        </MenuItem>
                                        {((toJS(this.props.ProductGroupStore.productGroups)) && ((Array.isArray(toJS(this.props.ProductGroupStore.productGroups))) ? (
                                            toJS(this.props.ProductGroupStore.productGroups).map((group) => (
                                                <MenuItem key={group.id} value={group.product_group_main.id}>{group.product_group_main.main_group_name}</MenuItem>
                                            ))
                                        ) : null))}
                                    </Select>
                                </FormControl>
                                <List component={"div"}
                                      subheader={
                                          <ListSubheader component="div" id="nested-list-subheader">
                                              {this.props.LanguageStore.language.PRODUCT}
                                          </ListSubheader>}>
                                    <ListItem>
                                        <ListItemText secondary={`${this.props.LanguageStore.language.NAME}:`}/>
                                        {(this.props.CopyProductStore.product)?(<ListItemText primary={this.props.CopyProductStore.product.product_name}/>):(<ListItemText primary={'---'}/>)}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText secondary={`${this.props.LanguageStore.language.PRODUCT_NUMBER}:`}/>
                                        {(this.props.CopyProductStore.product)?(<ListItemText primary={this.props.CopyProductStore.product.product_number}/>):(<ListItemText primary={'---'}/>)}
                                    </ListItem>
                                    <ListItem button onClick={() => {
                                        this.handleClick()
                                    }}>
                                        <ListItemText primary={this.props.LanguageStore.language.PRICES}/>
                                        {openP ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    {(this.props.CopyProductStore.product.product_price) ? (
                                        <Collapse in={openP} timeout="auto" onMouseEnter={() => (this.setState({editPButton: true}))}
                                                  onMouseLeave={() => (this.setState({editPButton: false}))} unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <DirectionsBike/>
                                                    </ListItemIcon>
                                                    <ListItemText secondary={`${this.props.LanguageStore.language.PARCEL}:`} className={classes.listSecondaryText}/>
                                                    <ListItemText
                                                        primary={`${(this.props.CopyProductStore.product.product_price.price_value).toFixed(2)}`} className={classes.listPrimaryText}/>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <DirectionsWalk/>
                                                    </ListItemIcon>
                                                    <ListItemText secondary={`${this.props.LanguageStore.language.TAKEAWAY}:`} className={classes.listSecondaryText}/>
                                                    <ListItemText primary={`${(this.props.CopyProductStore.product.product_price.price_takeaway).toFixed(2)}`} className={classes.listPrimaryText}/>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <EventSeat/>
                                                    </ListItemIcon>
                                                    <ListItemText secondary={`${this.props.LanguageStore.language.EATIN}:`} className={classes.listSecondaryText}/>
                                                    <ListItemText primary={`${(this.props.CopyProductStore.product.product_price.price_eatin).toFixed(2)}`} className={classes.listPrimaryText}/>
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                    ) : (
                                        <List component="div" disablePadding>
                                            <ListItem>
                                                --
                                            </ListItem>
                                        </List>
                                    )}
                                    <ListItem button onClick={() => (this.setState({openD: !openD}))}>
                                        <ListItemText secondary={`${this.props.LanguageStore.language.DESCRIPTION}:`}/>
                                        {openD ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    {(this.props.CopyProductStore.product.product_detail) ? (
                                        <List>
                                            <Collapse in={openD} timeout={"auto"} unmountOnExit>
                                                <Typography variant={"body1"}>
                                                    {`${(this.props.CopyProductStore.product.product_detail) ? (this.props.CopyProductStore.product.product_detail.detail_text) : (null)}`}
                                                </Typography>
                                            </Collapse>
                                        </List>
                                    ) : (
                                        "--"
                                    )}
                                </List>
                                {(() => {
                                    if (this.props.CopyProductStore.product.product_menu && this.props.CopyProductStore.product.product_menu && this.props.CopyProductStore.product.product_menu.length > 0) {
                                        return (<List>
                                            <ListSubheader>{this.props.LanguageStore.language.MENU}</ListSubheader>
                                            {(
                                                this.props.CopyProductStore.product.product_menu.map((menu, mi) => (
                                                    <div key={menu.id}>
                                                        <ListItem>
                                                            <ListItemText className={classes.menuName}
                                                                          primary={menu.menu_name}/>
                                                        </ListItem>
                                                        {(menu.product_items) && (
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <List component={'div'} disablePadding
                                                                      className={classes.nested}>
                                                                    {(menu.product_items.map((item, i) => (
                                                                        <ListItem key={item.id}>
                                                                            <ListItemText
                                                                                primary={item.product.product_name}/>
                                                                        </ListItem>
                                                                    )))}
                                                                </List>
                                                            </Collapse>)}
                                                    </div>
                                                ))
                                            )}
                                        </List>);
                                    } else {
                                        return (<p>{this.props.LanguageStore.language.EMPTY}</p>);
                                    }
                                })()}
                                <Button
                                    disabled={(!this.state.product) || (this.props.VariablesStore.groupId==="" || this.props.VariablesStore.groupId==="-1")}
                                    variant={"outlined"} onClick={() =>this.setState({confirmD:true})}>{this.props.LanguageStore.language.ADD}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                <Dialog
                    open={this.state.confirmD}
                    onClose={()=>this.setState({confirmD:false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.LanguageStore.language.COPY_PRODUCT_AYS}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({confirmD:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={() =>this.copyProduct(this.state.product)} color="primary" autoFocus>
                            {this.props.LanguageStore.language.AGREE}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        height: '30vh',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default withStyles(useStyles, {withTheme: true})(CopyProduct);
