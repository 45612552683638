import React, {Component} from "react";
import Cropper from "react-easy-crop";
import {Button, Grid, Slider, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {getOrientation} from 'get-orientation/browser'
import {getCroppedImg, getRotatedImage} from "../ImageUtils/canvasUtils";
import {inject, observer} from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

@inject('CDNStore')
@observer
class ImageUpload extends Component {
    state = {
        activeStep: 0,
        loading: false,
        steps: ['Select image', 'Crop&Zoom image', 'Upload image'],
        imageSrc: null,
        crop: {x: 0, y: 0},
        croppedAreaPixels: null,
        croppedImage: null,
        rotation: 0,
        zoom: 1,
        aspect: (this.props.ratio)?(this.props.ratio):(4 / 3),

    }


    handleNext() {
        if (this.state.activeStep === 1) {
            this.showCroppedImage().then(r => this.setState({activeStep: this.state.activeStep + 1}));
        } else if (this.state.activeStep === 2) {
            this.handleUpload().then(r => this.setState({activeStep: this.state.activeStep + 1}));
        } else {
            this.setState({activeStep: this.state.activeStep + 1});
        }
    };

    handleBack() {
        this.setState({activeStep: this.state.activeStep - 1});
    };

    handleReset() {
        this.setState({activeStep: 0});
    };

    onCropChange = crop => {
        this.setState({crop})
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log("croppedAreaPixels", croppedAreaPixels);
        console.log(croppedArea, croppedAreaPixels)
        this.setState({croppedAreaPixels: croppedAreaPixels});
    }

    async showCroppedImage() {
        //console.log("do it", this.state.croppedAreaPixels, this.state.rotation);
        try {
            this.setState({loading: true});
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels,
                this.state.rotation
            )
            //console.log('donee', {croppedImage})
            this.setState({loading: false, croppedImage: croppedImage})
        } catch (e) {
            console.error(e)
        }
    }

    onZoomChange = zoom => {
        this.setState({zoom})
    }

    onClose() {
        console.log("on close");
        this.setState({croppedImg: null})
    }

    async onFileChange(e) {
        console.log(e, "do not");
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]

            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            this.setState({imageSrc: imageDataUrl});
            this.handleNext();
        }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    async handleUpload() {
        this.setState({loading: true});
        let block = this.state.croppedImage.split(";");
        // Get the content type of the image
        let contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        let realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        let blob = this.b64toBlob(realData, contentType);
        await this.props.CDNStore.uploadImage(blob);
        this.setState({loading: false});
        //this.setState({ open: false, img:null});
    }

    getStepContent(stepIndex) {
        const {classes} = this.props;
        switch (stepIndex) {
            case 0:
                return (<input type="file" onChange={(event) => this.onFileChange(event)} accept="image/*"/>);
            case 1:
                return (<React.Fragment>
                    <div className={classes.cropContainer}>
                        <Cropper
                            image={this.state.imageSrc}
                            crop={this.state.crop}
                            rotation={this.state.rotation}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={(crop) => this.setState({crop: crop})}
                            onRotationChange={(rotation) => this.setState({rotation: rotation})}
                            onCropComplete={(croppedArea, croppedAreaPixels) => this.onCropComplete(croppedArea, croppedAreaPixels)}
                            onZoomChange={(zoom) => this.setState({zoom: zoom})}
                        />
                    </div>
                    <div className={classes.controls}>
                        <div className={classes.sliderContainer}>
                            <Typography
                                variant="overline"
                                classes={{root: classes.sliderLabel}}
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={this.state.zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => this.setState({zoom: zoom})}
                            />
                        </div>
                        <div className={classes.sliderContainer}>
                            <Typography
                                variant="overline"
                                classes={{root: classes.sliderLabel}}
                            >
                                Rotation
                            </Typography>
                            <Slider
                                value={this.state.rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                onChange={(e, rotation) => this.setState({rotation: rotation})}
                            />
                        </div>
                        {/*<Button
                            onClick={()=>this.showCroppedImage()}
                            variant="contained"
                            color="primary"
                            classes={{ root: classes.cropButton }}
                        >
                            Show Result
                        </Button>*/}
                    </div>
                    {/*<ImgDialog img={this.state.croppedImage} onClose={()=>this.onClose()} />*/}
                </React.Fragment>);
            case 2:
                return (<div className={classes.imgContainer}>
                    <img src={this.state.croppedImage} alt="Cropped" className={classes.img}/>
                </div>);
            default:
                return (<div>Unknown stepIndex</div>);
        }
    }

    render() {
        const {classes} = this.props;
        return (<>
                <Backdrop className={classes.backdrop} open={this.state.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <div className={classes.root}>
                    <Stepper activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {this.state.activeStep === this.state.steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={() => this.handleReset()}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                <Grid container
                                      justify="center"
                                >
                                    <Grid item>
                                        {this.getStepContent(this.state.activeStep)}
                                    </Grid>
                                </Grid>
                                <div>
                                    <Button
                                        disabled={this.state.activeStep === 0}
                                        onClick={() => this.handleBack()}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary"
                                            disabled={!this.state.imageSrc}
                                            onClick={() => this.handleNext()}>
                                        {this.state.activeStep === this.state.steps.length - 1 ? 'Upload' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const useStyles = theme => ({
    cropContainer: {
        position: 'relative',
        width: 600,
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    imgContainer: {
        padding: 16,
        width: "100%",
        height: "100%",
    },
    img: {
        marginLeft: "auto",
        marginRight: "auto",
    },
});
export default withStyles(useStyles, {withTheme: true})(ImageUpload);
