import React, {Component} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {inject, observer} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {toJS} from "mobx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

@inject('CopyMenuStore', 'LayoutStore', 'AddProductStore','LanguageStore')
@observer
class CopyMenu extends Component {

    state = {
        dialog: false,
        groups: [],
        count: 0,
        groupId: "-1",
        group: [],
        productId: "-1",
        product: [],
        copying: true
    };

    async componentDidMount() {
        await this.props.CopyMenuStore.getProductGroups(-1).then(() =>
            this.setState({dialog: this.props.open, groups: this.props.CopyMenuStore.productGroups})
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({dialog: this.props.open});
        }
    }

    getCount() {
        let count = 0;
        this.state.product.map((menu) => {
            if (!menu.disabled){
                count++;
                menu.product_items.map((product) => {
                    if (!product.disabled){
                        count++;
                    }
                });
            }
        });
        console.log(count);
        this.setState({count: count});
    }

    async startCopy() {
        let iteration = 0;
        await this.getCount();
        console.log(toJS(this.props));
        await Promise.all(this.state.product.map(async (menu, i) => {
            if (!menu.disabled) {
                //console.log("menu bu", toJS(menu));
                iteration++;
                console.log(iteration + "/" + this.state.count);
                await this.props.CopyMenuStore.addProductMenu(this.props.data.productId, menu.menu_name, 0, menu.menu_type, (menu.menu_sort)?(menu.menu_sort):(i+1));
                await Promise.all( menu.product_items.map(async (product, j) => {
                    if (!product.disabled) {
                        iteration++;
                        console.log("item copy");
                        console.log(iteration + "/" + this.state.count);
                        console.log(this.props.CopyMenuStore.justAddedMenuId, 1, this.props.data.productId, product.product.id, product.item_price);
                        await this.props.CopyMenuStore.addProductMenuItem(this.props.CopyMenuStore.justAddedMenuId, 1, this.props.data.productId, product.product.id, product.item_price, (product.item_sort)?(product.item_sort):(j+1));
                    }
                }));
            }
        }));
        await this.props.AddProductStore.getProduct(this.props.data.productId).then(r => {
            this.props.LayoutStore.toggleCopyDialog();
            this.refreshPage();
        });
    }

    refreshPage() {
        window.location.reload(false);
    }

    getGroup(groupId) {
        let group = this.state.groups.find(el => el.id === groupId);
        this.setState({group: group, productId: "-1"});
    }

    updateTemplate(group, item) {
        console.log("group and item", group, item);
        if (item !== -1) {
            let products = this.state.product

            let sss = this.state.product[group]
            let bbb = this.state.product[group].product_items[item];
            let aaa = this.state.product[group].product_items[item].disabled
            bbb.disabled = !aaa
            sss.product_items[item] = bbb
            products[group] = sss;

            this.setState({product: products})
        } else if (item === -1) {
            let products = this.state.product;
            let sss = this.state.product[group];

            let ccc = sss.disabled
            sss.disabled = !ccc
            sss.product_items.map(sitem => {
                sitem.disabled = !ccc
            })


            //let bbb = this.state.product[group].product_items[item];
            //let aaa = this.state.product[group].product_items[item].disabled
            //bbb.disabled = !aaa

            //sss.product_items[item] = bbb
            products[group] = sss;
            this.setState({product: products})
        }
        console.log("asa", this.state.product);
        //console.log("asa",group);
    }

    modMenu(product) {
        let json;
        json = product.product_menu.map((menu) => {
            let prod;
            prod = menu.product_items.map((product) => {
                product['disabled'] = false;
                return toJS(product);
            });
            menu['disabled'] = false;
            menu['product_items'] = prod
            return toJS(menu);
        });

        return json;
    }

    async getProduct(productId) {
        await this.props.CopyMenuStore.getProduct(productId).then(() => {
            //this.modMenu(this.props.CopyMenuStore.product);
            this.setState({product: this.modMenu(this.props.CopyMenuStore.product)});
        });
    }


    handleClose() {
        this.props.LayoutStore.toggleCopyDialog();
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Dialog fullScreen open={this.props.LayoutStore.copyDialog} onClose={() => this.handleClose()}
                        TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => this.handleClose()}
                                        aria-label="close">
                                <Close/>
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.props.LanguageStore.language.COPY_MENU_DESC}
                            </Typography>
                            <Button autoFocus color="inherit" onClick={() => this.handleClose()}>
                                {this.props.LanguageStore.language.CLOSE}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Backdrop className={classes.backdrop} open={this.props.CopyMenuStore.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={1} className={classes.paper}>
                                <Typography variant="h6">
                                    {this.props.LanguageStore.language.PRODUCT_GROUP}
                                </Typography>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.PRODUCT_GROUP}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.groupId}
                                        onChange={(e) => {
                                            this.setState({groupId: e.target.value});
                                            this.getGroup(e.target.value);
                                        }}
                                        label={this.props.LanguageStore.language.PRODUCT_GROUP}
                                    >
                                        <MenuItem value={-1}>
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.groups.map((group) => (
                                            <MenuItem key={group.id}
                                                      value={group.id}>{group.product_group_main.main_group_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {(() => {
                                    if (this.state.group && (this.state.group.products) && (this.state.group.products.length)) {
                                        return (
                                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.PRODUCT}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.productId}
                                                    onChange={(e) => {
                                                        this.setState({productId: e.target.value});
                                                        this.getProduct(e.target.value);
                                                    }}
                                                    label={this.props.LanguageStore.language.PRODUCT}
                                                >
                                                    <MenuItem value={-1}>
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {this.state.group.products && this.state.group.products.map((product) => (
                                                        <MenuItem key={product.id}
                                                                  value={product.id}>{product.product_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )
                                    } else {
                                        return (<p>{this.props.LanguageStore.language.EMPTY}</p>);
                                    }
                                })()}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={1} className={classes.paper}>
                                <Typography variant="h6">
                                    {this.props.LanguageStore.language.MENU_TO_COPY}:
                                </Typography>
                                {(() => {
                                    if (this.state.product && this.state.product && this.state.product.length > 0) {
                                        return (<List>
                                            <ListSubheader>{this.props.LanguageStore.language.MENU}</ListSubheader>
                                            {(
                                                this.state.product.map((menu, mi) => (
                                                    <div key={menu.id}>
                                                        <ListItem>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={!menu.disabled}
                                                                onChange={() => this.updateTemplate(mi, -1)}
                                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                                            />
                                                            <ListItemText className={classes.menuName}
                                                                          primary={menu.menu_name}/>
                                                        </ListItem>
                                                        {(menu.product_items) && (
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <List component={'div'} disablePadding
                                                                      className={classes.nested}>
                                                                    {(menu.product_items.map((item, i) => (
                                                                        <ListItem key={item.id}>
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={!item.disabled}
                                                                                onChange={() => this.updateTemplate(mi, i)}
                                                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                                                            />
                                                                            <ListItemText
                                                                                primary={item.product.product_name}/>
                                                                        </ListItem>
                                                                    )))}
                                                                </List>
                                                            </Collapse>)}
                                                    </div>
                                                ))
                                            )}
                                        </List>);
                                    } else {
                                        return (<p>{this.props.LanguageStore.language.EMPTY}</p>);
                                    }
                                })()}
                                <Button
                                    disabled={!(this.state.product && this.state.product.length > 0)}
                                    variant={"outlined"} onClick={() => this.startCopy()}>{this.props.LanguageStore.language.ADD}</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
        );
    }
}

const useStyles = (theme) => ({

    appBar: {
        position: 'relative',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    menuName: {
        fontWeight: 'bold',
    }
});

export default withStyles(useStyles, {withTheme: true})(CopyMenu);
