import React,{Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import {inject, observer} from "mobx-react";
import {QR_URL} from "../../constants";


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

@inject('AccountStore', 'LanguageStore','SettingsStore')
@observer
class Settings extends Component{
    state = {
        value: 0,
        selected_table:0
    }
    async componentDidMount() {
        await this.props.SettingsStore.getQr();
    }

    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };
    createConfirmation() {
        this.props.AccountStore.createConfirmation(this.state.selected_table).then(() => {
            this.setState({selected_table: 0});
            this.props.SettingsStore.addQr(`${QR_URL}${this.props.AccountStore.confirmation.id}/${this.props.AccountStore.confirmation.code}`).then(() => this.props.SettingsStore.getQr());
        });
    }

     downloadQR () {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        const {classes}=this.props;
        console.log(this.props.SettingsStore.qr);
        return(<div className={classes.root}>
            <AppBar position="static">
                <Tabs value={this.state.value} onChange={(event, value) => {
                    this.handleChange(event, value)
                }} aria-label="Settings">
                    <Tab label={"QR"} {...a11yProps(0)} />
                </Tabs>
            </AppBar>
            <TabPanel value={this.state.value} index={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>);
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    fixedHeight: {
        height: 576,
    },
    margin: {
        margin: theme.spacing(1),
    },
    QRCard:{
        maxWidth:482,
        marginInlineStart:"auto"
    },
    qrCode:{
        margin:1
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default  withStyles(useStyles, {withTheme: true})(Settings);
