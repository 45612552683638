import qs from 'qs';
import {action, observable, runInAction} from "mobx";
import {CDN_API} from "../constants";
import AsyncStorage from "@callstack/async-storage/lib";
import axios from "axios";
import LayoutStore from "./LayoutStore";


class CDNStore {
    @observable cdn_user = "bayramgeldi.bayriyev@gmail.com";
    @observable cdn_password = "rubin21";
    @observable imageUploadDialog = false;
    @observable active_cdn_user = null;
    @observable email = "";
    @observable token = "";
    @observable loading=false;
    @observable imageUploaded=null;


    constructor(props) {
            this.setupAuth();
    }

    @action
    async getCDNToken() {
        try {
            this.loading = true;
            const {data} = await axios({
                url: `${CDN_API}/login`,
                method: 'post',
                data: qs.stringify({
                    email: this.cdn_user,
                    password: this.cdn_password,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                alert("Error1!!!")
                this.loading = false;
                return false;
            }
            runInAction(() => {
                this.loading = false;
                //alert(data.data.signIn.token)
                this.saveToken(data.access_token);
            })
        } catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action
    async saveToken(cdn_token) {
        try {
            await AsyncStorage.setItem('cdn_token', cdn_token);
            await this.setupAuth();

        } catch (e) {
            console.log("Hata1");
            console.log(e)
        }
    }

    @action
    async tokenControl() {
        try {
            const token = await AsyncStorage.getItem('cdn_token');
            const {data} = await axios({
                url: `${CDN_API}/me`,
                method: 'post',
                data: qs.stringify({
                    token: token,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });

            if (!token) {
                return false;
            }
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Uzun zamandır giriş yapılmamış, tekrar giriş yapmanız gerek.");
                await this.removeToken();
                return false;
            }
            runInAction(() => {
                this.active_cdn_user = data.id;
            });
            return token;

        } catch (e) {
            alert("I " + e);
            return false;
        }
    }

    @action
    async removeToken() {
        try {
            await AsyncStorage.removeItem('cdn_token');
            this.token = null;
            await this.setupAuth();

        } catch (e) {
            console.log(e)
        }
    }

    @action
    async setupAuth() {
        const cdnToken = await AsyncStorage.getItem('cdn_token');
        if (!cdnToken){
            await this.getCDNToken();
        }
    }

    @action toggleDialog() {
        runInAction(() => {
            this.imageUploadDialog = !this.imageUploadDialog;
        });
    }

    @action async uploadImage(image){
        try {
            this.loading = true;
            const token = await AsyncStorage.getItem('cdn_token');
            //const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jZG4uZmFzdHJlc3QuY29tLnRyXC9cL2FwaVwvbG9naW4iLCJpYXQiOjE2MTYxMTAxNjMsImV4cCI6MTYxNjExMzc2MywibmJmIjoxNjE2MTEwMTYzLCJqdGkiOiJMRDl6RGUyT0c2T0UwQ1ZnIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.awvoKrTk1iNuZ-kDSt5MhpDUPwoWsuZ1QkKmkDzf4X0";
            if (!token) {
                return false;
            }
            let datum = new FormData();
            datum.append('image', image);
            const {data} = await axios({
                url: `${CDN_API}/upload`,
                method: 'post',
                data: datum,
                headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (data.status==="success"){
                runInAction(() => {
                    this.loading = false;
                    //alert(data.data.signIn.token)
                    console.log("log data",data.data);
                    this.imageUploaded=data.data.src;
                    this.imageUploadDialog=false;
                    LayoutStore.enqueueSnackbar({
                        message: 'Uploaded Image successfully!',
                        options: {
                            variant: 'success',
                        },
                    });
                });
            }else {
                console.log(data);
                if (data.err_.code===1){
                    runInAction(() => {
                        this.loading = false;
                        this.imageUploadDialog=false;
                        LayoutStore.enqueueSnackbar({
                            message: data.err_.message,
                            options: {
                                variant: 'error',
                            },
                        });

                        this.getCDNToken();
                    });
                }else {
                    runInAction(() => {
                        this.loading = false;
                        this.imageUploadDialog=false;
                        LayoutStore.enqueueSnackbar({
                            message: 'Something went wrong!',
                            options: {
                                variant: 'error',
                            },
                        });
                    });
                }
            }

        } catch (e) {
            console.log("error", e);
            this.loading = false;
            this.imageUploadDialog=false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                });
            });
            return false;
        }
    }
    @action async uploadFile(image, type){
        try {
            this.loading = true;
            const token = await AsyncStorage.getItem('cdn_token');
            let datum = new FormData();

            if (!token) {
                return false;
            }
            if (type==="application/pdf")
            {
                datum.append('file', image);
                const {data} = await axios({
                    url: `${CDN_API}/pdf/upload`,
                    method: 'post',
                    data: datum,
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (data.status==="success"){
                    runInAction(() => {
                        this.loading = false;
                        //alert(data.data.signIn.token)
                        console.log("log data",data.data);
                        this.imageUploaded=data.data.src;
                        this.imageUploadDialog=false;
                        LayoutStore.enqueueSnackbar({
                            message: 'Uploaded File successfully!',
                            options: {
                                variant: 'success',
                            },
                        });
                    });
                }else {
                    //console.log(data);
                    if (data.err_.code===1){
                        runInAction(() => {
                            this.loading = false;
                            this.imageUploadDialog=false;
                            LayoutStore.enqueueSnackbar({
                                message: data.err_.message,
                                options: {
                                    variant: 'error',
                                },
                            });

                            this.getCDNToken();
                        });
                    }else {
                        runInAction(() => {
                            this.loading = false;
                            this.imageUploadDialog=false;
                            LayoutStore.enqueueSnackbar({
                                message: 'Something went wrong!',
                                options: {
                                    variant: 'error',
                                },
                            });
                        });
                    }
                }
            }else
            {
                datum.append('image', image);
                const {data} = await axios({
                    url: `${CDN_API}/upload`,
                    method: 'post',
                    data: datum,
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (data.status==="success"){
                    runInAction(() => {
                        this.loading = false;
                        //alert(data.data.signIn.token)
                        console.log("log data",data.data);
                        this.imageUploaded=data.data.src;
                        this.imageUploadDialog=false;
                        LayoutStore.enqueueSnackbar({
                            message: 'Uploaded Image successfully!',
                            options: {
                                variant: 'success',
                            },
                        });
                    });
                }else {
                    console.log(data);
                    if (data.err_.code===1){
                        runInAction(() => {
                            this.loading = false;
                            this.imageUploadDialog=false;
                            LayoutStore.enqueueSnackbar({
                                message: data.err_.message,
                                options: {
                                    variant: 'error',
                                },
                            });

                            this.getCDNToken();
                        });
                    }else {
                        runInAction(() => {
                            this.loading = false;
                            this.imageUploadDialog=false;
                            LayoutStore.enqueueSnackbar({
                                message: 'Something went wrong!',
                                options: {
                                    variant: 'error',
                                },
                            });
                        });
                    }
                }
            }
        } catch (e) {
            console.log("error", e);
            this.loading = false;
            this.imageUploadDialog=false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                });
            });
            return false;
        }
    }

    @action resetImageUploaded(){
        runInAction(()=>{
            this.imageUploaded=null;
        });
    }
}

export default new CDNStore();
