import qs from 'qs';
import {action, observable, runInAction, toJS} from "mobx";
import {CRM_API} from "../constants";
import AsyncStorage from "@callstack/async-storage/lib";
import axios from "axios";
import LayoutStore from "./LayoutStore";


class TicketStore {
    @observable crm_user = "bayramgeldi.bayriyev@gmail.com";
    @observable crm_password = "rubin21";
    @observable ticketDialog = false;
    @observable active_crm_user = null;
    @observable email = "";
    @observable token = "";
    @observable t_activity_sreason = 6
    @observable t_activityd_person = 518
    @observable t_activityd_detail = "Lütfen yarıdm edin 518"
    @observable t_activity_job = 7
    @observable t_activity_account = 65
    @observable  t_activity_person = 13
    @observable t_activity_group = 4
    @observable t_activity_reason = 53
    @observable t_activity_header = 386
    @observable t_activity_user = 9
    @observable t_activity_owner = 65

    @observable message=""
    @observable subject=""
    @observable subjectId=""


    constructor(props) {
        this.setEmail().then(r => {
            this.setupAuth();
        });
    }

    @action
    async setEmail() {
        this.email = await AsyncStorage.getItem("user_email");
    }

    @action
    async getCRMToken() {
        try {
            this.loading = true;
            const {data} = await axios({
                url: `${CRM_API}/login`,
                method: 'post',
                data: qs.stringify({
                    email: this.crm_user,
                    password: this.crm_password,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                alert("Error1!!!")
                this.loading = false;
                return false;
            }
            runInAction(() => {
                this.loading = false;
                //alert(data.data.signIn.token)
                this.saveToken(data.result);
            })
        } catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action
    async saveToken(crm_token) {
        try {
            await AsyncStorage.setItem('crm_token', crm_token);
            await this.setupAuth();

        } catch (e) {
            console.log("Hata1");
            console.log(e)
        }
    }

    @action
    async tokenControl() {
        try {
            const token = await AsyncStorage.getItem('crm_token');
            const {data} = await axios({
                url: `${CRM_API}/get_user_details`,
                method: 'post',
                data: qs.stringify({
                    token: token,
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });

            if (!token) {
                return false;
            }
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Uzun zamandır giriş yapılmamış, tekrar giriş yapmanız gerek.");
                await this.removeToken();
                return false;
            }
            runInAction(() => {
                this.active_crm_user = data.result.id;
            });
            return token;

        } catch (e) {
            alert("I " + e);
            return false;
        }
    }

    @action
    async removeToken() {
        try {
            await AsyncStorage.removeItem('crm_token');
            this.token = null;
            await this.setupAuth();

        } catch (e) {
            console.log(e)
        }
    }

    @action
    async setupAuth() {
        const crmToken = await AsyncStorage.getItem('crm_token');
        if (!crmToken){
            await this.getCRMToken();
        }
    }

    @action toggleDialog() {
        runInAction(() => {
            this.ticketDialog = !this.ticketDialog;
        });
    }

    @action suggestedTicket(message, subject, subjectId){
        runInAction(() => {
            this.ticketDialog = !this.ticketDialog;
            this.message=message;
            this.subject=subject;
            this.subjectId=subjectId;

        });
    }

    @action
    async insertActivity(message, email, subject, subjectId) {

        try {
            this.loading = true;
            const token = await AsyncStorage.getItem('crm_token');
            const username = await AsyncStorage.getItem('user_name');
            const userid = await AsyncStorage.getItem('user_id');
            const account = await AsyncStorage.getItem('accountNo');
            let totalMessage = `[Subject]: ${subject}<br>
                                [E-Mail]: ${email}<br>
                                [Username]: ${username}<br>
                                [UserId]: ${userid}<br>
                                [Account Number]: ${account}<br>
                                [Message]:${message}`;
            if (!token) {
                return false;
            }
            const {data} = await axios({
                url: `${CRM_API}/insert_activity`,
                method: "post",
                data: qs.stringify({
                    token: token,
                    t_activity_sreason: this.t_activity_sreason,
                    t_activityd_person: this.t_activityd_person,
                    t_activityd_detail: `${totalMessage}`,
                    t_activity_job: this.t_activity_job,
                    t_activity_account: this.t_activity_account,
                    t_activity_person: this.t_activity_person,
                    t_activity_group: this.t_activity_group,
                    t_activity_reason: this.t_activity_reason,
                    t_activity_header: (subjectId && subjectId !== "") ? (parseInt(subjectId)) : (this.t_activity_header),
                    t_activity_user: this.t_activity_user,
                    t_activity_owner: this.t_activity_owner
                }),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: TicketStore at insertActivity!");
                return false;
            }
            console.log("saved successfully", toJS(data));

            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Saved ticket successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: TicketStore at insertActivity!");
        }
    }
}

export default new TicketStore();
