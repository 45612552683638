import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText, Paper,
    Step,
    StepLabel,
    Stepper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow,
    Typography
} from "@material-ui/core";
import CreateUser from "../auth/CreateUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {inject, observer} from "mobx-react";
import AssignRole from "../../components/Registration/AssignRole";
import CreateBrand from "../../components/Registration/CreateBrand";
import CreateAccount from "../../components/Registration/CreateAccount";
import AssignAccount from "../../components/Registration/AssignAccount";
import {withRouter} from "react-router-dom";

function getSteps() {
    return ['Create User', 'Assign User Role', 'Create Brand', 'Create Account', 'Assign User Account', 'Finish and clear'];
}

function getStepContent(step, props) {
    switch (step) {
        case 0:
            if (props.user) {
                return (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{"ID"}</TableCell>
                                    <TableCell align="center">{"Name"}</TableCell>
                                    <TableCell align="center">{"E-Mail"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align={"center"}>{props.user.id}</TableCell>
                                    <TableCell align={"center"}>{props.user.name}</TableCell>
                                    <TableCell align={"center"}>{props.user.email}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table></TableContainer>);
            } else {
                return (<CreateUser/>);
            }
        case 1:
            if (props.isRoleAssigned) {
                return (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{"ID"}</TableCell>
                                    <TableCell align="center">{"Name"}</TableCell>
                                    <TableCell align="center">{"Role"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align={"center"}>{props.user.id}</TableCell>
                                    <TableCell align={"center"}>{props.user.name}</TableCell>
                                    <TableCell align={"center"}>{"Account Admin"}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table></TableContainer>);
            } else {
                return (<AssignRole/>);
            }
        case 2:
            if (props.brand) {
                return (<TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{"ID"}</TableCell>
                                <TableCell align="center">{"Brand Name"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align={"center"}>{props.brand.id}</TableCell>
                                <TableCell align={"center"}>{props.brand.brand_name}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table></TableContainer>);
            } else {
                return (<CreateBrand/>);
            }
        case 3:
            if (props.account) {
                return (<TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{"ID"}</TableCell>
                                <TableCell align="center">{"Account Name"}</TableCell>
                                <TableCell align="center">{"Account E-Mail"}</TableCell>
                                <TableCell align="center">{"Account GSM"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align={"center"}>{props.account.id}</TableCell>
                                <TableCell align={"center"}>{props.account.account_title}</TableCell>
                                <TableCell align={"center"}>{props.account.account_e_mail}</TableCell>
                                <TableCell align={"center"}>{props.account.account_gsm_no}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table></TableContainer>);
            } else {
                return (<CreateAccount/>);
            }
        case 4:
            if (props.isAccountAssigned) {
                return (<p>Assigned</p>);
            } else {
                return (<AssignAccount/>);
            }
        case 5:
            return (<TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{"Account ID"}</TableCell>
                            <TableCell align="center">{"User Name"}</TableCell>
                            <TableCell align="center">{"User Email"}</TableCell>
                            <TableCell align="center">{"Brand"}</TableCell>
                            <TableCell align="center">{"Account Name"}</TableCell>
                            <TableCell align="center">{"Account E-Mail"}</TableCell>
                            <TableCell align="center">{"Account GSM"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align={"center"}>{props.account?.id}</TableCell>
                            <TableCell align={"center"}>{props.user?.name}</TableCell>
                            <TableCell align={"center"}>{props.user?.email}</TableCell>
                            <TableCell align={"center"}>{props.brand?.id}</TableCell>
                            <TableCell align={"center"}>{props.account?.account_title}</TableCell>
                            <TableCell align={"center"}>{props.account?.account_e_mail}</TableCell>
                            <TableCell align={"center"}>{props.account?.account_gsm_no}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table></TableContainer>);
        default:
            return 'Unknown step';
    }
}

@inject('RegistrationStore', 'AuthStore')
@observer
class RegistrationSteps extends Component {
    state = {
        activeStep: 0,
        skipped: new Set(),
    }

    componentDidMount() {
        this.setState({activeStep: this.props.RegistrationStore.step});
        if (this.props.AuthStore.user_role !== "SUPER_ADMIN") {
            this.props.history.push({pathname: `/`})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.activeStep !== this.props.RegistrationStore.step) {
            this.setState({activeStep: this.props.RegistrationStore.step});
        }
    }

    render() {
        const {classes} = this.props;
        const steps = getSteps();
        //console.log(this.state.skipped);
        const isStepOptional = (step) => {
            return step === 2;
        };

        const isStepSkipped = (step) => {
            return this.state.skipped.has(step);
        };
        const handleNext = async () => {
            let newSkipped = this.state.skipped;
            if (isStepSkipped(this.state.activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(this.state.activeStep);
            }

            await this.setState({activeStep: this.state.activeStep + 1})
            await this.setState({skipped: newSkipped});
            await this.props.RegistrationStore.setStep(this.state.activeStep + 1);
        };
        const handleBack = async () => {
            this.setState({activeStep: this.state.activeStep - 1})
            await this.props.RegistrationStore.setStep(this.state.activeStep - 1);

        };
        const handleSkip = () => {
            if (!isStepOptional(this.state.activeStep)) {
                // You probably want to guard against something like this,
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }

            this.setState({activeStep: this.state.activeStep + 1})
            this.setState({skipped: skipped()});

        };
        const skipped = () => {
            const newSkipped = new Set(this.state.skipped.values());
            newSkipped.add(this.state.activeStep);
            return newSkipped;
        };
        const handleReset = async () => {
            await this.props.RegistrationStore.clearRegistration();
            this.setState({activeStep: 0});
        };

        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={this.props.RegistrationStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Stepper activeStep={this.state.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {this.state.activeStep === steps.length ? (
                        <div>
                            <Typography variant={"h5"} style={{marginTop:10, marginBottom:10}} align={"center"}>
                                Tüm adımlar tamamlandı. Gerekli Notları aldıysanız, attaki butondan işlemleri sıfırlayabilirsiniz
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">{"Account ID"}</TableCell>
                                            <TableCell align="center">{"User Name"}</TableCell>
                                            <TableCell align="center">{"User Email"}</TableCell>
                                            <TableCell align="center">{"Brand"}</TableCell>
                                            <TableCell align="center">{"Account Name"}</TableCell>
                                            <TableCell align="center">{"Account E-Mail"}</TableCell>
                                            <TableCell align="center">{"Account GSM"}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.account?.id}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.user?.name}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.user?.email}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.brand?.id}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.account?.account_title}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.account?.account_e_mail}</TableCell>
                                            <TableCell align={"center"}>{this.props.RegistrationStore.account?.account_gsm_no}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table></TableContainer>
                            <Button onClick={handleReset} style={{marginTop:20}} variant={"contained"} className={classes.button}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Box
                                className={classes.instructions}>{getStepContent(this.state.activeStep, this.props.RegistrationStore)}</Box>
                            <div>
                                <Button disabled={this.state.activeStep === 0} onClick={handleBack}
                                        className={classes.button}>
                                    Back
                                </Button>
                                {isStepOptional(this.state.activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(RegistrationSteps));
