import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import {withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import {Add} from "@material-ui/icons";
import Image from 'material-ui-image';
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

@inject("AddProductStore", "VariablesStore","ProductGroupStore",'LanguageStore')
@observer
class AddProduct extends Component {
    state = {
        productName: "",
        productNumber: "",
        productActive: true,
        productType: "",
        mainGroupName: "",
        mainGroupId: null,
        isMenu:false,
        imageUrl: "https://dummyimage.com/600x400/000/fff",
        imageModal:false
    }

    componentDidMount() {
        this.props.AddProductStore.getProductTypes();
        this.props.ProductGroupStore.getProductGroups(-1);
        this.setState({
            mainGroupId: this.props.VariablesStore.groupId
        });
        this.props.AddProductStore.history=this.props.history;
    }

    handleSubmitImage(){
        this.handleClose();
    }
    handleClose(){
        this.setState({imageModal:false});
    }

    handleSubmit() {
        console.log(this.state, this.props.VariablesStore.groupId);
        this.props.AddProductStore.addProduct(this.state.productName, this.state.productNumber, this.state.productActive, this.state.productType, this.props.VariablesStore.groupId, this.state.imageUrl, this.state.isMenu).then(r => this.setState(
            {
                productName: "",
                productNumber: "",
                productActive: true,
                isMenu:false
            }
        ));
    }

    validateForm() {
        return true;
    }

    render() {
        const {classes} = this.props;
        console.log(this.state.isMenu);
        return (
            <div>
                <Backdrop className={classes.backdrop} open={this.props.AddProductStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={3}>
                            <Card className={classes.productImg} elevation={1}>
                                {(this.state.imageUrl)?(<Image
                                    onClick={()=>this.setState({imageModal: true})}
                                    src={this.state.imageUrl}
                                />):(
                                    <IconButton color={"primary"} onClick={()=>this.setState({imageModal: true})}>
                                        <Tooltip title={this.props.LanguageStore.language.ADD_PRODUCT_IMAGE} >
                                            <Add/>
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </Card>
                            <br/>
                            <Typography variant={"body1"}>*Ürün görselini değiştirmek için önce ürünü oluşturmanız gerekir. Yada görsel online kaynaktaysa kutuya tıklayarak ekleyebilirsiniz</Typography>
                        </Grid>
                        <Grid item xs={6} md={8} lg={9}>
                            {/*ProductForm*/}
                            <Typography variant="overline" display="inline">{this.state.mainGroupName}</Typography>
                            <List component="nav"
                                  subheader={"Please fill fields below"}>
                                <ListItem>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel id="product-group-label">{this.props.LanguageStore.language.PRODUCT_GROUP}</InputLabel>
                                        <Select
                                            labelId="product-group-label"
                                            id="product-group"
                                            label={this.props.LanguageStore.language.PRODUCT_GROUP}
                                            value={(this.props.VariablesStore.groupId)?(this.props.VariablesStore.groupId):("-1")}
                                            onChange={(event) => {
                                                this.props.VariablesStore.setGroup(event.target.value)
                                            }}
                                            fullWidth={true}
                                        >
                                            <MenuItem value="-1">
                                                <em>None</em>
                                            </MenuItem>
                                            {((toJS(this.props.ProductGroupStore.productGroups)) && ((Array.isArray(toJS(this.props.ProductGroupStore.productGroups))) ? (
                                                toJS(this.props.ProductGroupStore.productGroups).map((group) => (
                                                    <MenuItem key={group.id} value={group.product_group_main.id}>{group.product_group_main.main_group_name}</MenuItem>
                                                ))
                                            ) : null))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <br/>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel id="product-type-label">{this.props.LanguageStore.language.PRODUCT_TYPE}</InputLabel>
                                        <Select
                                            labelId="product-type-label"
                                            id="product-type"
                                            label={this.props.LanguageStore.language.PRODUCT_TYPE}
                                            value={this.state.productType}
                                            onChange={(event) => {

                                                this.setState({productType: event.target.value});
                                            }}
                                            fullWidth={true}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {((toJS(this.props.AddProductStore.productTypes)) && ((Array.isArray(toJS(this.props.AddProductStore.productTypes))) ? (
                                                toJS(this.props.AddProductStore.productTypes).map((type) => (
                                                    <MenuItem key={type.id} value={type.id}>{type.type_name}</MenuItem>
                                                ))
                                            ) : null))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        label={this.props.LanguageStore.language.NAME}
                                        id="name"
                                        autoFocus={true}
                                        value={this.state.productName}
                                        placeholder={this.props.LanguageStore.language.PRODUCT_NAME}
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            this.setState({productName: event.target.value});
                                        }}
                                        fullWidth={true}
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        label={this.props.LanguageStore.language.PRODUCT_NUMBER}
                                        id="product-number"
                                        value={this.state.productNumber}
                                        placeholder={this.props.LanguageStore.language.PRODUCT_NUMBER}
                                        variant="outlined"
                                        size="small"
                                        type={'number'}
                                        onChange={(event) => {
                                            this.setState({productNumber: event.target.value});
                                        }}
                                        fullWidth={true}
                                    />
                                </ListItem>
                                <ListItem>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.isMenu} onChange={(event) => {
                                                this.setState({isMenu: event.target.checked});
                                            }} name="is_menu"/>}
                                            label={"Menu"}/>
                                    </FormGroup>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.productActive} onChange={(event) => {
                                                this.setState({productNumber: event.target.checked});
                                            }} name="product_active"/>}
                                            label={this.props.LanguageStore.language.ACTIVE}/>
                                    </FormGroup>
                                </ListItem>
                                <ListItem>
                                    <Button variant="outlined"
                                            disabled={!(this.state.productName && this.state.productNumber && this.props.VariablesStore.groupId)}
                                            onClick={() => {
                                                this.handleSubmit()
                                            }}
                                            fullWidth>{this.props.LanguageStore.language.ADD_PRODUCT}</Button>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                <br/>
                {/*Add image url*/}
                <Dialog open={this.state.imageModal} onClose={()=>this.handleClose()} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Image Url</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder={"http://"}
                            label="Url"
                            type="text"
                            value={this.state.imageUrl}
                            onChange={(e)=>this.setState({imageUrl:e.target.value})}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.handleClose()} color="secondary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={!this.state.imageUrl} onClick={()=>this.handleSubmitImage()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/*More details*/}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const useStyles = theme => ({
    productImg: {
        maxWidth: 240,
        paddingLeft: "10px",
        textAlign:"center",
        lineHeight:16
    },
    imageUpload: {
        padding: "6px"
    },
    dropzoneText: {
        fontSize: "small"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

});

export default withRouter(withStyles(useStyles, {withTheme: true})(AddProduct));
