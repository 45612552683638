import {action, observable, runInAction} from "mobx";
import axios from "axios";
import {API_BASE} from "../constants";
import AsyncStorage from "@callstack/async-storage";
import {GET_ACCOUNT_ROOMS} from "../graphql/Queries";
import LayoutStore from "./LayoutStore";
import {ADD_ROOM, ADD_ROOM_TABLE, UPDATE_ROOM, UPDATE_ROOM_TABLE} from "../graphql/Mutations";

class AccountRoomAndTableStore {
    @observable rooms = [];
    @observable room = [];
    @observable loading = false;

    @action async getRooms() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ACCOUNT_ROOMS(parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at getRooms()!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.rooms = data.data.account_rooms;
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at getRooms()!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addRoom(name, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `mutation {${ADD_ROOM(name,active, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at addRoom()!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Room successfully!',
                options: {
                    variant: 'success',
                },
            }));
            await this.getRooms();
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at addRoom()!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRoom(id, name, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `mutation {${UPDATE_ROOM(id, name,active, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at updateRoom()!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Room successfully!',
                options: {
                    variant: 'success',
                },
            }));
            await  this.getRooms();
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at updateRoom()!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addRoomTable(name, room, x, y, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `mutation {${ADD_ROOM_TABLE(name, room, parseInt(accountId), x, y, active)}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at addRoomTable()!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            await this.getRooms();
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Table successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at addRoomTable()!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRoomTable(id, name, room, x, y, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `mutation {${UPDATE_ROOM_TABLE(id, name, room, x, y, active)}}`
                }
            });
            if (data.errors) {
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at updateRoomTable()!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Table successfully!',
                options: {
                    variant: 'success',
                },
            }));
            await this.getRooms();
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountRoomAndTableStore at updateRoomTable()!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
}

export default new AccountRoomAndTableStore();
