import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {inject, observer} from "mobx-react";
import Button from "@material-ui/core/Button";
import {Add, Delete, Home, LocationCity, Place} from "@material-ui/icons";
import {
    Card,
    CardContent,
    FormControl, InputLabel,
    List,
    ListItem, MenuItem, Select,
    Tooltip
} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import {toJS} from "mobx";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));
function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

@inject('LanguageStore','AccountStore','AddressStore','TicketStore', 'DataStore')
@observer
class NearPlaces extends Component {

    state={
        addModal:false,
        addressId:null,
        suggestThis:"",
        addressType:"",
        deleteModal:false,
        placeId:null,
    };

    async componentDidMount() {
        await this.props.DataStore.getAddressTypes();
        await this.props.AccountStore.getNearPlaces();
        await this.props.AddressStore.getAllAddresses(this.props.DataStore.addressTypes[0].id);
    }

    suggestTicket(value) {
        console.log("please create ticket", value);
        this.props.TicketStore.suggestedTicket(`"${value.inputValue}"`, "Yeni adres ekleme isteği", 386);
    }

    async handleAddModalConfirm() {
        console.log("add", this.state);
        await this.props.AccountStore.addNearPlaces(this.state.addressId);
        await this.props.AccountStore.getNearPlaces();
        this.setState({
            addressId:null,
            addModal:false,
            suggestThis:""
        });
    }

    async handleDelete(){
        await this.props.AccountStore.deleteNearPlaces(this.state.placeId);
        await this.props.AccountStore.getNearPlaces();
        this.setState({
            placeId:null,
            deleteModal:false,
        });
    }
    async handleTypeChange(type) {
        this.setState({addressType: type});
        await this.props.AddressStore.getAllAddresses(type);
    }

    render() {
        const {classes}=this.props;
        //console.log(toJS(this.props.DataStore.addressTypes));
        const filter = createFilterOptions();
        return (
            <>
                <Backdrop className={classes.backdrop} open={this.props.AccountStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="overline" gutterBottom>{this.props.LanguageStore.language.NEAR_PLACES}</Typography>
                            <Button startIcon={<Add/>} onClick={() => this.setState({addModal:true})}>{this.props.LanguageStore.language.ADD}</Button>
                            <List style={{overflow: 'auto',
                                maxHeight: 400,
                            }}>
                                {(this.props.AccountStore.nearPlaces.map((place, index) => (
                                    <ListItem key={index}>
                                        <Card className={classes.fullWidth}>
                                            <CardContent style={{paddingBottom: 16}}>
                                                <Grid container={true}>
                                                    <Grid item xs={10} md={10} lg={10}>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Grid container>
                                                                <Button size={"medium"} onClick={() => console.log("clicked")}
                                                                        startIcon={<Home/>}>{place?.place?.address_name}</Button>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Grid container color={grey[400]}>
                                                                <Grid item xs={4} md={3} lg={3}>
                                                                    <Grid container>
                                                                        <BootstrapTooltip title={this.props.LanguageStore.language.TYPE}>
                                                                            <Button size={"small"}
                                                                                    onClick={() => console.log("clicked")}
                                                                                    startIcon={
                                                                                        <LocationCity/>}>{place?.place?.address_type?.type_name}</Button>
                                                                        </BootstrapTooltip>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={9} lg={9}>
                                                                    <Grid container>
                                                                        <BootstrapTooltip title={this.props.LanguageStore.language.ADDRESS}>
                                                                            <Button size={"small"}
                                                                                    onClick={() => console.log("clicked")}
                                                                                    startIcon={
                                                                                        <Place/>}>{place?.place?.address_text},{place?.place?.neighborhood?.name},{place?.place?.district?.name},{place?.place?.town?.name}</Button>
                                                                        </BootstrapTooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2} md={2} lg={2}>
                                                        <Button
                                                            fullWidth={true}
                                                            color="secondary"
                                                            size="small"
                                                            className={classes.funcButton}
                                                            onClick={() => this.setState({deleteModal:true, placeId: place.id})}
                                                            startIcon={<Delete/>}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                )))}
                            </List>
                        </Paper>
                    </Grid>
                    </Grid>
                {/*ADD NEAR PLACES DIALOG*/}
                <Dialog fullWidth={true} open={this.state.addModal}
                        onClose={() => this.setState({
                            addModal: false,
                            addressId: null,
                        })} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.NEAR_PLACES}</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControl variant="outlined" className={classes.margin} style={{"width": "97%"}}>
                                    <InputLabel id="demo-simple-select-outlined-label">Address Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.addressType}
                                        onChange={(e)=>this.handleTypeChange(e.target.value)}
                                        label="Type"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {this.props.DataStore.addressTypes.map((type, i)=>(
                                            (type.id!==2) &&(<MenuItem key={i} value={type.id}>{type.type_name}</MenuItem>)
                                        ))}
                                    </Select>
                                </FormControl>
                                {(this.props.AddressStore.addresses?.length > 0) && (<>
                                        <FormControl className={classes.margin} style={{"width": "97%"}} variant="outlined">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth={true}
                                                options={this.props.AddressStore.addresses}
                                                getOptionLabel={(option) => option.address_name}
                                                loading={this.props.AddressStore.loading}
                                                getOptionSelected={(option, value) => value.id === option.id}
                                                onChange={(event, newValue) => {
                                                    console.log("new value", newValue);
                                                    if (newValue?.inputValue && typeof newValue.inputValue === 'string') {
                                                        console.log("add now", newValue);
                                                        this.setState({suggestThis: newValue});
                                                        this.suggestTicket(newValue);
                                                    } else if (newValue?.id) {
                                                        this.setState({addressId: newValue.id});
                                                    } else if (newValue === null) {
                                                        this.setState({addressId: null});
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== "") {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            address_name: `Ekleme isteği bildir "${params.inputValue}"`
                                                        });
                                                    }
                                                    return filtered;
                                                }}
                                                freeSolo
                                                clearOnBlur
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={this.props.LanguageStore.language.ADDRESS}
                                                                                    variant="outlined"/>}
                                            />
                                        </FormControl>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                addModal: false,
                                addressId:null
                            })
                        }} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button
                            disabled={!this.state.addressId}
                            onClick={async () => {
                                await this.handleAddModalConfirm()
                            }} color="primary">
                            {this.props.LanguageStore.language.SAVE}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*DELETE NEAR PLACE DIALOG*/}
                <Dialog fullWidth={true} open={this.state.deleteModal}
                        onClose={() => this.setState({
                            deleteModal: false,
                            placeId: null,
                        })} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.AYS}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                deleteModal: false,
                                placeId: null,
                            })
                        }} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button
                            disabled={!(this.state.placeId && !this.props.AccountStore.loading)}
                            onClick={async () => {
                                await this.handleDelete()
                            }} color="secondary">
                            {this.props.LanguageStore.language.CONFIRM}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>);
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    fixedHeight: {
        height: 576,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
    fullWidth: {
        width: "100%"
    },
    funcButton:{
        margin: 1
    },
    margin: {
        margin: theme.spacing(1),
    },
});
export default  withStyles(useStyles, {withTheme: true})(NearPlaces);
