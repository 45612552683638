import React, {Component} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {Button, Grid, Link} from "@material-ui/core";

@inject('CDNStore','LanguageStore')
@observer
class Upload extends Component{

    state={
        imageToUpload:null,
        fileType:""
    }

    async onFileChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            console.log(file);
            if (file.type==="application/pdf"){
                this.setState({fileType:"application/pdf"});
                //console.log("Upload pdf");
                this.props.CDNStore.uploadFile(file, "application/pdf").then(r => {
                    console.log(this.props.CDNStore.imageUploaded);
                    this.setState({fileType:""});
                });
            }else {
                this.setState({fileType:"image/*"});
                this.props.CDNStore.uploadFile(file, "image/*").then(r => {
                    console.log(this.props.CDNStore.imageUploaded);
                    this.setState({fileType:""});
                });
            }
        }
    }
    render() {
        const {classes}=this.props;
        return(<>
            <Backdrop className={classes.backdrop} open={this.props.CDNStore.loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Button component="label" disabled={this.props.CDNStore.imageUploaded} variant={'contained'} color={"primary"} fullWidth>
                                    {this.props.LanguageStore.language.UPLOAD_FILE}
                                    <input type="file" hidden onChange={(event) => this.onFileChange(event)} accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Button disabled={!this.props.CDNStore.imageUploaded} onClick={()=>this.props.CDNStore.resetImageUploaded()}  variant={'contained'} color={"secondary"} fullWidth>
                                    {this.props.LanguageStore.language.REMOVE}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {(this.props.CDNStore.imageUploaded && this.state.fileType!=="application/pdf") && (<img src={this.props.CDNStore.imageUploaded} style={{height:"360px"}} />)}
                    {(this.props.CDNStore.imageUploaded && this.state.fileType==="application/pdf") && (<Link href={this.props.CDNStore.imageUploaded} target={"_blank"}>{this.props.LanguageStore.language.FILE}</Link>)}
                </Grid>

            </Grid>
        </>);
    }
}

function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    grid:{
        marginTop:10
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    imgContainer: {
        padding: 16,
        width: "100%",
        height: "100%",
    },
    img: {
        marginLeft: "auto",
        marginRight: "auto",
    },
});
export default withStyles(useStyles, {withTheme: true})(Upload);
