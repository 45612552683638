import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage/lib";
import {API_BASE} from '../constants';

class SettingsStore {
    @observable qr = null;
    @observable loading = false;



    @action async addQr(qr){
        try {
            this.loading=true;
            await  AsyncStorage.setItem('qr',qr);
            this.qr = qr;
            this.loading=false;
        }catch(e){
            console.log(e)
            this.loading=false;
        }
    }
    @action async getQr(){
        try {
            this.loading=true;
            const qr = await AsyncStorage.getItem('qr');
            if (!qr){
                await this.removeQr();
            }
            this.qr = qr;
            this.loading=false;
        }catch(e){
            console.log(e)
            this.loading=false;
        }
    }
    @action async removeQr(){
        try {
            this.loading=true;
            await  AsyncStorage.removeItem('qr');
            this.qr = null;
            this.loading=false;
        }catch(e){
            console.log(e)
            this.loading=false;
        }
    }
}
export default new SettingsStore();
