import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {ArrowRight, ArrowRightAlt} from "@material-ui/icons";
import CollapsableRow from "../../components/admin/CollapsableRow";
import {toJS} from "mobx";
import {Button, Grid, TextField} from "@material-ui/core";

@inject("AdminStore","AuthStore")
@observer
class AllOrders extends Component {
    state = {
        start: "2021-01-01 00:00:00",
        end: "2021-01-01 23:59:59",
        data: [],
        rowsExpanded:[]
    }

    async componentDidMount() {
        this.setState({
            start: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            end: moment().add(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        });

        if (this.props.AuthStore.user_role !== "SUPER_ADMIN") {
            this.props.history.push({pathname: `/`})
        }

        await this.props.AdminStore.getAllOrders(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'), moment().add(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'));
        this.setState({data: toJS(this.props.AdminStore.orders)});
    }

    async fetchOrders() {
        await this.props.AdminStore.getAllOrders(this.state.start, this.state.end);
        this.setState({data: this.props.AdminStore.orders});
    }


    render() {
        const {classes} = this.props;
        const columns = [
            {
                name: "id",
                label: "#",
                options: {
                    filter: true,
                    filterType:"textField"
                }
            }, {
                name: "customer.customer_name",
                label: "Costumer",
                options: {
                    filter: true,
                    filterType:"textField"
                }
            }, {
                name: "account.account_title",
                label: "Account",
                options: {
                    filter: true,
                    filterType:"textField"
                }
            }, {
                name: "ordertype.type_name",
                label: "Order Type"
            }, {
                name: "status.statu_name",
                label: "Status",
            }, {
                name: "order_price",
                label: "Total(₺)",
                options: {
                    customBodyRender:(value, tableMeta, updateValue) => {
                        return parseFloat(value).toFixed(2)
                    }
                }
            }, {
                name: "created_at",
                label: "Created",
                options: {
                    customBodyRender:(value, tableMeta, updateValue) => {
                        if (value){
                            return moment(parseInt(value)).fromNow();
                        }else {
                            return "";
                        }
                    }
                }
            }, {
                name: "order_receive_time",
                label: "Received",
                options: {
                    customBodyRender:(value, tableMeta, updateValue) => {
                        if (value){
                            return moment(parseInt(value)).fromNow();
                        }else {
                            return "";
                        }
                    }
                }
            }, "Action"];
        const options = {
            selectable: false,
            pagination: false,
            expandableRows:true,
            expandableRowsHeader: true,
            expandableRowsOnClick: true,
            rowsExpanded: this.state.rowsExpanded,
            renderExpandableRow:(rowData, rowMeta)=>{
                const order=this.state.data.find(or=>or.id===rowData[0]);
                return(<CollapsableRow rowData={rowData} order={order} rowMeta={rowMeta}/>);
            },
            onTableChange: (action, tableState) => {
                switch (action) {
                    case "rowExpansionChange":
                        //console.log(action);
                        //console.dir(tableState);
                        let rowsExpanded = tableState.expandedRows.data.map(
                            item => item.index
                        );
                        if (rowsExpanded.length > 1) {
                            // limiting would go here
                            rowsExpanded = rowsExpanded.slice(-1);
                        }
                        //console.dir(rowsExpanded);

                        this.setState(prevState => ({
                            rowsExpanded: rowsExpanded
                        }));

                        break;
                }
            },
            filter: true,
            filterType: "dropdown",
            enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
        };
        return (<>
                <Grid container spacing={2} style={{margin:10}}>
                    <Grid item>
                        <TextField id="start" value={this.state.start} type={"datetime-local"} onChange={(e)=>this.setState({start:e.target.value})} variant="outlined" size={"small"} />
                    </Grid>
                    <Grid item>
                        <ArrowRightAlt style={{marginTop:"38%"}} />
                    </Grid>
                    <Grid item>
                        <TextField id="end" value={this.state.end} type={"datetime-local"} onChange={(e)=>this.setState({end:e.target.value})} variant="outlined" size={"small"} />
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} disabled={this.props.AdminStore.loading} color={"primary"} onClick={()=>this.fetchOrders()} disableElevation>Fetch</Button>
                    </Grid>
                </Grid>
            <MUIDataTable
                title={"All Orders"}
                data={this.state.data}
                columns={columns}
                options={options}
            />
            </>
        );
    }
}

const useStyles = ({
    table: {
        minWidth: 650,
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(AllOrders));
