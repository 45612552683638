import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {Add} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CopyMenu from "./CopyMenu";

@inject("AddProductStore", 'LayoutStore','LanguageStore')
@observer
class AddProductMenu extends Component{
    state={
        open:false,
        menuName:"",
        type:"",
        price:0,
        copyMenuDialog:false
    }
    handleClickOpen(){
        this.setState({open:true});
    }
    handleClose(){
        this.setState({open:false, menuName:"", price:0});
    }
    handleConfirm(){
        console.log(this.props.productId, this.state);
        this.props.AddProductStore.addProductMenu(this.props.productId,this.state.menuName,this.state.price,this.state.type);
        this.handleClose();
    }
    handleCopy(){
        this.props.LayoutStore.toggleCopyDialog();
    }

    render() {
        const {classes}=this.props;
        const {open, menuName, type}=this.state;
        return(<>
            <Paper className={classes.addPMenuPaper} elevation={0}>
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                <Button startIcon={<Add/>} onClick={()=>(this.handleClickOpen())}>{this.props.LanguageStore.language.ADD_PRODUCT_MENU}</Button>
                <Button color={'secondary'} onClick={()=>(this.handleCopy())}>{this.props.LanguageStore.language.COPY_MENU}</Button>
                </ButtonGroup>
            </Paper>
            {/*Add Product Menu Dialog/Model*/}
            <Dialog open={open} onClose={()=>this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_PRODUCT_MENU}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.ADD_PRODUCT_MENU_DESC}
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup aria-label="type" name="type" value={type} onChange={(e)=>{this.setState({type: e.target.value})}}>
                            <FormControlLabel value="1" control={<Radio />} label={this.props.LanguageStore.language.SINGULAR} />
                            <FormControlLabel value="2" control={<Radio />} label={this.props.LanguageStore.language.MULTIPLE} />
                            <FormControlLabel value="3" control={<Radio />} label={this.props.LanguageStore.language.MENU} />
                            <FormControlLabel value="4" control={<Radio />} label={this.props.LanguageStore.language.REMOVABLE} />
                            <FormControlLabel value="5" control={<Radio />} label={"Seçenek Seçimi"} />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        autoFocus
                        variant={"outlined"}
                        margin="dense"
                        id="menu-name"
                        defaultValue={menuName}
                        onChange={e => this.setState({menuName: e.target.value})}
                        label={this.props.LanguageStore.language.NAME}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {this.handleClose()}} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button disabled={!this.state.type || !this.state.menuName} onClick={()=> {this.handleConfirm()}} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Copy menu details from another product*/}
            <CopyMenu open={this.state.copyMenuDialog} data={this.props} />
        </>);
    }
}
const useStyles={
    addPMenuPaper: {
        width: "100%"
    }
};
export default  withStyles(useStyles, { withTheme: true })(AddProductMenu);
