import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import SurveyList from "../../components/surveys/SurveyList";

import Survey from "../../components/surveys/Survey";

class Surveys extends Component{
    render() {
        const {classes}=this.props;
        return(<div className={classes.root}>
            {(this.props.match.params && !this.props.match.params.id) && <SurveyList />}
            {/*{(this.props.match.params && this.props.match.params.edit==="edit") && <EditProduct />}*/}
            {(this.props.match.params && this.props.match.params.id && !isNaN(Number(this.props.match.params.id))) && <Survey surveyId={this.props.match.params.id} />}
        </div>);
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
});
export default withStyles(useStyles, {withTheme: true})(Surveys);
