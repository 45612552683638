import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Toolbar from "./Toolbar";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Notifier from "./Notifier";
import LanguageSelector from "./LanguageSelector";
import TicketDialog from "./TicketDialog";

@inject('AuthStore', 'TicketStore')
@observer
class Layout extends Component{
    componentDidMount() {
        this.props.AuthStore.all_props = this.props;
        this.props.AuthStore.getToken();
    }

    render(){
        const {classes,navbar, toolbar, children} = this.props;
        return (
        <div className={classes.root}>
            <Notifier/>
            {navbar && (<Navbar/>)}
            <LanguageSelector/>
            {toolbar && (<Toolbar/>)}
            <main className={classes.content}>
                {toolbar && (<div className={classes.toolbar}/>)}
                {children}
            </main>
            {/*Create ticket dialog*/}
            <TicketDialog/>
        </div>
    );
    }
}


const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});
export default withRouter(withStyles(useStyles, { withTheme: true })(Layout));
