import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage/lib";
import axios from "axios";
import {API_BASE} from "../constants";
import {
    GET_ACCOUNTS,
    GET_ADDRESS_TYPES, GET_BRANDS,
    GET_CITIES,
    GET_CITY,
    GET_DISTRICT,
    GET_DOCUMENT_TYPES, GET_EXTERNAL_SOURCES,
    GET_STATUSES
} from "../graphql/Queries";
import LayoutStore from "./LayoutStore";

class DataStore {
    @observable loading = false;
    @observable cities = [];
    @observable statuses = [];
    @observable addressTypes = [];
    @observable accounts = [];
    @observable brands = [];
    @observable documentTypes = [];
    @observable externalSources = [];
    @observable city = null;
    @observable district = null;

    @action
    async getCities() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_CITIES}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: DataStore at getCities!");
                return false;
            }
            this.cities = data.data.cities;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: DataStore at getCities!");
        }
    }

    @action
    async getCity(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_CITY(parseInt(id))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: DataStore at getCity!");
                return false;
            }
            this.city = data.data.city;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: DataStore at getCity!");
        }
    }

    @action
    async getDistrict(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_DISTRICT(parseInt(id))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: DataStore at getDistrict!");
                return false;
            }
            this.district = data.data.district;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: DataStore at getDistrict!");
        }
    }

    @action
    async getStatuses() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_STATUSES}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: DataStore at getStatuses!");
                return false;
            }
            this.statuses = data.data.statuses.sort(function (a, b) {
                return a.id - b.id;
            });
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: DataStore at getStatuses!");
        }
    }

    @action
    async getAddressTypes() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ADDRESS_TYPES}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: DataStore at getAddressTypes!");
                return false;
            }
            this.addressTypes = data.data.address_types;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: DataStore at getAddressTypes!");
        }
    }

    @action
    async getDocumentTypes() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_DOCUMENT_TYPES}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: DataStore at getDocumentTypes!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.documentTypes = data.data.document_types;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: DataStore at getDocumentTypes!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async getAccounts() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ACCOUNTS}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: DataStore at getAccounts!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.accounts = data.data.accounts;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: DataStore at getAccounts!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async getBrands() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_BRANDS}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: DataStore at getBrands!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.brands = data.data.brands;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: DataStore at getBrands!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async getExternalSources() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_EXTERNAL_SOURCES}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: DataStore at getExternalSources!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.externalSources = data.data.external_sources;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: DataStore at getExternalSources!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

}

export default new DataStore();
