import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import NavCards from "../../components/Welcome/NavCards";
import {
    EventSeat,
    GroupWork,
    LocalOffer,
    MenuBook
} from "@material-ui/icons";
import grey from "@material-ui/core/colors/grey";
import {orange, pink, teal, red} from "@material-ui/core/colors";
import {inject, observer} from "mobx-react";

@inject('LanguageStore')
@observer
class NewDefinitions extends Component{
    render() {
        const {classes}=this.props;
        return(<Grid container direction="row"
                     justify="center"
                     alignItems="center" spacing={3}>
            <Grid item className={classes.CardsContainer} xs={12} md={8} lg={8}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={3} md={3} lg={3}>
                        <NavCards onClick={()=> {this.props.history.push({pathname: '/definitions/menu-management'})}} info={["CRUD Product","CRUD Menu","CRUD Menu details"]} name={this.props.LanguageStore.language.MENU_MANAGEMENT} color={red[600]} secondaryColor={red[400]} icon={<MenuBook style={{color:grey[50], fontSize:60}}/>} />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <NavCards onClick={()=> {this.props.history.push({pathname: '/definitions/product-group-management'})}} info={["CRUD Groups", "Sort/Order Groups"]} name={this.props.LanguageStore.language.PRODUCT_GROUP} color={orange[600]} secondaryColor={orange[400]} icon={<GroupWork style={{color:grey[50], fontSize:60}}/>} />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <NavCards onClick={()=> {this.props.history.push({pathname: '/definitions/price-group-management'})}} info={["CRUD Price Group"]} name={this.props.LanguageStore.language.PRODUCT_PRICE_GROUPS} color={pink[600]} secondaryColor={pink[400]} icon={<LocalOffer style={{color:grey[50], fontSize:60}}/>} />
                    </Grid>
                    <Grid item xs={3} md={3} lg={3} hidden>
                        <NavCards onClick={()=> {this.props.history.push({pathname: '/definitions/room-management'})}} info={["CRUD Room","CURD Saloon"]} name={this.props.LanguageStore.language.ROOM_AND_TABLE} color={teal[600]} secondaryColor={teal[400]} icon={<EventSeat style={{color:grey[50], fontSize:60}}/>} />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>);
    }
}

const useStyles = theme => ({
    CardsContainer:{
        marginTop:100
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(NewDefinitions));
