import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {withRouter} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LayersIcon from "@material-ui/icons/Layers";
import ListItemText from "@material-ui/core/ListItemText";
import {FormatListBulleted, Help, Home, MenuBook, QuestionAnswer} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Styles from "../../styles/Styles";
import {inject, observer} from "mobx-react";


@inject('LayoutStore', 'AuthStore','LanguageStore','TicketStore')
@observer
class Toolbar extends Component {

    activeMenu(link) {
        if (this.props.match.path === link) {
            return true;
        }
    }

    handleLogout() {
        console.log("logout");
        this.props.AuthStore.removeToken();
    }

    render() {
        const {classes} = this.props;
        //console.log(this.props);
        //console.log("drawer", this.props.LayoutStore.drawer);
        return (<Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, this.props.LayoutStore.drawer && classes.drawerPaperClose),
            }}
            display={'none'}
            style={{height:"100vh"}}
            open={this.props.LayoutStore.drawer}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={() => {
                    this.props.LayoutStore.toggleDrawer()
                }}>
                    <ChevronLeftIcon/>
                </IconButton>
            </div>
            <Divider/>
            <List>
                <ListItem selected={this.activeMenu("/")} button onClick={() => {
                    this.props.history.push({pathname: '/'})
                }}>
                    <ListItemIcon>
                        <Home/>
                    </ListItemIcon>
                    <ListItemText primary={"Anasayfa"}/>
                </ListItem>
                <ListItem button selected={this.activeMenu("/registration")} onClick={() => {
                    this.props.history.push({pathname: '/registration'})
                }}>
                    <ListItemIcon>
                        <LayersIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Yeni Tanımlama"}/>
                </ListItem>
                <ListItem selected={this.activeMenu("/admin")}
                          button onClick={() => {
                    this.props.history.push({pathname: '/admin'})
                }}>
                    <ListItemIcon>
                        <FormatListBulleted/>
                    </ListItemIcon>
                    <ListItemText primary={"Günlük Sipariş Takibi"}/>
                </ListItem>
                <ListItem selected={false}
                          button onClick={() => {
                    console.log("create ticket");
                    this.props.TicketStore.toggleDialog();
                }}>
                    <ListItemIcon>
                        <Help/>
                    </ListItemIcon>
                    <ListItemText primary={this.props.LanguageStore.language.HELP}/>
                </ListItem>
                {/*<Divider/>
                <ListItem onClick={() => {
                    this.handleLogout()
                }} button>
                    <ListItemIcon>
                        <ExitToApp/>
                    </ListItemIcon>
                    <ListItemText primary="Logout"/>
                </ListItem>*/}
            </List>
        </Drawer>);
    }
}

const useStyles = theme => (Styles(theme));

export default withRouter(withStyles(useStyles, {withTheme: true})(Toolbar));
