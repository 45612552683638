import React, {Component} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import {
    AddCircle,
    DirectionsBike,
    DirectionsWalk,
    Edit,
    EventSeat,
    ExpandLess,
    ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {inject, observer} from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

@inject('AddProductStore', 'PriceGroupStore','ProductGroupStore', 'LanguageStore','AccountStore')
@observer
class ProductDetailList extends Component {

    state = {
        openP: true,
        openD: true,
        editDButton: false,
        editPButton: false,
        price: 0,
        priceGroups: [],
        takeAway: 0,
        eatIn: 0,
        group: "",
        description: "",
        openPModal: {state: false, type: "", price: null, value: 0, takeaway: 0, eatin: 0, group: "0"},
        openDModal: {state: false, type: "", detail: null, description: ""}
    };

    handleClick() {
        this.setState({openP: !this.state.openP});
    }

    handlePriceModal(edit, id, value, takeAway, eatIn, group) {
        this.setState({price: value, takeAway: takeAway, eatIn: eatIn, group: group});
        (edit) ? (
            this.setState({
                openPModal: {
                    state: true,
                    type: "edit",
                    price: id,
                    value: value,
                    takeaway: takeAway,
                    eatin: eatIn,
                    group: ""
                }
            })
        ) : (
            this.setState({
                openPModal: {
                    state: true,
                    type: "add",
                    price: null,
                    value: value,
                    takeaway: takeAway,
                    eatin: eatIn,
                    group: ""
                }
            })
        );
    }

    handleDescModal(edit, detail, desc) {
        this.setState({description: desc});
        (edit) ? (this.setState({openDModal: {state: true, type: "edit", detail: detail, description: desc}})
        ) : (this.setState({openDModal: {state: true, type: "add", detail: null, description: desc}})
        )
    }

    handleCloseDescModal() {
        this.setState({openDModal: {state: false, type: "", detail: null, description: ""}});
    }

    handleClosePriceModal() {
        this.setState({openPModal: {state: false, type: "", price: null, value: 0, takeaway: 0, eatin: 0, group: ""}});
    }

    handleDescModalConfirm(id) {
        console.log(id, this.state.openDModal);
        if (this.state.openDModal.type === "edit") {
            this.props.AddProductStore.editDescription(this.state.openDModal.detail, id, 2, this.state.description).then(r => this.props.AddProductStore.getProduct(id));
        } else if (this.state.openDModal.type === "add") {
            this.props.AddProductStore.addDescription(id, 2, this.state.description).then(r => this.props.AddProductStore.getProduct(id));
        }
        this.setState({openDModal: {state: false, type: "", detail: null, description: ""}});
    }
    isCenter(){
        return  this.props.AccountStore.account.id==this.props.AccountStore.account.account_center
    }
    async handlePriceModalConfirm(id) {
        //console.log(id, this.state.openPModal);
        if (this.state.openPModal.type === "edit") {
            //edit
            console.log(id, this.state.openPModal.price, this.state.price, this.state.takeAway, this.state.eatIn);
            await this.props.AddProductStore.editPrice(parseInt(this.state.openPModal.price), id, Number(this.state.price), Number(this.state.takeAway), Number(this.state.eatIn)).then(r => this.handleClosePriceModal());
            await this.props.ProductGroupStore.getProductGroups(-1);
        } else if (this.state.openPModal.type === "add") {
            //add
            console.log(id, Number(this.state.price), Number(this.state.takeAway), Number(this.state.eatIn), this.state.group);
            await this.props.AddProductStore.addPrice(id, Number(this.state.price), Number(this.state.takeAway), Number(this.state.eatIn), this.state.group).then(r => this.handleClosePriceModal());
            await this.props.ProductGroupStore.getProductGroups(-1);
        }
    }

    handleDeleteDescModal(id) {
        console.log(this.state.openDModal.detail, 'delete it');
        this.props.AddProductStore.deleteProductDetail(this.state.openDModal.detail, id);
        this.setState({openDModal: {state: false, type: "", detail: null, description: ""}});
    }

    render() {
        const {classes, data} = this.props;
        const {openP, openD, editDButton, description, openPModal, openDModal} = this.state;
        return (<>
            <List component="nav">
                <ListItem>
                    <ListItemText secondary={`${this.props.LanguageStore.language.NAME}:`}/>
                    {data.product.account_product_name ? (
                        <ListItemText primary={`${data.product.account_product_name?.except_p_data} (${data.product.product_name})`}/>
                    ):(
                        <ListItemText primary={`${data.product.product_name}`}/>
                    )}
                </ListItem>
                <ListItem>
                    <ListItemText secondary={`${this.props.LanguageStore.language.MENU}?:`}/>
                    <ListItemText primary={`${data.product.product_is_menu}`}/>
                </ListItem>
                <ListItem>
                    <ListItemText secondary={`${this.props.LanguageStore.language.PRODUCT_NUMBER}:`}/>
                    <ListItemText primary={`${data.product.product_number}`}/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.handleClick()
                }}>
                    <ListItemText primary={`${this.props.LanguageStore.language.PRICES}`}/>
                    {data.product.product_price && <Button size={"small"} aria-labelledby={"edit price"}
                                                           onClick={() => (this.handlePriceModal(true, data.product.product_price.id, data.product.product_price.price_value, data.product.product_price.price_takeaway, data.product.product_price.price_eatin, ""))}>{this.props.LanguageStore.language.EDIT}</Button>}
                    {openP ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                {(data.product.product_price) ? (
                    <Collapse in={openP} timeout="auto" onMouseEnter={() => (this.setState({editPButton: true}))}
                              onMouseLeave={() => (this.setState({editPButton: false}))} unmountOnExit>
                        <List component="div" disablePadding>
                            {this.props.AccountStore.account.account_delivery && (
                                <ListItem>
                                <ListItemIcon>
                                    <DirectionsBike/>
                                </ListItemIcon>
                                <ListItemText secondary={`${this.props.LanguageStore.language.PARCEL}:`} className={classes.listSecondaryText}/>
                                <ListItemText
                                    primary={`${(data.product.product_price.price_value).toFixed(2)}`} className={classes.listPrimaryText}/>
                            </ListItem>)}
                            {this.props.AccountStore.account.account_takeaway && (
                                <ListItem>
                                    <ListItemIcon>
                                        <DirectionsWalk/>
                                    </ListItemIcon>
                                    <ListItemText secondary={`${this.props.LanguageStore.language.TAKEAWAY}:`} className={classes.listSecondaryText}/>
                                    <ListItemText primary={`${(data.product.product_price.price_takeaway).toFixed(2)}`} className={classes.listPrimaryText}/>
                                </ListItem>
                            )}
                            {this.props.AccountStore.account.account_eatin && (
                                <ListItem>
                                <ListItemIcon>
                                    <EventSeat/>
                                </ListItemIcon>
                                <ListItemText secondary={`${this.props.LanguageStore.language.EATIN}:`} className={classes.listSecondaryText}/>
                                <ListItemText primary={`${(data.product.product_price.price_eatin).toFixed(2)}`} className={classes.listPrimaryText}/>
                            </ListItem>)}
                        </List>
                    </Collapse>
                ) : (
                    <List component="div" disablePadding>
                        <ListItem>
                            <Button variant={'outlined'} startIcon={<AddCircle/>}
                                    onClick={() => {
                                        this.props.PriceGroupStore.getPriceGroups().then(r => {
                                            this.setState({priceGroups: this.props.PriceGroupStore.priceGroups});
                                            this.handlePriceModal(false, null, 0, 0, 0, this.props.PriceGroupStore.priceGroups[0].id);
                                        });
                                    }}
                                    className={classes.addButton}>{this.props.LanguageStore.language.ADD_PRICE}</Button>
                        </ListItem>
                    </List>
                )}
                <ListItem button onClick={() => (this.setState({openD: !openD}))}>
                    <ListItemText secondary={`${this.props.LanguageStore.language.PRODUCT} ${this.props.LanguageStore.language.DESCRIPTION}:`}/>
                    {openD ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                {(data.product.product_detail) ? (
                    <List>
                        <Collapse in={openD} timeout={"auto"} unmountOnExit>
                            <Typography variant={"body1"} onMouseEnter={() => (this.setState({editDButton: true}))}
                                        onMouseLeave={() => (this.setState({editDButton: false}))}>
                                {`${(data.product.product_detail) ? (data.product.product_detail.detail_text) : (null)}`}

                                {editDButton && <IconButton visibility={"hidden"} aria-labelledby={"edit description"}
                                                            onClick={() => (this.handleDescModal(true, data.product.product_detail.id, data.product.product_detail.detail_text))}>
                                    <Edit/>
                                </IconButton>}
                            </Typography>
                        </Collapse>
                    </List>
                ) : (
                    <List component="div" disablePadding>
                        <ListItem>
                    <Button variant={'outlined'} startIcon={<AddCircle/>}
                            className={classes.addButton} onClick={() => (this.handleDescModal(false, null, ""))}>{this.props.LanguageStore.language.ADD_DESCRIPTION}</Button>
                        </ListItem></List>)}
            </List>

            {/*Price add/change dialog/modal*/}
            <Dialog open={openPModal.state} onClose={()=>this.handleClosePriceModal()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{openPModal.type === "add" ? `${this.props.LanguageStore.language.ADD}` : `${this.props.LanguageStore.language.EDIT}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.ADD_PRICE_DESC}
                    </DialogContentText>
                    {openPModal.type === "add" ? (<FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.PRICE_GROUP}</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.group}
                            onChange={(e) => {
                                this.setState({group: e.target.value})
                            }}
                            label={this.props.LanguageStore.language.PRICE_GROUP}
                        >
                            {this.state.priceGroups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>{group.price_group_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>) : null}
                    {(this.props.AccountStore.account.account_delivery || this.isCenter()) && (
                        <TextField
                        autoFocus
                        margin="dense"
                        id="price"
                        label={this.props.LanguageStore.language.DELIVERY}
                        type="number"
                        variant={'outlined'}
                        value={(this.state.price != null) ? (this.state.price) : (0)}
                        onFocus={()=>(this.state.price === null)?this.setState({price: ""}):this.state.price}
                        onChange={e => this.setState({price: e.target.value})}
                        fullWidth
                    />)}
                    {(this.props.AccountStore.account.account_takeaway || this.isCenter()) && (
                        <TextField
                        margin="dense"
                        id="takeAwayPrice"
                        label={this.props.LanguageStore.language.TAKEAWAY}
                        type="number"
                        variant={'outlined'}
                        value={(this.state.takeAway != null) ? (this.state.takeAway) : (0)}
                        onFocus={()=>(this.state.takeAway === null)?this.setState({takeAway: ""}):this.state.takeAway}
                        onChange={e => this.setState({takeAway: e.target.value})}
                        fullWidth
                    />)}
                    {(this.props.AccountStore.account.account_eatin || this.isCenter()) && (
                        <TextField
                        margin="dense"
                        id="eatinPrice"
                        label={this.props.LanguageStore.language.EATIN}
                        type="number"
                        variant={'outlined'}
                        value={(this.state.eatIn != null) ? (this.state.eatIn) : (0)}
                        onFocus={()=>(this.state.eatIn === null)?this.setState({eatIn: ""}):this.state.eatIn}
                        onChange={e => this.setState({eatIn: e.target.value})}
                        fullWidth
                    />)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleClosePriceModal()
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button onClick={() => {
                        this.handlePriceModalConfirm(data.product.id)
                    }} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Description add/change dialog/modal*/}
            <Dialog open={openDModal.state} aria-labelledby="description-form-dialog-title">
                <DialogTitle
                    id="description-form-dialog-title">{openDModal.type === "add" ? this.props.LanguageStore.language.ADD : this.props.LanguageStore.language.EDIT}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.ADD_DESCRIPTION_DESC}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label={this.props.LanguageStore.language.DESCRIPTION}
                        multiline
                        inputProps={{maxLength: 500}}
                        helperText={`${description.length}/500`}
                        rows={4}
                        defaultValue={description}
                        onChange={e => this.setState({description: e.target.value})}
                        variant={'outlined'}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    {this.state.openDModal.type === "edit" && <Button onClick={() => {
                        this.handleDeleteDescModal(data.product.id)
                    }} color="secondary">
                        {this.props.LanguageStore.language.DELETE}
                    </Button>}
                    <Button onClick={() => {
                        this.handleCloseDescModal()
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button disabled={!this.state.description}
                            onClick={() => (this.handleDescModalConfirm(data.product.id))} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    }
}

const useStyles = theme => ({
    addButton: {
        width: '100%',
    },
    listPrimaryText:{
      width: "50%"
    },
    listSecondaryText:{
      width: "50%"
    },
});

export default withStyles(useStyles, {withTheme: true})(ProductDetailList);
