import React, {Component} from "react";
import {
    Card,
    CardContent, CircularProgress,
    Divider, FilledInput,
    FormControl,
    Grid, Input,
    InputAdornment, InputLabel,
    List,
    ListItem,
    OutlinedInput,
    Tooltip
} from "@material-ui/core";
import {Add, AttachMoney, Delete, Edit, Home, LocalOffer, Timer} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {inject} from "mobx-react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {toJS} from "mobx";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

@inject('AccountStore', 'LanguageStore', 'TicketStore', 'DataStore')
class DeliveryPoints extends Component {

    state = {
        value: 0,
        pointId: null,
        suggestThis: "",
        pointEditModal: false,
        pointDeleteModal: false,
        pointAddModal: false,
        pointAddType: "",
        pointFee: 0,
        rowId:null,
        cityId:null,
        townId:null,
        townOptions:[],
        neighborhoodOptions:[],
        districtId:null,
        districtOptions:[],
        secondRowId:null,
        pointMinPay: 0,
        pointTime: 0
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };
    handleAddPointButton = async (type) => {
        await this.props.DataStore.getCities();
        this.setState({pointAddModal: true, pointAddType: type, pointFee: 0, pointMinPay: 0, pointTime: 0});
    }

    async handleAddModalConfirm() {
        console.log("add", this.state);
        if (this.state.pointAddType === "city") {
            await this.props.AccountStore.addDeliveryPointCity(this.state.rowId, this.state.pointFee, this.state.pointMinPay, this.state.pointTime);
        }else if (this.state.pointAddType === "neighborhood") {
            await this.props.AccountStore.addDeliveryPointNeighborhood(this.state.secondRowId, this.state.pointFee, this.state.pointMinPay, this.state.pointTime);
        }
        await this.props.AccountStore.getAccount();
        this.setState({
            pointId: null,
            suggestThis: "",
            pointEditModal: false,
            pointDeleteModal: false,
            pointAddModal: false,
            pointAddType: "",
            pointFee: 0,
            rowId:null,
            cityId:null,
            townOptions:[],
            neighborhoodOptions:[],
            districtOptions:[],
            townId:null,
            districtId:null,
            secondRowId:null,
            pointMinPay: 0,
            pointTime: 0
        });
    }

    async handleEditModalConfirm() {
        console.log("update", this.state);
        await this.props.AccountStore.updateDeliveryPoint(this.state.pointId, this.state.pointFee, this.state.pointMinPay, this.state.pointTime);
        await this.props.AccountStore.getAccount();
        this.setState({
            pointEditModal: false,
            pointId: null,
            pointFee: 0,
            pointMinPay: 0,
            pointTime: 0
        });
    }

    async handleDeleteModalConfirm() {
        console.log("delete", this.state);
        await this.props.AccountStore.deleteDeliveryPoint(this.state.pointId);
        await this.props.AccountStore.getAccount();
        this.setState({
            pointDeleteModal: false,
            pointId: null,
            pointFee: 0,
            pointMinPay: 0,
            pointTime: 0
        });
    }
    modifyData(data) {
        let districts=[];
        (data.length>0) && data.map(town =>
            town.districts.map(district =>{
                district.town=town.id;
                district.townName=town.name;
                districts.push(district);
                return null;
            })
        );
        return districts;
    }
    async setOpen(open, type) {
        if (type==="town"){
            await this.props.DataStore.getCity(this.state.rowId);
            this.setState({townOptions:this.props.DataStore.city.towns});
        }else if (type==="district"){
            await this.props.DataStore.getCity(this.state.rowId);
            this.setState({districtOptions:this.modifyData(toJS(this.props.DataStore.city.towns))});
        }if (type==="neighborhood"){
            await this.props.DataStore.getDistrict(this.state.townId);
            this.setState({neighborhoodOptions:this.props.DataStore.district.neighborhoods});
        }
    }

    suggestTicket(value) {
        console.log("please create ticket", value);
        this.props.TicketStore.suggestedTicket(`"${value.inputValue}"`, "Yeni şehir ekleme isteği", 386);
    }

    render() {
        const {classes} = this.props;
        const filter = createFilterOptions();
        //console.log(toJS(this.props.account?.delivery_point_neighborhood));
        return (<>
            <Tabs value={this.state.value} onChange={(event, value) => {
                this.handleChange(event, value)
            }} aria-label={this.props.LanguageStore.language.DELIVERY_POINTS}>
                <Tab label={this.props.LanguageStore.language.CITY} {...a11yProps(0)} />
                <Tab label={this.props.LanguageStore.language.NEIGHBORHOOD} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={this.state.value} index={1}>
                <Button startIcon={<Add/>} onClick={() => this.handleAddPointButton("neighborhood")}>{this.props.LanguageStore.language.ADD}</Button>
                <List style={{overflow: 'auto',
                    maxHeight: 400,
                }}>
                    {(this.props.account?.delivery_point_neighborhood?.map((neighborhood, index) => (
                        <ListItem key={index}>
                            <Card className={classes.fullWidth}>
                                <CardContent style={{paddingBottom: 16}}>
                                    <Grid container={true}>
                                        <Grid item xs={10} md={10} lg={10}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Grid container>
                                                    <Button size={"medium"} onClick={() => console.log("clicked")}
                                                            startIcon={<Home/>}>{neighborhood.point_row?.district?.town?.name} - {neighborhood.point_row?.district?.name} - {neighborhood.point_row.name}</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <Grid container color={grey[400]}>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <Grid container>
                                                            <BootstrapTooltip title={this.props.LanguageStore.language.EST_DELIVERY_TIME}>
                                                                <Button size={"small"}
                                                                        onClick={() => console.log("clicked")}
                                                                        startIcon={
                                                                            <Timer/>}>{neighborhood.point_time}</Button>
                                                            </BootstrapTooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <Grid container>
                                                            <BootstrapTooltip title={this.props.LanguageStore.language.MIN_PACKAGE_PRICE}>
                                                                <Button size={"small"}
                                                                        onClick={() => console.log("clicked")}
                                                                        startIcon={
                                                                            <LocalOffer/>}>{neighborhood.point_min_pay} ₺</Button>
                                                            </BootstrapTooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4} lg={4}>
                                                        <Grid container>
                                                            <BootstrapTooltip title={this.props.LanguageStore.language.DELIVERY_FEE}>
                                                                <Button size={"small"}
                                                                        onClick={() => console.log("clicked")}
                                                                        >{neighborhood.point_fee} ₺</Button>
                                                            </BootstrapTooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={2}>
                                            <Button
                                                color="primary"
                                                size="small"
                                                fullWidth={true}
                                                onClick={() => this.setState({
                                                    pointEditModal: true,
                                                    pointId: neighborhood.id,
                                                    pointFee: neighborhood?.point_fee,
                                                    pointMinPay: neighborhood?.point_min_pay,
                                                    pointTime: neighborhood?.point_time
                                                })}
                                                startIcon={<Edit/>}
                                            >
                                                {this.props.LanguageStore.language.EDIT}
                                            </Button>
                                            <Divider/>
                                            <Button
                                                fullWidth={true}
                                                color="secondary"
                                                size="small"
                                                onClick={() => this.setState({
                                                    pointDeleteModal: true,
                                                    pointId: neighborhood.id
                                                })}
                                                startIcon={<Delete/>}
                                            >
                                                {this.props.LanguageStore.language.DELETE}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </ListItem>
                    )))}
                </List>
            </TabPanel>
            <TabPanel value={this.state.value} index={0}>
                <Button startIcon={<Add/>} onClick={() => this.handleAddPointButton("city")}>{this.props.LanguageStore.language.ADD}</Button>
                <List style={{overflow: 'auto',
                    maxHeight: 400,
                }}>
                    {(this.props.account?.delivery_point_city?.map((city, index) => (
                        <ListItem key={index}>
                            <Card className={classes.fullWidth}>
                                <CardContent style={{paddingBottom: 16}}>
                                    <Grid container={true}>
                                        <Grid item xs={10} md={10} lg={10}>
                                            <Grid container>
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <Grid container>
                                                        <Button size={"medium"} onClick={() => console.log("clicked")}
                                                                startIcon={<Home/>}>{city.point_row.name}</Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12}>
                                                    <Grid container color={grey[400]}>
                                                        <Grid item xs={4} md={4} lg={4}>
                                                            <Grid container>
                                                                <BootstrapTooltip title={this.props.LanguageStore.language.EST_DELIVERY_TIME}>
                                                                    <Button size={"small"}
                                                                            onClick={() => console.log("clicked")}
                                                                            startIcon={
                                                                                <Timer/>}>{city.point_time}</Button>
                                                                </BootstrapTooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4}>
                                                            <Grid container>
                                                                <BootstrapTooltip title={this.props.LanguageStore.language.MIN_PACKAGE_PRICE}>
                                                                    <Button size={"small"}
                                                                            onClick={() => console.log("clicked")}
                                                                            startIcon={
                                                                                <LocalOffer/>}>{city.point_min_pay} ₺</Button>
                                                                </BootstrapTooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4}>
                                                            <Grid container>
                                                                <BootstrapTooltip title={this.props.LanguageStore.language.DELIVERY_FEE}>
                                                                    <Button size={"small"}
                                                                            onClick={() => console.log("clicked")}
                                                                            >{city.point_fee} ₺</Button>
                                                                </BootstrapTooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={2}>
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={() => this.setState({
                                                    pointEditModal: true,
                                                    pointId: city.id,
                                                    pointFee: city?.point_fee,
                                                    pointMinPay: city?.point_min_pay,
                                                    pointTime: city?.point_time
                                                })}
                                                fullWidth={true}
                                                startIcon={<Edit/>}
                                            >
                                                {this.props.LanguageStore.language.EDIT}
                                            </Button>
                                            <Divider/>
                                            <Button
                                                fullWidth={true}
                                                color="secondary"
                                                size="small"
                                                onClick={() => this.setState({
                                                    pointDeleteModal: true,
                                                    pointId: city.id
                                                })}
                                                startIcon={<Delete/>}
                                            >
                                                {this.props.LanguageStore.language.DELETE}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </ListItem>
                    )))}
                </List>
            </TabPanel>

            {/*ADD DELIVERY DIALOG*/}
            <Dialog fullWidth={true} open={this.state.pointAddModal} maxWidth={"md"}
                    onClose={() => this.setState({
                        pointAddModal: false,
                        pointFee: 0,
                        pointMinPay: 0,
                        pointTime: 0,
                        pointAddType: ""
                    })} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"Teslimat Noktaları Zaman ve Tutar Bilgileri"}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            {(this.props.DataStore.cities?.length > 0) && (<>
                                <FormControl className={classes.margin} style={{"width": "97%"}}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        fullWidth={true}
                                        options={this.props.DataStore.cities}
                                        getOptionLabel={(option) => option.name}
                                        loading={this.props.DataStore.loading}
                                        getOptionSelected={(option, value) => value.id === option.id}
                                        onChange={(event, newValue) => {
                                            console.log("new value", newValue);
                                            if (newValue?.inputValue && typeof newValue.inputValue === 'string') {
                                                //console.log("add now", newValue);
                                                this.setState({suggestThis: newValue});
                                                this.suggestTicket(newValue);
                                            } else if (newValue?.id) {
                                                this.setState({rowId: newValue.id});
                                            } else if (newValue === null) {
                                                this.setState({rowId: null});
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            // Suggest the creation of a new value
                                            if (params.inputValue !== "") {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    name: `Ekleme isteği bildir "${params.inputValue}"`
                                                });
                                            }
                                            return filtered;
                                        }}
                                        freeSolo
                                        clearOnBlur
                                        renderInput={(params) => <TextField {...params}
                                                                            label={this.props.LanguageStore.language.CITY}
                                                                            />}
                                    />
                                </FormControl>
                                    {(this.state.pointAddType==="neighborhood" && this.state.rowId)&&(<>
                                        <FormControl className={classes.margin} style={{"width": "97%"}}>
                                            <Autocomplete
                                                id="combo-box-district"
                                                fullWidth={true}
                                                options={this.state.districtOptions}
                                                getOptionLabel={(option) => option.name}
                                                groupBy={(option) => option.townName}
                                                onOpen={async () => {
                                                    await this.setOpen(true,"district");
                                                }}
                                                onClose={async () => {
                                                    await this.setOpen(false,"district");
                                                }}
                                                loading={this.props.DataStore.loading}
                                                getOptionSelected={(option, value) => value.id === option.id}
                                                onChange={(event, newValue) => {
                                                    console.log("new value", newValue);
                                                    if (newValue?.inputValue && typeof newValue.inputValue === 'string') {
                                                        //console.log("add now", newValue);
                                                        this.setState({suggestThis: newValue});
                                                        this.suggestTicket(newValue);
                                                    } else if (newValue?.id) {
                                                        this.setState({townId: newValue.id});
                                                    } else if (newValue === null) {
                                                        this.setState({townId: null});
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== "") {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            name: `Ekleme isteği bildir "${params.inputValue}"`
                                                        });
                                                    }
                                                    return filtered;
                                                }}
                                                freeSolo
                                                clearOnBlur
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={this.props.LanguageStore.language.DISTRICT}
                                                                                     InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {this.props.DataStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}/>}
                                            />
                                        </FormControl>
                                        <FormControl className={classes.margin} style={{"width": "97%"}} >
                                            <Autocomplete
                                                id="combo-box-neighborhood"
                                                fullWidth={true}
                                                options={this.state.neighborhoodOptions}
                                                getOptionLabel={(option) => option.name}
                                                onOpen={async () => {
                                                    await this.setOpen(true,"neighborhood");
                                                }}
                                                onClose={async () => {
                                                    await this.setOpen(false,"neighborhood");
                                                }}
                                                loading={this.props.DataStore.loading}
                                                getOptionSelected={(option, value) => value.id === option.id}
                                                onChange={(event, newValue) => {
                                                    console.log("new value", newValue);
                                                    if (newValue?.inputValue && typeof newValue.inputValue === 'string') {
                                                        //console.log("add now", newValue);
                                                        this.setState({suggestThis: newValue});
                                                        this.suggestTicket(newValue);
                                                    } else if (newValue?.id) {
                                                        this.setState({secondRowId: newValue.id});
                                                    } else if (newValue === null) {
                                                        this.setState({townId: null});
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    // Suggest the creation of a new value
                                                    if (params.inputValue !== "") {
                                                        filtered.push({
                                                            inputValue: params.inputValue,
                                                            name: `Ekleme isteği bildir "${params.inputValue}"`
                                                        });
                                                    }
                                                    return filtered;
                                                }}
                                                freeSolo
                                                clearOnBlur
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={this.props.LanguageStore.language.NEIGHBORHOOD}
                                                                                     InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {this.props.DataStore.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}/>}
                                            />
                                        </FormControl>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin} >
                                <InputLabel htmlFor="outlined-adornment-est">{this.props.LanguageStore.language.EST_DELIVERY_TIME}</InputLabel>
                                <Input
                                    id="outlined-adornment-est"
                                    value={this.state.pointTime}
                                    type={"number"}
                                    onChange={(e) => this.setState({pointTime: e.target.value})}
                                    startAdornment={<InputAdornment position="start"><Timer/></InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin} >
                                <InputLabel htmlFor="outlined-adornment-mpp">{this.props.LanguageStore.language.MIN_PACKAGE_PRICE}</InputLabel>
                                <Input
                                    id="outlined-adornment-mpp"
                                    value={this.state.pointMinPay}
                                    type={"number"}
                                    inputProps={{"step": ".10"}}
                                    onChange={(e) => this.setState({pointMinPay: e.target.value})}
                                    startAdornment={<InputAdornment position="start">{"₺"}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin} >
                                <InputLabel htmlFor="outlined-adornment-df">{this.props.LanguageStore.language.DELIVERY_FEE}</InputLabel>
                                <Input
                                    id="outlined-adornment-df"
                                    value={this.state.pointFee}
                                    type={"number"}
                                    inputProps={{"step": ".10"}}
                                    onChange={(e) => this.setState({pointFee: e.target.value})}
                                    startAdornment={<InputAdornment position="start">{"₺"}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({
                            pointAddModal: false,
                            pointFee: 0,
                            pointMinPay: 0,
                            pointTime: 0,
                            pointAddType: ""
                        })
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button
                        disabled={!((this.state.pointAddType==="neighborhood")?(this.state.rowId && this.state.pointTime &&(this.state.secondRowId)):(this.state.rowId && this.state.pointTime))}
                        onClick={() => {
                            this.handleAddModalConfirm()
                        }} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*EDIT DELIVERY DIALOG*/}
            <Dialog fullWidth={true} open={this.state.pointEditModal} maxWidth={"md"}
                    onClose={() => this.setState({
                        pointEditModal: false,
                        pointId: null,
                        pointFee: 0,
                        pointMinPay: 0,
                        pointTime: 0
                    })} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{"Teslimat Noktaları Zaman ve Tutar Bilgileri"}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="outlined-adornment-est">{this.props.LanguageStore.language.EST_DELIVERY_TIME}</InputLabel>
                                <Input
                                    id="outlined-adornment-est"
                                    value={this.state.pointTime}
                                    type={"number"}
                                    onChange={(e) => this.setState({pointTime: e.target.value})}
                                    startAdornment={<InputAdornment position="start"><Timer/></InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="outlined-adornment-mpp">{this.props.LanguageStore.language.MIN_PACKAGE_PRICE}</InputLabel>
                                <Input
                                    id="outlined-adornment-mpp"
                                    value={this.state.pointMinPay}
                                    type={"number"}
                                    inputProps={{"step": ".10"}}
                                    onChange={(e) => this.setState({pointMinPay: e.target.value})}
                                    startAdornment={<InputAdornment position="start">{"₺"}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl className={classes.margin}>
                                <InputLabel htmlFor="outlined-adornment-df">{this.props.LanguageStore.language.DELIVERY_FEE}</InputLabel>
                                <Input
                                    id="outlined-adornment-df"
                                    value={this.state.pointFee}
                                    type={"number"}
                                    inputProps={{"step": ".10"}}
                                    onChange={(e) => this.setState({pointFee: e.target.value})}
                                    startAdornment={<InputAdornment position="start">{"₺"}</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({
                            pointEditModal: false,
                            pointId: null,
                            pointFee: 0,
                            pointMinPay: 0,
                            pointTime: 0
                        })
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button
                        disabled={!(this.state.pointId && this.state.pointTime && !this.props.AccountStore.loading)}
                        onClick={() => {
                            this.handleEditModalConfirm()
                        }} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*DELETE DELIVERY DIALOG*/}
            <Dialog fullWidth={true} open={this.state.pointDeleteModal}
                    onClose={() => this.setState({
                        pointDeleteModal: false,
                        pointId: null,
                    })} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.AYS}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({
                            pointDeleteModal: false,
                            pointId: null,
                        })
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button
                        disabled={!(this.state.pointId && !this.props.AccountStore.loading)}
                        onClick={() => {
                            this.handleDeleteModalConfirm()
                        }} color="secondary">
                        {this.props.LanguageStore.language.CONFIRM}
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    }
}

const useStyles = theme => ({
    listPrimaryText: {
        width: "60%"
    },
    listSecondaryText: {
        width: "40%"
    },
    fullWidth: {
        width: "100%"
    },
    funcButtons: {
        padding: 6
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
});

export default withStyles(useStyles, {withTheme: true})(DeliveryPoints);
