import React, {Component} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import {inject, observer} from "mobx-react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {withRouter} from "react-router-dom";
import Styles from "../../../styles/Styles";
import moment from "moment";
import 'moment/locale/tr'
import {ArrowBackIos, Home} from "@material-ui/icons";

@inject('LayoutStore', 'AuthStore', 'AccountStore', 'LanguageStore')
@observer
class NewNavbar extends Component {
    componentDidMount() {
        this.props.AccountStore.getAccount();
        setInterval(()=>{
            this.setState({datetime: moment().locale((this.props.LanguageStore.language_code)?(this.props.LanguageStore.language_code):('en')).format('dddd, MMMM Do YYYY, hh:mm:ss')})
            },
            1000)
    }

    state={
        anchorEl:null,
        datetime:moment().locale(this.props.LanguageStore.language_code).format('dddd, MMMM Do YYYY, hh:mm:ss')
    }
    handleClick(event){
        this.setState({anchorEl:event.currentTarget});
    }
    handleClose(){
        this.setState({anchorEl:null});
    }
    handleLogout() {
        console.log("logout");
        this.props.AuthStore.removeToken();
    }
    render() {
        const {classes} = this.props;
        return (
            <>
                <CssBaseline/>
                <AppBar position="absolute"
                        className={clsx(classes.appBar, false)}>
                    <Toolbar className={classes.toolbar}>
                        {(this.props.location.pathname!=="/")&& (
                            <IconButton onClick={()=>this.props.history.goBack()}>
                                <ArrowBackIos style={{color:"white"}}/>
                            </IconButton>
                        )}
                        {(this.props.location.pathname==="/")&& (
                            <IconButton onClick={()=>this.props.history.push('/')}>
                                <Home style={{color:"white"}}/>
                            </IconButton>
                        )}
                        <Typography onClick={()=>this.props.history.push(`/`)} variant="h5" color="inherit" noWrap className={classes.title}>
                            Yemekarena
                        </Typography>
                        <Typography  variant="overline" color="inherit" display={'block'} noWrap className={classes.title}>
                            {/*{(brand)?(brand.account.account_title +'/'+brand.account.brand.brand_name):(null)}*/}
                            {this.state.datetime}
                        </Typography>
                        {/*<Tooltip title={(this.props.AuthStore.activeUser.name)?(this.props.AuthStore.activeUser.name):("")}>*/}
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event)=>{this.handleClick(event)}} color="inherit">
                            <Avatar alt={this.props.AuthStore.activeUser?.name} className={classes.orange} />
                        </IconButton>
                        {this.props.AuthStore.activeUser.name}/{this.props.AccountStore.brand.brand_name}
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={()=>{this.handleClose()}}
                        >
                            <MenuItem onClick={()=>{this.props.history.push({pathname:"/account"}); this.handleClose()}}>{this.props.LanguageStore.language.PROFILE}</MenuItem>
                            <MenuItem onClick={()=>{this.handleClose()}}>{this.props.LanguageStore.language.MY_ACCOUNT}</MenuItem>
                            <MenuItem onClick={()=>{this.handleLogout(); this.handleClose()}}>{this.props.LanguageStore.language.LOGOUT}</MenuItem>
                        </Menu>
                        {/*</Tooltip>*/}
                    </Toolbar>
                </AppBar>
            </>);
    }
}

const useStyles = theme => (Styles(theme));

export default withRouter(withStyles(useStyles, {withTheme: true})(NewNavbar));
