import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    Divider,
    FormControl,
    Grid, IconButton, InputLabel, Link, MenuItem,
    Paper, Select,
    Typography
} from "@material-ui/core";
import {CheckCircle, ExpandMore, HighlightOff} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import Upload from "../layout/Upload";
import {green} from "@material-ui/core/colors";
import LayoutStore from "../../store/LayoutStore";

@inject('DataStore','CDNStore','DocumentStore','LayoutStore','LanguageStore')
@observer
class Documents extends Component{
    state={
        documentType:"",
        documentTypes:[],
        alreadyUploaded:[]

    };
    alreadyUploaded(){
        let uploaded =[];
        this.props.DocumentStore.documents.map((document)=>{
            uploaded.push(document.document_type.id);
        });
        this.setState({alreadyUploaded:uploaded})
    }
    componentDidMount() {
        this.props.DataStore.getDocumentTypes().then(() => this.setState({documentTypes:this.props.DataStore.documentTypes}));
        this.props.DocumentStore.getDocuments().then(() => this.alreadyUploaded());
    }

    handleChange(type){
        console.log(this.state.alreadyUploaded.includes(type));
        if (!this.state.alreadyUploaded.includes(type)){
            this.setState({documentType: type});
        }else {
            this.setState({documentType: ""});
            this.props.LayoutStore.enqueueSnackbar({
                message: 'Bu belge zaten yüklü!',
                options: {
                    variant: 'info',
                },
            });
        }
    }
    isUploaded(type){
        let arr=false;
        this.props.DocumentStore.documents.filter((document)=>{
            if (document.document_type.id===type){
                arr=true;
            }
        });
        console.log("is: ",arr);
        return arr;
    }
    handleSave(){
        this.props.DocumentStore.addDocument(this.props.CDNStore.imageUploaded, this.state.documentType).then(async () => {
            this.setState({documentType: ""});
          await  this.props.CDNStore.resetImageUploaded();
            await this.props.DocumentStore.getDocuments();
        });
    }
    render() {
        const {classes}=this.props;
        return(<Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Paper className={classes.paper}>
                        <Accordion className={classes.uploadAccordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{this.props.LanguageStore.language.UPLOAD_DOCUMENT}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={12}><Typography variant="subtitle2" display="block" gutterBottom>{this.props.LanguageStore.language.DOCUMENT_UPLOAD_INF}</Typography></Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.DOCUMENT_TYPE}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.documentType}
                                                onChange={(e)=>this.handleChange(e.target.value)}
                                                label={this.props.LanguageStore.language.TYPE}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.state.documentTypes.map((type, index)=>(
                                                    <MenuItem key={index} value={type.id}>{type.document_type_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        {this.state.documentType && (
                                            <Upload/>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} style={{marginTop:10}}>
                                            <Button disabled={!this.props.CDNStore.imageUploaded} onClick={()=>this.handleSave()}  variant={'contained'} color={"primary"} fullWidth>{this.props.LanguageStore.language.SAVE}</Button>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Divider style={{marginTop:10, marginBottom:10}}/>
                        {this.state.documentTypes?.map((type, i)=>(
                            <Accordion key={i}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container direction="row"
                                          justify="space-around"
                                          alignItems="center">
                                        <Grid item xs={8} md={8} lg={8}>
                                            <Typography className={classes.heading} component={"h5"}>{type.document_type_name}</Typography>
                                        </Grid>
                                        <Grid item>
                                        {(this.isUploaded(type.id))?(
                                                <IconButton aria-label="Uploaded" color={"primary"}><CheckCircle /></IconButton>
                                                ):(
                                                <IconButton aria-label="Not Uploaded" color={"secondary"}><HighlightOff /></IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="column" justify="center" alignItems="center">
                                    {this.props.DocumentStore.documents.map((document, y)=>(
                                        <Grid containerdirection="column" justify="center" alignItems="center" key={y}>{(document.document_type.id===type.id) && (
                                        <Grid container direction="row" spacing={1}>
                                            <Grid item>
                                                <Typography>{document.document_type?.document_type_name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{document.created_at}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Link href={document.document_url} target={"_blank"}>{this.props.LanguageStore.language.DOWNLOAD}</Link>
                                            </Grid>
                                        </Grid>)}</Grid>
                                    ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            ))}
                    </Paper>
                </Grid>
                </Grid>
            );
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    uploadAccordion:{
      color:green[500]
    },
    fixedHeight: {
        height: 576,
    },
    formControl:{
        width:"100%"
    },
    margin: {
        margin: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default withStyles(useStyles, {withTheme: true})(Documents);
