import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";

import {withStyles} from "@material-ui/core/styles";
import NavCards from "../../components/Welcome/NavCards";
import {ShoppingBasket, List, Settings, QuestionAnswer, Help, Person} from "@material-ui/icons";
import grey from "@material-ui/core/colors/grey";
import {blueGrey, green, orange, pink, teal} from "@material-ui/core/colors";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import deepOrange from "@material-ui/core/colors/deepOrange";
import UserType from "../../components/Welcome/UserType";

@inject('LayoutStore', 'AuthStore','LanguageStore','TicketStore')
@observer
class NewWelcome extends Component {

    handleLogout() {
        console.log("logout");
        this.props.AuthStore.removeToken();
    }
    render() {
        const {classes}=this.props;
        return (
            <Grid container direction="row"
                  justify="center"
                  alignItems="center" spacing={3}>
                {this.props.AuthStore.user_role==="SUPER_ADMIN" && (
                    <UserType account={this.props.AuthStore.account_id} role={this.props.AuthStore.user_role}/>
                )}
                <Grid item className={classes.CardsContainer} xs={12} md={8} lg={8}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={2}
                    >
                        <Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=> {this.props.history.push({pathname: '/orders'})}} info={["Orders", "Order Details", "Order Actions"]} name={this.props.LanguageStore.language.ORDERS} color={green[600]} secondaryColor={green[400]} icon={<ShoppingBasket style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=> {this.props.history.push({pathname: '/definitions'})}} info={["Menu", "Product Groups", "Price Groups"]} name={this.props.LanguageStore.language.DEFINITIONS} color={pink[600]} secondaryColor={pink[400]} icon={<List style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=> {this.props.history.push({pathname: '/surveys'})}} info={["CRUD Surveys"]} name={this.props.LanguageStore.language.SURVEY} color={deepOrange[600]} secondaryColor={deepOrange[400]} icon={<QuestionAnswer style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>
                        {/*<Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=> {this.props.history.push({pathname: '/settings'})}} info={["Empty"]} name={this.props.LanguageStore.language.SETTINGS} color={blueGrey[600]} secondaryColor={blueGrey[400]} icon={<Settings style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>*/}
                        <Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=> {this.props.history.push({pathname: '/account'})}} info={["Account Address", "Payment Methods", "Near Places", "Credit"]} name={this.props.LanguageStore.language.PROFILE} color={teal[600]} secondaryColor={teal[400]} icon={<Person style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=>this.props.TicketStore.toggleDialog()} info={["Support Tickets"]} name={this.props.LanguageStore.language.HELP} color={orange[600]} secondaryColor={orange[400]} icon={<Help style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>
                        {/*<Grid item xs={3} md={3} lg={3}>
                            <NavCards onClick={()=>this.handleLogout()} name={this.props.LanguageStore.language.LOGOUT} color={grey[600]} secondaryColor={grey[400]} icon={<Close style={{color:grey[50], fontSize:60}}/>} />
                        </Grid>*/}
                    </Grid>
                </Grid>

            </Grid>);
    }
}
const useStyles = theme => ({
    CardsContainer:{
      marginTop:90
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 160,
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(NewWelcome));
