import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {
    GET_ACCOUNT_DETAILS, GET_ACCOUNT_PAYRULES,
    GET_ALL_ACCEPTED_ORDERS, GET_ALL_COMPLETED_ORDERS, GET_ALL_DAILY_ORDERS,
    GET_ALL_REFUSED_ORDERS,
    GET_ALL_WAITING_ORDERS, GET_CANCEL_REASONS, GET_NEAR_PLACES, GET_ORDER, GET_PAYRULES, GET_POINT_SET
} from "../graphql/Queries";
import {
    ACCEPT_ORDER, ADD_DELIVERY_CITY, ADD_DELIVERY_NEIGHBORHOOD, ADD_NEAR_PLACE,
    ADD_PAYRULE,
    ADD_WORK_HOURS,
    CANCEL_ORDER,
    COMPLETE_ORDER,
    CREATE_CONFIRMATION, DELETE_DELIVERY_POINT, DELETE_NEAR_PLACE,
    REMOVE_PAYRULE,
    TOGGLE_ACTIVE_GROUP,
    TOGGLE_RESTAURANT, UPDATE_DELIVERY_POINT, UPDATE_RESTAURANT_CLOSING,
    UPDATE_RESTAURANT_EMAIL, UPDATE_RESTAURANT_GSM,
    UPDATE_RESTAURANT_KEP,
    UPDATE_RESTAURANT_OPENING,
    UPDATE_RESTAURANT_TAX_NO, UPDATE_WORK_HOURS
} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";

class AccountStore {
    @observable loading = false;
    @observable accountId = null;
    @observable accountName = null;
    @observable accountType = null;
    @observable accountOpening = null;
    @observable accountClosing = null;
    @observable accountCenter = null;
    @observable accountPriceGroup = null;
    @observable allDailyOrders = [];
    @observable allWaitingOrders = [];
    @observable allAcceptedOrders = [];
    @observable allCancelledOrders = [];
    @observable allCompletedOrders = [];
    @observable cancelReasons = [];
    @observable account={};
    @observable pointSet={};
    @observable order={};
    @observable accountPayRules=[];
    @observable payRules=[];
    @observable nearPlaces=[];
    @observable brand = {id: null, brand_name: null, brand_banner: "", accounts: []};
    @observable address = null;
    @observable confirmation = {id: null, code: null, table: null}

    @action
    async getAccount() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if (!accountId) {
                return false;
            }

            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                data: {
                    query: `{${GET_ACCOUNT_DETAILS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAccount!");
                return false;
            }
            this.account=data.data.account;
            this.accountId = data.data.account.id;
            this.accountName = data.data.account.account_title;
            this.accountType = data.data.account.account_type;
            this.accountOpening = data.data.account.account_opening;
            this.accountClosing = data.data.account.account_closing;
            this.accountCenter = data.data.account.account_center;
            this.accountPriceGroup = data.data.account.account_price_group;
            this.brand = data.data.account.brand;
            this.address = data.data.account.address;

        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAccount!");
            return false;

        }
    }
    @action
    async getPointSet() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if (!accountId) {
                return false;
            }

            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                data: {
                    query: `{${GET_POINT_SET(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on getPointSet!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.pointSet=data.data.point_set;

        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on getPointSet!',
                options: {
                    variant: 'error',
                },
            }));
            return false;

        }
    }

    @action
    async getAccountPayRules(){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if (!accountId) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                data: {
                    query: `{${GET_ACCOUNT_PAYRULES(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at getAccountPayRules!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.accountPayRules=data.data.account_payrules;

        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at getAccountPayRules!',
                options: {
                    variant: 'error',
                },
            }));
            return false;
        }
    }
    @action
    async getPayRules(){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if (!accountId) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                data: {
                    query: `{${GET_PAYRULES()}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at getPayRules!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.payRules=data.data.order_payrules;

        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at getPayRules!',
                options: {
                    variant: 'error',
                },
            }));
            return false;
        }
    }

    @action async addPayRule(payrule, delivery, takeaway, eatin){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_PAYRULE(parseInt(accountId), parseInt(payrule), delivery, eatin, takeaway)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on addPayRule!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Payment Method Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on addPayRule!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async removePayRule(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${REMOVE_PAYRULE(parseInt(id))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on removePayRule!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Removed Payment Method Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on removePayRule!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async createConfirmation(table) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `mutation {${CREATE_CONFIRMATION(parseInt(accountId), parseInt(table))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AccountStore at createConfirmation!");
                LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at createConfirmation!',
                    options: {
                        variant: 'error',
                    },
                });
                return false;
            }
            this.confirmation.id = data.data.createConfirmation.id;
            this.confirmation.code = data.data.createConfirmation.code;
            this.confirmation.table = data.data.createConfirmation.table;
            LayoutStore.enqueueSnackbar({
                message: 'QR Code generated successfully!',
                options: {
                    variant: 'success',
                },
            });
        } catch (e) {
            console.log(e);
            this.loading = false;
            //alert("OOPS. Something went wrong on: AccountStore at createConfirmation!");
            LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at createConfirmation!',
                options: {
                    variant: 'error',
                },
            });
            return false;
        }
    }

    @action
    async getAllDailyOrders() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_DAILY_ORDERS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAllDailyOrders!");
                return false;
            }
            this.allDailyOrders = data.data.all_daily_orders;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAllDailyOrders!");
            return false;
        }
    }
    @action
    async getAllWaitingOrders() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_WAITING_ORDERS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAllWaitingOrders!");
                return false;
            }
            this.allWaitingOrders = data.data.all_waiting_orders;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAllWaitingOrders!");
            return false;
        }
    }
    @action
    async getAllAcceptedOrders() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_ACCEPTED_ORDERS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAllAcceptedOrders!");
                return false;
            }
            this.allAcceptedOrders = data.data.all_accepted_orders;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAllAcceptedOrders!");
            return false;
        }
    }
    @action
    async getAllCancelledOrders() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_REFUSED_ORDERS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAllCancelledOrders!");
                return false;
            }
            this.allCancelledOrders = data.data.all_refused_orders;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAllCancelledOrders!");
        }
    }
    @action
    async getAllCompletedOrders() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_COMPLETED_ORDERS(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getAllCompletedOrders!");
                return false;
            }
            this.allCompletedOrders = data.data.all_completed_orders;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getAllCompletedOrders!");
        }
    }
    @action
    async getOrder(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ORDER(parseInt(id))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getOrder!");
                return false;
            }
            this.order = data.data.order;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getOrder!");
        }
    }
    @action
    async getCancelReasons(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_CANCEL_REASONS(parseInt(id))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on getCancelReasons!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.cancelReasons = data.data.order_cancel_reasons;
        } catch (e) {
            console.log(e);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on getCancelReasons!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async completeOrder(id,order, index) {
        try {
            this.loading = true;
            let accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation{${COMPLETE_ORDER(parseInt(id))}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AccountStore at completeOrder!");
                LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at completeOrder!',
                    options: {
                        variant: 'error',
                    },
                });
                return false;
            }
            runInAction(() => {
                //this.allWaitingOrders.splice(index, 1);
                this.allAcceptedOrders.splice(index, 1);
                this.allCompletedOrders.unshift(order);

                LayoutStore.enqueueSnackbar({
                    message: 'Completed order successfully!',
                    options: {
                        variant: 'success',
                    },
                });
            });
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
            //alert("OOPS. Something went wrong on: AccountStore at completeOrder!");
            LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at completeOrder!',
                options: {
                    variant: 'error',
                },
            });
        }
    }
    @action
    async acceptOrder(id, order, index) {
        try {
            this.loading = true;
            let accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation{${ACCEPT_ORDER(parseInt(id))}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AccountStore at acceptOrder!");
                LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at acceptOrder!',
                    options: {
                        variant: 'error',
                    },
                });
                return false;
            }
            runInAction(() => {
                this.allWaitingOrders.splice(index, 1);
                this.allAcceptedOrders.unshift(order);
                LayoutStore.enqueueSnackbar({
                    message: 'Accepted order successfully!',
                    options: {
                        variant: 'success',
                    },
                });
            });
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
            //alert("OOPS. Something went wrong on: AccountStore at acceptOrder!");
            LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at acceptOrder!',
                options: {
                    variant: 'error',
                },
            });
        }
    }
    @action
    async cancelOrder(id,order, index, reasonId) {
        try {
            this.loading = true;
            let accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: 'post',
                data: {
                    query: `mutation{${CANCEL_ORDER(parseInt(id), parseInt(reasonId))}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AccountStore at cancelOrder!");
                LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AccountStore at cancelOrder!',
                    options: {
                        variant: 'error',
                    },
                });
                return false;
            }
            runInAction(() => {
                this.allWaitingOrders.splice(index, 1);
                this.allAcceptedOrders.splice(index, 1);
                LayoutStore.enqueueSnackbar({
                    message: 'Cancelled order successfully!',
                    options: {
                        variant: 'default',
                    },
                });
            });
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
            //alert("OOPS. Something went wrong on: AccountStore at cancelOrder!");
            LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AccountStore at cancelOrder!',
                options: {
                    variant: 'error',
                },
            });
        }
    }

    @action async toggleActiveGroup(id, status){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${TOGGLE_ACTIVE_GROUP(parseInt(id), Number(status))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at toggleActiveGroup!");
                return false;
            }
            this.loading = false;
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'Changed Active Group Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AccountStore at toggleActiveGroup!");
        }
    }

    @action async toggleRestaurant(delivery, takeaway, eatin){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${TOGGLE_RESTAURANT(parseInt(accountId), delivery, takeaway, eatin)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on toggleRestaurant!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Work Methods Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on toggleRestaurant!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantEmail(email){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_EMAIL(parseInt(accountId), email)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantEmail!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant E-Mail Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantEmail!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantKep(kep){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_KEP(parseInt(accountId), kep)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantKep!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Kep Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantKep!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantGSM(gsm){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_GSM(parseInt(accountId), gsm)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantGSM!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Kep Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantGSM!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantTaxNo(taxNo){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_TAX_NO(parseInt(accountId), taxNo)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantTaxNo!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Tax No. Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantTaxNo!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantOpening(opening){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_OPENING(parseInt(accountId), opening)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantOpening!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Opening Hours Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantOpening!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateRestaurantClosing(closing){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_RESTAURANT_CLOSING(parseInt(accountId), closing)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateRestaurantClosing!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Opening Hours Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateRestaurantClosing!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async addDeliveryPointCity(row, pointFee, pointMinPay, pointTime){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_DELIVERY_CITY(parseInt(row), parseInt(accountId), parseFloat(pointFee), parseFloat(pointMinPay), parseInt(pointTime))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on addDeliveryPoint!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Restaurant Delivery Point Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on addDeliveryPoint!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addDeliveryPointNeighborhood(row, pointFee, pointMinPay, pointTime){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_DELIVERY_NEIGHBORHOOD(parseInt(row), parseInt(accountId), parseFloat(pointFee), parseFloat(pointMinPay), parseInt(pointTime))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on addDeliveryPointNeighborhood!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Restaurant Delivery Point Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on addDeliveryPointNeighborhood!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateDeliveryPoint(id, pointFee, pointMinPay, pointTime){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_DELIVERY_POINT(Number(id), parseInt(accountId), parseFloat(pointFee), parseFloat(pointMinPay), Number(pointTime))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateDeliveryPoint!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Set Restaurant Delivery Point Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateDeliveryPoint!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async deleteDeliveryPoint(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${DELETE_DELIVERY_POINT(Number(id), parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on deleteDeliveryPoint!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Restaurant Delivery Point Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on deleteDeliveryPoint!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async getNearPlaces() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_NEAR_PLACES(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AccountStore at getNearPlaces!");
                return false;
            }
            this.nearPlaces = data.data.account_near_places;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AccountStore at getNearPlaces!");
        }
    }
    @action async addNearPlaces(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_NEAR_PLACE(parseInt(accountId), Number(id))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on addNearPlaces!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Near Place Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on addNearPlaces!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async deleteNearPlaces(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${DELETE_NEAR_PLACE(parseInt(accountId), Number(id))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on deleteNearPlaces!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Near Place Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on deleteNearPlaces!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }



    @action async updateWorkHours(id, dayType, time){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_WORK_HOURS(Number(id), parseInt(accountId), dayType, time)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on updateWorkHours!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Restaurant Work Hours Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on updateWorkHours!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addWorkHours(){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_WORK_HOURS(parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on AccountStore on addWorkHours!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Restaurant Work Hours Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on AccountStore on addWorkHours!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

}

export default new AccountStore();
