import {action, observable, runInAction, toJS} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT, GET_PRODUCT_GROUPS, GET_PRODUCT_PRICE_GROUPS} from "../graphql/Queries";
import LayoutStore from "./LayoutStore";
import {
    ADD_PRODUCT,
    ADD_PRODUCT_DETAIL,
    ADD_PRODUCT_MENU,
    ADD_PRODUCT_MENU_ITEM,
    ADD_PRODUCT_PRICE
} from "../graphql/Mutations";

class CopyProductStore {
    @observable loading=false;
    @observable products=[];
    @observable product={};
    @observable productGroups=[];
    @observable productGroup=null;
    @observable accountTCF=12;
    @observable newProductId=null;
    @observable newMenuId=null;
    @observable priceGroups=[];
    @observable defaultPriceGroupId=null;


    constructor(props) {
        this.getDefaultPriceGroup();
    }
    @action async getProductGroups() {
        try {
            this.loading = true;
            if (!this.accountTCF) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT_GROUPS(parseInt(this.accountTCF), parseInt(0))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS! Something went wrong...',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);
            this.loading = false;
            this.productGroups = data.data.product_groups;
        } catch (e) {
            //console.log(e);
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS! Something went wrong...',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async getProduct(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT(id, this.accountTCF)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at getProduct()!");
                this.loading = false;
                return false;
            }
            this.product = data.data.product;
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at getProduct()");
        }
    }

    @action
    async addProduct(productName, productNumber, productActive, productType, mainGroupId, imageUrl, isMenu) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT(mainGroupId, parseInt(accountId), productName, parseInt(productType), Number(productActive), parseInt(productNumber), imageUrl, Boolean(isMenu))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore!!!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: CopyProductStore!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("new product", data);
            this.newProductId=data.data.add_product.id
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Successfully!',
                options: {
                    variant: 'success',
                },
            }));
            //this.history.push({pathname: `/menu-management/${data.data.add_product.id}`});
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: CopyProductStore!!!',
                options: {
                    variant: 'error',
                },
            }));        }
    }

    @action
    async addPrice(id, price, takeaway, eatin, group) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_PRICE(id, parseFloat(price), parseFloat(takeaway), parseFloat(eatin), 1, parseInt(accountId), parseInt(group))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on CopyProductStore!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Price Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on CopyProductStore!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async addDescription(productId, detailType=2, detailText) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_DETAIL(productId, detailType, detailText, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductStore at addDescription!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            await this.getProduct(productId);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Description Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddProductStore at addDescription!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async addProductMenu(product, name, price, menuType,sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU(parseInt(menuType), name, parseFloat(price), product, parseInt(accountId), parseInt(sort))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
                return false;
            }
            this.newMenuId = data.data.add_product_menu.id;
            //console.log('from store',this.justAddedMenuId, data.data.add_product_menu.id);
            //await this.getProduct(product);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Menu Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
        }
    }

    @action
    async addProductMenuItem(menu, type, mainProduct, productId, price, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU_ITEM(parseInt(menu), parseInt(type), parseInt(productId), parseFloat(price), parseInt(accountId), parseInt(sort))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: CopyProductStore at addProductMenuItem!");
                return false;
            }
            await this.getProduct(parseInt(mainProduct));
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Menu Item Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenuItem!");
        }
    }

    @action
    async getDefaultPriceGroup() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if (!accountId) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT_PRICE_GROUPS(parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product main groups", data.data.product_price_groups);
            this.loading = false;
            this.priceGroups = data.data.product_price_groups;
            let aa = [];
            this.priceGroups.map(cumo => {if (cumo.default === "true"){aa.push(toJS(cumo))}});
            this.defaultPriceGroupId=(aa.length>0)?(aa[0].id):(null);
        } catch (e) {
            this.loading = false;
            console.log("product main groups error", e);
            alert("OOPS. Something went wrong");
        }
    }

}

export default new CopyProductStore();
