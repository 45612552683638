import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_ADDRESSES} from "../graphql/Queries";
import {ADD_ADDRESS, ADD_TO_GROUP2, UPDATE_MAP_COORDINATES} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";

class AddressStore {
    @observable loading=false;
    @observable addresses=[];
    @observable address={};

    @action
    async getAllAddresses(type) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ADDRESSES(type)}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddressesStore at getAllAddresses!");
                return false;
            }
            this.addresses = data.data.addresses;
        } catch (e) {
            console.log(e);
            this.loading = false;
            alert("OOPS. Something went wrong on: AddressesStore at getAllAddresses!");
            return false;
        }
    }

    @action
    async addAddress(name, phone, country, city, town, district, neighborhood, text, direction, type=1, lat=0, long=0) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            let formData = {};
            formData['lat'] =  lat;
            formData['lng'] =  long;
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_ADDRESS(name, phone, country, city, town, district, neighborhood, text, direction, type, parseInt(accountId), lat, long,JSON.stringify(formData))}}`
                }
            });



            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddressStore on addAddress!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Added Address Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddressStore on addAddress!!!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action
    async updateMap(id, lat, long) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            let formData = {};
            formData['lat'] =  lat;
            formData['lng'] =  long;

            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_MAP_COORDINATES(parseInt(id), lat, long, JSON.stringify(formData))}}`
                }
            });



            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddressStore on updateMap!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Map Coordinates Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddressStore on updateMap!!!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
}

export default new AddressStore();
