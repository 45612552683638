import {action, observable, runInAction} from "mobx";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT_M_GROUPS} from "../graphql/Queries";

class ProductMGroupStore {
    @observable productMGroups = [];
    @observable loading = false;


    @action async getProductMGroups() {
        try {
            this.loading = true;
            const {data} = await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_PRODUCT_M_GROUPS}}`

                }
            });
            if (data.errors){
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product main groups", data);

            this.loading=false;
            runInAction(()=>{
                this.productMGroups=data.data.product_m_groups;
            })

        } catch (e) {
            this.loading=false;
            console.log("product main groups error", e);
            alert("OOPS. Something went wrong");

        }
    }
}

export default new ProductMGroupStore();
