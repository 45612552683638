import {action, observable} from "mobx";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT_PRICE_GROUPS} from "../graphql/Queries";
import AsyncStorage from "@callstack/async-storage";
import {ADD_PRICE_GROUP, UPDATE_PRICE_GROUP} from "../graphql/Mutations";

class PriceGroupStore {
    @observable priceGroups = [];
    @observable loading = false;

    @action
    async getPriceGroups() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if (!accountId) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT_PRICE_GROUPS(parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product main groups", data);
            this.loading = false;
            this.priceGroups = data.data.product_price_groups;
        } catch (e) {
            this.loading = false;
            console.log("product main groups error", e);
            alert("OOPS. Something went wrong");
        }
    }

    @action async addPriceGroup(name, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRICE_GROUP(parseInt(accountId), name,active)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: PriceGroupStore at addPriceGroup!");
                return false;
            }
            await this.getPriceGroups();
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: PriceGroupStore at addPriceGroup!");
        }
    }

    @action async updatePriceGroup(id,name, active){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRICE_GROUP(parseInt(id), parseInt(accountId), parseInt(accountId), name, Boolean(active))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: PriceGroupStore at updatePriceGroup!");
                return false;
            }
            await this.getPriceGroups();
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: PriceGroupStore at updatePriceGroup!");
        }
    }


}

export default new PriceGroupStore();
