import React, {Component} from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Footer from "../../components/layout/Footer";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

@inject('RegistrationStore','AuthStore', 'LanguageStore')
@observer
class CreateUser extends Component {
    static defaultProps = {};
    static propTypes = {};
    state = {
        name:"",
        email: "",
        password: "",
        rePassword:""
    };

    validateForm() {
        return this.state.name.length > 3 && this.state.email.length > 7 && this.state.password.length > 7 && this.state.rePassword.length > 7 && (this.state.password===this.state.rePassword);
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <Backdrop className={classes.backdrop} open={this.props.RegistrationStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create User
                    </Typography>
                    <Typography hidden={true} component={'p'}>{this.props.LanguageStore.language.LOADING}!!!</Typography>
                    <form className={classes.form} onSubmit={(event)=>{
                        event.preventDefault();
                        this.props.AuthStore.all_props = this.props;
                        console.log(this.state);
                        this.props.RegistrationStore.register(this.state.name, this.state.email, this.state.password).then(r=> this.setState({name:"", email:"", password:"",rePassword:""}));

                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            name="name"
                            autoComplete="name"
                            value={this.state.name}
                            onChange={e => this.setState({name: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            type={"email"}
                            label={this.props.LanguageStore.language.EMAIL}
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={e => this.setState({email: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={this.props.LanguageStore.language.PASSWORD}
                            type="password"
                            id="password"
                            value={this.state.password}
                            onChange={e => this.setState({password: e.target.value})}
                            autoComplete="current-password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="re-password"
                            label={"Repeat Password"}
                            type="text"
                            id="re-password"
                            value={this.state.rePassword}
                            onChange={e => this.setState({rePassword: e.target.value})}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!this.validateForm()}
                        >
                            Sign Up
                        </Button>
                        {/*<Grid container>
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Do you have account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>*/}
                    </form>
                </div>
                {/*<Box mt={8}>
                    <Footer/>
                </Box>*/}
            </Container>
        );
    }
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

export default withRouter(withStyles(useStyles, { withTheme: true })(CreateUser));
