import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {
    Button,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {EvStation, Poll} from "@material-ui/icons";

@inject('LanguageStore', 'CreditStore')
@observer
class Credit extends Component {
    state = {
        showLog: false
    }

    async componentDidMount() {
        await this.props.CreditStore.getCumulativeCredit();
    }

    sourceHandler(id) {
        let sources = [{id: 1, source: "CRM'DEN YÜKLENEN"},
            {id: 2, source: "YEMEK ARENADAN HARCANAN"},
            {id: 3, source: "HAPPY LOYALTY'DEN HARCANAN"},
            {id: 4, source: "YEMEK ARENADAN WEB QR HARCANAN"}];
        return sources.find((row) => row.id === id);
    }

    async handleShowLog() {
        await this.props.CreditStore.getCredits();
        this.setState({showLog: true});
    }

    render() {
        const {classes} = this.props;
        return (<>
            <Backdrop className={classes.backdrop} open={this.props.CreditStore.loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12} lg={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Typography variant={"h6"} component={"h6"} color={"textSecondary"}>
                                                    {this.props.LanguageStore.language.CREDIT}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size="large"
                                                    className={classes.incomingCredit}
                                                    startIcon={<EvStation/>}
                                                >
                                                    {this.props.CreditStore.cumulativeCredit?.incoming}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size="large"
                                                    className={classes.outgoingCredit}
                                                    startIcon={<EvStation/>}
                                                >
                                                    {this.props.CreditStore.cumulativeCredit?.outgoing}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size="large"
                                                    className={((parseFloat(this.props.CreditStore.cumulativeCredit?.incoming) - parseFloat(this.props.CreditStore.cumulativeCredit?.outgoing) > 0)) ? classes.incomingCredit : classes.outgoingCredit}
                                                    startIcon={<Poll/>}
                                                >
                                                    {parseFloat(this.props.CreditStore.cumulativeCredit?.incoming) - parseFloat(this.props.CreditStore.cumulativeCredit?.outgoing)}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item={true} xs={12} md={12} lg={12}>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                {(!this.state.showLog) ? (<Grid container alignItems='center' justify='center'>
                                    <Grid item={true} xs={"auto"} md={"auto"}>
                                        <Button size={"large"} onClick={() => this.handleShowLog()}
                                                variant={"contained"} color={"primary"}
                                                className={classes.centerButton}>Show logs</Button>
                                    </Grid>
                                </Grid>) : (
                                    <Table className={classes.table} stickyHeader={true} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>source</TableCell>
                                                <TableCell align="right">incoming</TableCell>
                                                <TableCell align="right">outgoing</TableCell>
                                                <TableCell align="right">info(s)</TableCell>
                                                <TableCell align="right">date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.CreditStore.creditLog.length > 0 && this.props.CreditStore.creditLog.slice().reverse().map((credit) => (
                                                <TableRow key={credit.id}>
                                                    <TableCell component="th" scope="row">
                                                        {(this.sourceHandler(credit?.source))?.source}
                                                    </TableCell>
                                                    <TableCell align="right"
                                                               className={classes.incomingCredit}>{credit.incoming}</TableCell>
                                                    <TableCell align="right"
                                                               className={classes.outgoingCredit}>{credit.outgoing}</TableCell>
                                                    <TableCell align="right">{credit.info}</TableCell>
                                                    <TableCell align="right">{credit.created_at}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid></>);
    }
}

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 0px',
        transform: 'scale(0.8)',
    },
    centerButton: {
        marginTop: "50%"
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    table: {},
    tableContainer: {
        minHeight: 200
    },
    incomingCredit: {
        color: "rgb(2, 192, 118)"
    },
    outgoingCredit: {
        color: "rgb(248, 73, 96)"
    }
});
export default withStyles(useStyles, {withTheme: true})(Credit);
