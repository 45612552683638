import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import ProductDetailList from "./ProductDetailList";
import AddProductMenu from "./AddProductMenu";
import ProductMenu from "./ProductMenu";
import AddProductMenuItem from "./AddProductMenuItem";
import DeleteProductMenu from "./DeleteProductMenu";
import {Edit, HelpOutline, PostAdd} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import {Alert} from "@material-ui/lab";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    const {title}=props;
    let obj = {...props, title: (title?title:"-")}
    return <Tooltip arrow classes={classes} {...obj} />;
}


@inject('AddProductStore', 'LanguageStore','ProductGroupStore')
@observer
class Product extends Component {
    state = {
        product: [],
        group2Modal:false,
        closeToSaleModal:false,
        closeToSaleUpdateModal:false,
        closeToSaleUntil:moment().add(1,'days').format('YYYY-MM-DDTHH:mm'),
        group2:""
    }

    async componentDidMount(prevProps) {
        await this.props.AddProductStore.getProduct(this.props.productId);
        this.setState({product: toJS(this.props.AddProductStore.product)});
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            await this.props.AddProductStore.getProduct(this.props.productId);
            this.setState({product: toJS(this.props.AddProductStore.product)});
        }
    }
    saveGroup2(){
        this.props.AddProductStore.addToGroup2(this.state.product.id, this.state.group2).then(r => {
            this.setState({group2Modal:false, group2:""});
            this.props.ProductGroupStore.getProductGroups(-1);
            this.props.AddProductStore.getProduct(this.props.productId);
        });
    }
    resetGroup2(){
        this.props.AddProductStore.addToGroup2(this.state.product.id, null).then(r => {
            this.setState({group2Modal:false, group2:""});
            this.props.ProductGroupStore.getProductGroups(-1);
            this.props.AddProductStore.getProduct(this.props.productId);
        });
    }
    isClosedNow(){
        return (moment().isBefore(moment(parseInt(this.props.AddProductStore.product.account_product_not_show?.except_p_not_show)).subtract(3, 'hours')))
    }
    async saveCloseToSale() {
        await this.props.AddProductStore.addCloseToSale(this.props.AddProductStore.product.id, this.props.AddProductStore.product.product_name, this.props.AddProductStore.product.product_type.id, this.state.closeToSaleUntil);
        this.setState({closeToSaleModal:false});
    }

    async updateCloseUntil(id){
        await this.props.AddProductStore.updateCloseToSale(id,  this.props.AddProductStore.product.id, this.state.closeToSaleUntil);
        this.setState({closeToSaleUpdateModal:false});
        this.setState({closeToSaleUpdateModal:false});
    }
    async resetCloseUntil(id){
        await this.props.AddProductStore.updateCloseToSale(id,  this.props.AddProductStore.product.id, moment().format('YYYY-MM-DD HH:mm'));
        this.setState({closeToSaleUpdateModal:false});
    }


    render() {
        const {classes} = this.props;
        return (
            <div>
                <Backdrop className={classes.backdrop} open={this.props.AddProductStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Paper>
                    {this.isClosedNow() && (<Alert action={<IconButton color="inherit" size="small"><HelpOutline/></IconButton>} className={classes.alert} severity={"error"}>Ürün Satışa Kapalı</Alert>)}
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={3}>
                            <Card className={classes.productImg} elevation={0}>
                                <CardMedia
                                    component="img"
                                    alt={`${this.props.AddProductStore.product.product_name}`}
                                    image={`${(this.props.AddProductStore.product.img_url) ? (this.props.AddProductStore.product.img_url) : ("https://dummyimage.com/240x180/fff/aaa")}`}
                                    title={`${this.props.AddProductStore.product.product_name}`}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={8} lg={9}>
                            {/*ProductDetailList*/}
                            <Grid container direction={"row"} justify={"flex-end"}>
                                <Grid item>
                                    <BootstrapTooltip title={"Şu tarihe kadar satışa kapalı"}>
                                        {(this.props.AddProductStore.product. account_product_not_show)?(<>
                                                {this.isClosedNow() ? (<Button style={{color:"red"}}  onClick={() =>this.setState({closeToSaleUpdateModal:true})} aria-label="add-to-group2" color="default">
                                                        {moment(parseInt(this.props.AddProductStore.product.account_product_not_show?.except_p_not_show)).subtract(3, 'hours').format("DD-MM-YYYY HH:mm:ss")}
                                                    </Button>):( <Button  onClick={() =>this.setState({closeToSaleUpdateModal:true})} startIcon={<PostAdd/>} aria-label="add-to-group2" color="default">
                                                    {"Satışa Kapat"}
                                                </Button>)}</>
                                            ):(
                                            <Button  onClick={() =>this.setState({closeToSaleModal:true})} startIcon={<PostAdd/>} aria-label="add-to-group2" color="default">
                                                {"Satışa Kapat"}
                                            </Button>
                                        )}
                                    </BootstrapTooltip>
                                </Grid>
                                <Grid item>
                                    <BootstrapTooltip title={this.props.LanguageStore.language.SECONDARY_GROUP}>
                                        {(this.props.AddProductStore.product.product_group2)?(<Button  onClick={() =>this.setState({group2Modal:true})} aria-label="add-to-group2" color="default">
                                            {this.props.AddProductStore.product.product_group2?.product_group_main?.main_group_name}
                                        </Button>):(
                                            <Button  onClick={() =>this.setState({group2Modal:true})} startIcon={<PostAdd/>} aria-label="add-to-group2" color="default">
                                                {this.props.LanguageStore.language.ADD}
                                            </Button>
                                        )}
                                    </BootstrapTooltip>
                                </Grid>
                                <Grid item>
                                    <BootstrapTooltip title={this.props.LanguageStore.language.EDIT}>
                                    <Button onClick={() => {
                                        this.props.history.push({pathname: `/definitions/menu-management/${this.state.product.id}/edit`})
                                    }} aria-label="edit-product" color="default" startIcon={<Edit/>}>
                                        {this.props.LanguageStore.language.EDIT}
                                    </Button>
                                    </BootstrapTooltip>
                                </Grid>
                            </Grid>
                            <ProductDetailList data={{product: this.props.AddProductStore.product}}/>
                        </Grid>
                    </Grid>
                </Paper>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignContent={'flex-end'}>
                            {(()=>{
                                if(this.state.product.product_type && this.state.product.product_type.id===2){
                                    return (
                                        <Grid item xs={12} md={6}>
                                            <AddProductMenu productId={this.state.product.id}/>
                                        </Grid>
                                    );
                                }else {
                                    return null;
                                }
                            })()}

                        </Grid>
                        <br/>
                        <ProductMenu productId={this.props.AddProductStore.product.id}
                                     data={this.props.AddProductStore.product.product_menu}/>
                    </Grid>

                </Grid>
                <AddProductMenuItem productId={this.props.AddProductStore.product.id}/>
                <DeleteProductMenu productId={this.props.AddProductStore.product.id}/>
                {/*ADD TO GROUP2*/}
                <Dialog
                    open={this.state.group2Modal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{this.props.LanguageStore.language.SECONDARY_GROUP}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Add Product to Another Group"}
                        </DialogContentText>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.MAIN_PRODUCT_GROUPS}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.group2}
                                onChange={(e)=>this.setState({group2:e.target.value})}
                                label={this.props.LanguageStore.language.MAIN_PRODUCT_GROUPS}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {this.props.ProductGroupStore.productGroups.map((group, index)=>(
                                    <MenuItem key={index} value={group.product_group_main.id}>{group.product_group_main.main_group_name}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.resetGroup2()} disabled={this.props.AddProductStore.loading} color="secondary">
                            {this.props.LanguageStore.language.DELETE}
                        </Button>
                        <Button onClick={()=>{this.setState({group2Modal:false, group2:""})}} disabled={this.props.AddProductStore.loading} color="secondary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={()=>{this.saveGroup2()}} disabled={this.props.AddProductStore.loading || this.state.group2==="" } color="primary" autoFocus>
                            {this.props.LanguageStore.language.ACCEPT}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*ADD CLOSE TO SALE*/}
                <Dialog
                    open={this.state.closeToSaleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Süreli Satışa Kapatma"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Süreli Satışa Kapatma"}
                        </DialogContentText>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <TextField
                                id="outlined-secondary"
                                variant="outlined"
                                value={this.state.closeToSaleUntil}
                                color="secondary"
                                onChange={(e)=>this.setState({closeToSaleUntil:e.target.value})}
                                type={"datetime-local"}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        {/*<Button onClick={()=>this.resetGroup2()} disabled={this.props.AddProductStore.loading} color="secondary">
                            {this.props.LanguageStore.language.DELETE}
                        </Button>*/}
                        <Button onClick={()=>{this.setState({closeToSaleModal:false})}} disabled={this.props.AddProductStore.loading} color="secondary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={()=>{this.saveCloseToSale()}} disabled={this.props.AddProductStore.loading || this.state.closeToSaleUntil==="" } color="primary" autoFocus>
                            {this.props.LanguageStore.language.ACCEPT}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*UPDATE CLOSE TO SALE*/}
                <Dialog
                    open={this.state.closeToSaleUpdateModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Süreli Satışa Kapatma"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Süreli Satışa Kapatma"}
                        </DialogContentText>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <TextField
                                id="outlined-secondary"
                                variant="outlined"
                                value={this.state.closeToSaleUntil}
                                color="secondary"
                                onChange={(e)=>this.setState({closeToSaleUntil:e.target.value})}
                                type={"datetime-local"}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.resetCloseUntil(this.props.AddProductStore.product.account_product_not_show?.id)} disabled={this.props.AddProductStore.loading} color="secondary">
                            {"SIFIRLA"}
                        </Button>
                        <Button onClick={()=>{this.setState({closeToSaleUpdateModal:false})}} disabled={this.props.AddProductStore.loading} color="secondary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={async () => {
                            await this.updateCloseUntil(this.props.AddProductStore.product.account_product_not_show?.id)
                        }} disabled={this.props.AddProductStore.loading || this.state.closeToSaleUntil==="" } color="primary" autoFocus>
                            {this.props.LanguageStore.language.ACCEPT}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const useStyles = theme => ({
    productImg: {
        maxWidth: 240,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    editBtn: {
        marginLeft: "90%"
    }
});

export default withRouter(withStyles(useStyles, {withTheme: true})(Product));
