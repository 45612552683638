import React, {Component} from "react";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Footer from "../../components/layout/Footer";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";

@inject("AuthStore","LanguageStore")
@observer
class UpdatePassword extends Component{
    state={
        email:"",
        token:"",
        newPassword:"",
        rePassword:""
    }
    componentDidMount() {
        //console.log("sa",this.props.match.params.email);
        if (this.props.match.params.email){
            this.setState({email:this.props.match.params.email});
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.token.length > 5 && this.state.newPassword.length > 0 && (this.state.newPassword===this.state.rePassword);
    }
    async handleSubmit() {
        await this.props.AuthStore.updatePassword(this.state.email, this.state.token, this.state.newPassword);
        await this.props.history.push({pathname: `/login`});
    }
    render() {
        const {classes}=this.props;
        return(
            <Container component="main" maxWidth="xs">
                <Backdrop className={classes.backdrop} open={this.props.AuthStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {this.props.LanguageStore.language.UPDATE_PASSWORD}
                    </Typography>
                    {this.props.AuthStore.errors?.map((error, i)=>(
                        <Typography key={i} color={'secondary'} component={'p'}>{error.message}</Typography>
                    ))}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={this.props.LanguageStore.language.EMAIL}
                        name="email"
                        type={"email"}
                        autoComplete="email"
                        value={this.state.email}
                        onChange={e => this.setState({email: e.target.value})}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="token"
                        label={this.props.LanguageStore.language.TOKEN}
                        name="token"
                        autoComplete="token"
                        value={this.state.token}
                        onChange={e => this.setState({token: e.target.value})}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        type={"password"}
                        label={this.props.LanguageStore.language.PASSWORD}
                        name="password"
                        autoComplete="password"
                        value={this.state.newPassword}
                        onChange={e => this.setState({newPassword: e.target.value})}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="re-password"
                        type={"password"}
                        label={this.props.LanguageStore.language.PASSWORD}
                        name="re-password"
                        autoComplete="password"
                        value={this.state.rePassword}
                        onChange={e => this.setState({rePassword: e.target.value})}
                        autoFocus
                    />
                    <Button fullWidth variant="contained" color="primary" onClick={()=>this.handleSubmit()} className={classes.submit} disabled={!this.validateForm()}>
                        {this.props.LanguageStore.language.SIGN_IN}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#/login" variant="body2">
                                Login
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <Box mt={8}>
                    <Footer/>
                </Box>
            </Container>
        );
    }
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
export default withRouter(withStyles(useStyles, { withTheme: true })(UpdatePassword));
