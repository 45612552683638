import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT} from "../graphql/Queries";
import {POST_QR_ORDER} from "../graphql/Mutations";

class LayoutStore {
    @observable drawer = true;
    @observable PMIModal = false;
    @observable deletePMModal = false;
    @observable PMId = null;
    @observable PMItems = [];
    @observable PMType = null;
    @observable notifications = [];
    @observable copyDialog = false;
    @observable loading=false;

    @observable menuChoicesDialog=false;
    @observable productWM=[];
    @observable cart=[];
    @observable cartTotalPrice=0;

    @observable menuInMenuDialog=false;
    @observable productType3={};
    @observable itemType3= {};
    @observable menuType3= {};
    @observable selection=[];



    @action toggleDrawer() {
        runInAction(() => {
            this.drawer = !this.drawer;
        })
    }
    @action toggleCopyDialog() {
        runInAction(() => {
            this.copyDialog = !this.copyDialog;
        })
    }
    @action togglePMIModal(PMId,PMType) {
        this.PMIModal = !this.PMIModal;
        this.PMId = parseInt(PMId);
        this.PMType = parseInt(PMType);
        console.log("menu type", PMType);
    }
    @action toggleDeletePMModal(PMId, items) {
        this.deletePMModal = !this.deletePMModal;
        this.PMId = parseInt(PMId);
        this.PMItems = items;
    }
    @action toggleMenuChoicesDialog() {
        runInAction(() => {
            this.menuChoicesDialog = !this.menuChoicesDialog;
        })
    }
    @action toggleMenuInMenuDialog() {
        runInAction(() => {
            this.menuInMenuDialog = !this.menuInMenuDialog;
        })
    }
    @action async setProductWM(product){
        runInAction(() => {
            this.productWM = product;
        })
    }
    @action async setProductWMIM(product){
        runInAction(() => {
            this.productWMIM = product;
        })
    }
    @action async getProduct(menu, item){
        try{
            runInAction(()=>{this.loading = true})
            const accountId = await AsyncStorage.getItem('accountNo');
            const {data} = await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`{${GET_PRODUCT(item.product.id, parseInt(accountId))}}`
                }
            });
            //alert(id);
            if(data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => this.enqueueSnackbar({
                    message: 'OOPS. Something went wrong! On LayoutStore @getProduct',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            runInAction( () => {
                this.productType3 = data.data.product;
                this.itemType3 = item;
                this.menuType3 = menu;
                this.loading = false;
            })
        }catch (e) {
            this.loading=false;
            runInAction(() => this.enqueueSnackbar({
                message: 'OOPS. Something went wrong! On LayoutStore @getProduct',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action setCartTotal(total){
        runInAction(()=>{
            this.cartTotalPrice=total;
        });
    }
    @action addSelectionToMIM(selection){
        runInAction(()=>{
            this.selection=selection;
        });
    }
    @action clearSelection(){
        runInAction(()=>{
            this.selection=[];
            this.itemType3= {};
            this.menuType3= {};
                });
    }


    @action async addToCart(product){
        runInAction(() => {
            //console.log("push to cart");
            this.cart.push(product);
        });
    }
    @action async updateCart(index, product){
        runInAction(() => {
            let old= this.cart;
            old[index]=product;
            this.cart=old;
        });
    }

    @action async clearCart(){
        runInAction(() => {
            this.cart=[];
        });
    }

    @action async removeFromCart(index){
        runInAction(()=>{
            this.cart.splice(index,1);
        });}

    @action async postFastOrder(totalAmount, orderNote, confTable, cart){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `mutation {${POST_QR_ORDER(parseInt(accountId), totalAmount, orderNote, 0, 54, 8889, cart)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => this.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on posting order!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("is order post", data);
            runInAction(() => this.enqueueSnackbar({
                message: 'Order Successfully Posted',
                options: {
                    variant: 'success',
                },
            }));

            this.loading=false;
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => this.enqueueSnackbar({
                message: 'OOPS. Something went wrong on posting order!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action enqueueSnackbar(note) {
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note,
        });
    }

    @action removeSnackbar(key) {
        this.notifications = this.notifications.filter(notification => notification.key !== key);
    }
}

export default new LayoutStore();
