import React,{Component} from "react";
import {inject, observer} from "mobx-react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {Business} from "@material-ui/icons";
import Image from "material-ui-image";

@inject("RegistrationStore","LanguageStore")
@observer
class CreateBrand extends Component{
    state = {
        name:"",
        banner: "https://dummyimage.com/396x198/fff/aaa",
    };

    validateForm() {
        return this.state.name.length > 3 && this.state.banner.length > 0;
    }
    render() {
        const {classes}=this.props;
        return(
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Business/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create Brand
                    </Typography>
                    <Typography component="p" variant="body1" color={"secondary"}>
                        *Create brand or skip this step to chose from already existing brands
                    </Typography>
                    <form className={classes.form} onSubmit={(event)=>{
                        event.preventDefault();
                        this.props.RegistrationStore.createBrand(this.state.name, this.state.banner).then(r=> this.setState({name:""}));

                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            name="name"
                            autoComplete="name"
                            value={this.state.name}
                            onChange={e => this.setState({name: e.target.value})}
                            autoFocus
                        />
                        <Image src={this.state.banner} aspectRatio={2/1}/>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!this.validateForm()}
                        >
                            Create
                        </Button>
                    </form>
                </div>
            </Container>
        )
    }
}
const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

export default withStyles(useStyles, { withTheme: true })(CreateBrand);
