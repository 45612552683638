import {observable, action, runInAction, toJS} from 'mobx';
import AsyncStorage from "@callstack/async-storage";
import {API_BASE} from '../constants';
import axios from 'axios';
import {FORGET_PASSWORD, REGISTER, UPDATE_PASSWORD} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";
class AuthStore{
    @observable token = null;
    @observable user_id = null;
    @observable customer_id = null;
    @observable account_id = null;
    @observable user_name = null;
    @observable user_role = "";
    @observable errors = [];
    @observable message="";
    @observable loading = false;
    @observable user_provider = null;
    @observable control_email = { email: null}
    @observable activeUser = {id:null,email:null,name:null};


    @observable all_props = null;

    @action  async _handleSubmit  (email,password,accountNo) {
        try {
            this.loading = true;
            const {data} =  await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`
                        mutation {
                          signIn(accountNo:${accountNo} email: "${email}", password: "${password}") {
                            token
                            user {
                              id
                              name
                              email
                              user_role{id role{id name display_name}}
                            }
                          }
                        }
                    `
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(()=>{
                this.loading = false;
                //alert(data.data.signIn.token)
                console.log(data.data.signIn.user.user_role?.role?.name);
                this.saveToken(data.data.signIn.token,data.data.signIn.user.id,data.data.signIn.user.name,data.data.signIn.user.email,accountNo, data.data.signIn.user.user_role?.role?.name);
            })
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action async saveToken(token,user_id,user_name,email, accountNo, userRole){
        try {
            await  AsyncStorage.setItem('token',token);
            await  AsyncStorage.setItem('user_name',user_name);
            await  AsyncStorage.setItem('user_email',email);
            await  AsyncStorage.setItem('accountNo',accountNo.toString());
            await  AsyncStorage.setItem('user_id',user_id.toString());
            await  AsyncStorage.setItem('user_role',userRole.toString());
            this.setupAuth();

        }catch(e){
            console.log("Hata1");
            console.log(e)
        }
    }

    @action async removeToken(){
        try {
            await  AsyncStorage.removeItem('token');
            await  AsyncStorage.removeItem('accountNo');
            localStorage.clear();
            this.token = null;
            this.user_id = null;
            this.user_name = null;
            this.customer_id = null;
            await this.setupAuth();

        }catch(e){
            console.log(e)
        }
    }

    @action async setupAuth(){
        await this.getToken();
    }

    @action async getToken(){
        try {
            const token = await AsyncStorage.getItem('token');
            const user_id = await AsyncStorage.getItem('user_id');
            const user_name = await AsyncStorage.getItem('user_name');
            const user_role = await AsyncStorage.getItem('user_role');
            const account_id = await AsyncStorage.getItem('accountNo');
            //console.log("history", toJS(this.all_props.history), toJS(this.all_props.match));
            if (this.all_props.history.location.pathname!=="/forgot-password" && !(this.all_props.match.path==="/update-password/:email" || this.all_props.history.location.pathname==="/update-password" || this.all_props.history.location.pathname==="/update-password/" ) && !token) {
                this.all_props.history.push({
                    pathname:'/login'
                })
                return false;
            }
            this.token = token;
            this.user_id = user_id;
            this.user_role = user_role;
            this.account_id = account_id;
            this.tokenControl()
        }catch (e) {
            console.log(e)
        }
    }

    @action async tokenControl(){
        try{
            const token = await AsyncStorage.getItem('token');
            const {data} = await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:'post',
                data:{
                    query:`
                    {
                      activeUser{
                        id
                        name
                        email
                        user_role{role{id name display_name}}
                      }
                    }
					          `
                }
            });

            if(!token){
                return false;
            }
            if (data.errors) {
                //Alert.alert("YEMEKARENA", "Uzun zamandır giriş yapılmamış, tekrar giriş yapmanız gerek.");
                this.removeToken();
                return false;
            }
            runInAction(()=>{
                this.activeUser =  data.data.activeUser;

                if (this.all_props.history.location.pathname!=='/login'){
                    this.all_props.history.push({
                        pathname:this.all_props.history.location.pathname
                    })
                }else {
                    this.all_props.history.push({
                        pathname:'/'
                    })
                }
                /*this.all_props.history.push({
                    pathname:'/'
                })*/
                //NavigationService.navigate('Choose');//App
                //Giriş yapıldı admine yönlendir
            });
            if(!token)return false; else return true;

        }catch(e){
            alert("I "+e);
            return false;
        }
    }

    @action async emailControl(email){
        try{
            this.loading = true;
            runInAction(()=>{this.control_email = { email:null}})
            const {data} = await axios({
                url:API_BASE,
                method:'post',
                data:{
                    query:`{
                      email_control(email:"${email}"){
                        email
                      }
                    }`
                }
            })

            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.loading = false;
                return false;
            }

            runInAction(()=>{
                this.loading = false;
                this.control_email.email = data.data.email_control ?  data.data.email_control.email : null;
            })
        }catch (e) {
            //Alert.alert("YEMEKARENA", "Sorun oluştu 004");
            this.loading = false;
            return false;
        }
    }

    @action async register(name, email, password){
        try {
            this.loading = true;
            const {data} =  await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`
                        mutation {${REGISTER(name, email, password)}}
                    `
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                alert("Error3!!!");
                this.loading = false;
                return false;
            }

            runInAction(()=>{
                this.loading = false;
                alert(`Name: ${name},
                       Email: ${email},
                       Password: ${password}`);
            })



        }catch (e) {
            this.loading = false;
            return false;
        }
    }
    @action async forgotPassword(email){
        try {
            this.loading = true;
            const {data} =  await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`mutation {${FORGET_PASSWORD(email)}}`
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(()=>{
                this.loading = false;
                console.log("data: ",data);
                //alert(`e-mail sent`);
                this.message=data.data.forgetPass.message;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: this.message,
                    options: {
                        variant: 'success',
                    },
                }));
            });
            this.loading = false;
        }catch (e) {
            this.loading = false;
            return false;
        }
    }
    @action async updatePassword(email, token, password){
        try {
            this.loading = true;
            const {data} =  await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`mutation {${UPDATE_PASSWORD(email, token, password)}}`
                }
            });
            if(data.errors){
                alert("Error3!!!");
                this.loading = false;
                return false;
            }
            runInAction(()=> {
                this.message = data.data.forgetPassUpdate.message;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: this.message,
                    options: {
                        variant: 'success',
                    },
                }));
            });
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

}

export default  new AuthStore()
