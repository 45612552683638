import {action, observable, runInAction} from "mobx";
import axios from "axios";
import {API_BASE} from "../constants";
import {
    ASSIGN_USER_ACCOUNT,
    ASSIGN_USER_ROLE,
    CREATE_ACCOUNT,
    CREATE_BRAND,
    REGISTER
} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";
import AsyncStorage from "@callstack/async-storage";

class RegistrationStore {
    @observable loading = false;
    @observable user=null;
    @observable brand=null;
    @observable account=null;
    @observable isRoleAssigned=false;
    @observable isAccountAssigned=false;
    @observable step=0;

    constructor(){
        this.getRegistrationFromLS().then(r =>console.log("LS:", this.step, this.user, this.brand, this.account, this.isRoleAssigned));
    }
    @action
    async getRegistrationFromLS() {
        try {
            this.step= (await AsyncStorage.getItem('reg_step'))?(parseInt(await AsyncStorage.getItem('reg_step'))):(0);
            this.user = JSON.parse(await AsyncStorage.getItem('reg_user'));
            this.brand = JSON.parse(await AsyncStorage.getItem('reg_brand'));
            this.account = JSON.parse(await AsyncStorage.getItem('reg_account'));
            this.isRoleAssigned =Boolean(parseInt(await AsyncStorage.getItem('reg_role_assigned')));
            this.isAccountAssigned =Boolean(parseInt(await AsyncStorage.getItem('reg_account_assigned')));
        } catch (e) {
            console.log("error: ", e);
            this.loading = false;
            return false;
        }
    }
    @action async register(name, email, password){
        try {
            this.loading = true;
            const {data} =  await axios({
                url:`${API_BASE}`,
                method:'post',
                data:{
                    query:`
                        mutation {${REGISTER(name, email, password)}}
                    `
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                this.loading = false;
                return false;
            }
            runInAction(async () => {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'Created user successfully',
                    options: {
                        variant: 'success',
                    },
                }));
                this.user = data.data.createUser.user;
                await AsyncStorage.setItem('reg_user_id', this.user.id);
                await AsyncStorage.setItem('reg_user', JSON.stringify(this.user));
                const newStep = this.step + 1;
                await this.setStep(newStep);
                this.loading = false;
            });
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action async assignRole(role){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} =  await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:'post',
                data:{
                    query:`mutation {${ASSIGN_USER_ROLE(this.user.id, role)}}`
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "assigned role to user successfully",
                    options: {
                        variant: 'success',
                    },
                }));
                this.isRoleAssigned = true;
                await AsyncStorage.setItem('reg_role_assigned', 1);
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action async assignAccount(source, user, account, temporal_pass, main_color, head_color){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} =  await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:'post',
                data:{
                    query:`mutation {${ASSIGN_USER_ACCOUNT(source, account, user, temporal_pass, main_color, head_color)}}`
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "assigned account to user successfully",
                    options: {
                        variant: 'success',
                    },
                }));
                this.isAccountAssigned = true;
                await AsyncStorage.setItem('reg_account_assigned', 1);
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action async createBrand(name, banner){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} =  await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:'post',
                data:{
                    query:`mutation {${CREATE_BRAND(name, banner)}}`
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                this.brand = data.data.add_brand;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "Created brand successfully",
                    options: {
                        variant: 'success',
                    },
                }));
                await AsyncStorage.setItem('reg_brand_id', this.brand.id);
                await AsyncStorage.setItem('reg_brand', JSON.stringify(this.brand));
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        }catch (e) {
            this.loading = false;
            return false;
        }
    }
    @action async createAccount(title, active, accept, brand, type, center, delivery, takeaway, eatin, price_group, email, kep, tax, gsm){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} =  await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:'post',
                data:{
                    query:`mutation {${CREATE_ACCOUNT(title, active, accept, brand, type, center, delivery, takeaway, eatin, price_group, email, kep, tax, gsm)}}`
                }
            });
            if(data.errors){
                //Alert.alert("YEMEKARENA", "Sorun oluştu 003");
                this.errors=data.errors;
                this.loading = false;
                return false;
            }

            runInAction(async () => {
                this.loading = false;
                console.log("data: ", data);
                this.account = data.data.add_account;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "Created account successfully",
                    options: {
                        variant: 'success',
                    },
                }));
                await AsyncStorage.setItem('reg_account_id', this.account.id);
                await AsyncStorage.setItem('reg_account', JSON.stringify(this.account));
                const newStep = this.step + 1;
                await this.setStep(newStep);
            });
            this.loading = false;
        }catch (e) {
            this.loading = false;
            return false;
        }
    }

    @action async clearRegistration(){
        try {
            this.loading=true;
            await this.setStep(0);
            await  AsyncStorage.removeItem('reg_step');
            await  AsyncStorage.removeItem('reg_user_id');
            await  AsyncStorage.removeItem('reg_user');
            await  AsyncStorage.removeItem('reg_role_assigned');
            await  AsyncStorage.removeItem('reg_brand_id');
            await  AsyncStorage.removeItem('reg_brand');
            await  AsyncStorage.removeItem('reg_account_id');
            await  AsyncStorage.removeItem('reg_account');
            await AsyncStorage.removeItem('reg_account_assigned');

            this.loading=false;
        }catch (e) {
            this.loading=false;
            console.log("error: ",e);
            return false;
        }
    }
    @action async setStep(step){
        try {
            this.loading=true;
            this.step=step;
            await  AsyncStorage.setItem('reg_step',this.step);
            this.loading=false;
        }catch (e) {
            this.loading=false;
            console.log("error: ",e);
        }
    }
}
export default new RegistrationStore();
