import React, {Component} from "react";
import {fade, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";
import {useSpring, animated} from 'react-spring/web.cjs';
import Button from "@material-ui/core/Button";
import {AddCircle, ArrowDownward, ArrowUpward, Sort} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import {withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import AsyncStorage from "@callstack/async-storage/lib";
import {IconButton} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            <path
                d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z"/>
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: {opacity: 0, transform: 'translate3d(20px,0,0)'},
        to: {opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)`},
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};
const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent}/>);

@inject('ProductGroupStore', 'VariablesStore', 'LanguageStore', 'AddProductStore','AccountStore')
@observer
 class ControlledTreeView extends Component{

    state={
        accountId:null
    }
    async componentDidMount() {
        await this.props.ProductGroupStore.getProductGroups(-1);
        this.setState({accountId:await AsyncStorage.getItem('accountNo')});
    }
    async moveUpward(id, sort) {
        console.log("upward", id, sort);
        await this.props.AddProductStore.sortProduct(id, sort - 1);
        await this.props.ProductGroupStore.getProductGroups(-1);
    }
    async moveDownward(id, sort) {
        console.log("downward", id, sort);
        await this.props.AddProductStore.sortProduct(id, sort + 1);
        await this.props.ProductGroupStore.getProductGroups(-1);
    }
    isCenter(){
       return  this.state.accountId==this.props.AccountStore.account.account_center
    }
    render() {
        //console.log(this.props);
         const {classes}=this.props;
         //console.log(toJS(this.props.AccountStore.account));
         //console.log(toJS(this.props.ProductGroupStore.productGroups));
         const renderTree = (productGroups) => (
             Array.isArray(productGroups) ? productGroups.map((productGroup, index) => (
                 <StyledTreeItem key={productGroup.id} nodeId={`${index+1}`} className={(productGroup.group_sort===-1)?("lineThrough"):("")}
                                 label={`${productGroup.product_group_main.main_group_name}(${productGroup.products.length})`}>
                     {Array.isArray(productGroup.products) ? productGroup.products.map((product, i) => (
                         <div key={product.id} style={{backgroundColor:(i%2===0)?grey[200]:""}} className={classes.inline}><StyledTreeItem key={`${(((index+1)*100)+i+1)}`}  nodeId={`${(((index+1)*100)+i+1)}`} label={`${product.product_name} ${product.product_price? `(${parseFloat(product.product_price?.price_value).toFixed(2)},${parseFloat(product.product_price?.price_takeaway).toFixed(2)},${parseFloat(product.product_price?.price_eatin).toFixed(2)})`:" "}`}
                                         onClick={() => {
                                             this.props.history.push(`/definitions/menu-management/${product.id}`)
                                         }}/>
                             <div style={{whiteSpace:"nowrap"}}>
                                 {(this.isCenter()) && ((!product.product_sort)?(<IconButton onClick={()=>this.moveDownward(product.id, product.product_sort)} size={"small"}><Sort/></IconButton>):(<>
                                     <Button>
                                         {!product.product_sort ? "-":product.product_sort}
                                     </Button>
                                     <IconButton disabled={(product.product_sort<=1)} onClick={()=>this.moveUpward(product.id, product.product_sort)} size={"small"}><ArrowUpward/></IconButton>
                                     <IconButton  onClick={()=>this.moveDownward(product.id, product.product_sort)}size={"small"}><ArrowDownward/></IconButton>
                                 </>))}
                             </div>
                         </div>
                     )) : null}
                     <Button startIcon={<AddCircle/>}
                             onClick={() =>{
                                 this.props.VariablesStore.setGroup(productGroup.product_group_main.id);
                                 this.props.history.push(`/definitions/menu-management/import`)
                             }}>{this.props.LanguageStore.language.ADD_PRODUCT}</Button>
                     {(this.isCenter())&&(<Button startIcon={<AddCircle/>}
                                   onClick={() =>{
                                       this.props.VariablesStore.setGroup(productGroup.product_group_main.id);
                                       this.props.history.push(`/definitions/menu-management/add`)
                                   }}>Add Product Manually</Button>)}

                 </StyledTreeItem>
             )) : null

         );
         return(<>
                 <Backdrop className={classes.backdrop} open={this.props.ProductGroupStore.loading}>
                     <CircularProgress color="inherit"/>
                 </Backdrop>
             <TreeView
                 className={classes.root}
                 defaultCollapseIcon={<MinusSquare/>}
                 defaultExpandIcon={<PlusSquare/>}
                 defaultEndIcon={<CloseSquare/>}
             >
                 {renderTree(toJS(this.props.ProductGroupStore.productGroups))}
             </TreeView>
             </>
         );
     }
}
const useStyles =(theme)=> ({
    root: {
        minHeight: "100vh",
        flexGrow: 1,
        maxWidth: 400,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    button: {
        margin: theme.spacing(1),
    },
    inline:{
        display:"flex",
        justifyContent:"space-between"
    }
});
export default withRouter( withStyles(useStyles, { withTheme: true })(ControlledTreeView));
