import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {ArrowDownward, ArrowUpward, Edit, Folder, Visibility, VisibilityOff} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import AsyncStorage from "@callstack/async-storage/lib";

@inject('ProductMGroupStore', 'ProductGroupStore', 'AddProductGroupStore', 'LanguageStore', 'TicketStore','AccountStore')
@observer
class GroupManagement extends Component {



    state = {
        mProductGroup: null,
        group_sort: false,
        editIcon: true,
        edit: {id: null, modal: false, mGroup: null, activeGroup: -1, sort: null},
        group_active: -1,
        suggestThis:"",
        accountId:null,
    }
    async componentDidMount() {
        this.setState({accountId: await AsyncStorage.getItem('accountNo')});
        await this.props.ProductMGroupStore.getProductMGroups();
        await this.props.ProductGroupStore.getProductGroups(-1);
        await this.props.ProductGroupStore.getActiveGroups();
    }

    isCenter(){
        return  this.state.accountId==this.props.AccountStore.account.account_center;
    }
    handleMoveUp(id, sort, mGroup,active) {
            this.props.ProductGroupStore.updateProductGroup(id, mGroup, sort + 1, active);
    }

    handleMoveDown(id, sort, mGroup,active) {
        if (sort>0){
            this.props.ProductGroupStore.updateProductGroup(id, mGroup, sort - 1, active);
        }
    }

    handleGroupEdit(id, sort, mGroup, activeGroup) {
        this.setState({edit: {id: id, modal: true, mGroup: mGroup, activeGroup: activeGroup, sort: sort}});
    }

    handleCloseModal() {
        this.setState({edit: {id: null, modal: false, mGroup: null, activeGroup: -1, sort: null}});
    }

    handleModalConfirm() {
        this.props.ProductGroupStore.updateProductGroup(this.state.edit.id, this.state.edit.mGroup, this.state.edit.sort, this.state.edit.activeGroup).then(r => this.setState({
            edit: {
                id: null,
                modal: false,
                mGroup: null,
                activeGroup: -1,
                sort: null
            }
        }));
    }

    suggestTicket(value){
        console.log("please create ticket", value);
        this.setState({mProductGroup: null});

        this.props.TicketStore.suggestedTicket(`"${value.inputValue}"`, "Yeni grup ekleme isteği", 386);
    }

    handleDisable(id, sort, mGroup, active) {
        if (sort === -1) {
            this.props.ProductGroupStore.updateProductGroup(id, mGroup, 0, active);
        } else {
            this.props.ProductGroupStore.updateProductGroup(id, mGroup, -1, active);
        }
    }

    handleSubmit = () => {
        this.props.AddProductGroupStore.addProductGroup(parseInt(this.state.mProductGroup.id), Number(this.state.group_sort), Number(this.state.group_active));
        //this.props.ProductGroupStore.getProductGroups();
        this.setState({mProductGroup: null});
    }

    render() {
        const {classes} = this.props;
        const productGroups = toJS(this.props.ProductGroupStore.productGroups);
       const filter = createFilterOptions();
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Backdrop className={classes.backdrop} open={this.props.ProductGroupStore.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Paper className={classes.paper}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Autocomplete
                                id="tags-outlined"
                                options={toJS(this.props.ProductMGroupStore.productMGroups)}
                                getOptionLabel={option => option.main_group_name}
                                onChange={(event, newValue) => {
                                    if ( newValue?.inputValue && typeof newValue.inputValue=== 'string') {
                                        //console.log("add now", newValue);
                                        this.setState({suggestThis: newValue});
                                        this.suggestTicket(newValue);
                                    }else {
                                        this.setState({mProductGroup: newValue});
                                    }
                                }}

                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    // Suggest the creation of a new value
                                    if (params.inputValue !== "") {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            main_group_name: `Ekleme isteği bildir "${params.inputValue}"`
                                        });
                                    }
                                    return filtered;
                                }}
                                freeSolo
                                clearOnBlur
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={this.props.LanguageStore.language.SELECT_PRODUCT_GROUP}
                                        placeholder={this.props.LanguageStore.language.SELECT_PRODUCT_GROUP}
                                    />
                                )}
                            />
                        </FormControl>
                        {(this.props.ProductGroupStore.activeGroups.length > 0 && false) && (
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.ACTIVE_GROUP}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.group_active}
                                    onChange={(e) => {
                                        this.setState({group_active: e.target.value})
                                    }}
                                    label={this.props.LanguageStore.language.ACTIVE_GROUP}
                                >
                                    <MenuItem value={-1}>
                                        <em>None</em>
                                    </MenuItem>
                                    {this.props.ProductGroupStore.activeGroups.map((activeG) => (
                                        <MenuItem key={activeG.id} value={activeG.id}>{activeG.active_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl variant="outlined" className={classes.formControl}>

                            <Button variant="contained" color="primary" onClick={() => {
                                this.handleSubmit()
                            }}
                                    disabled={!this.state.mProductGroup}>
                                {this.props.LanguageStore.language.ADD}
                            </Button>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Backdrop className={classes.backdrop} open={this.props.ProductGroupStore.loading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <div className={classes.demo}>
                        <List dense={false}>
                            <FormControlLabel
                                value="edit"
                                control={<Switch color="secondary" onChange={(e) => {
                                    this.setState({editIcon: !this.state.editIcon})
                                }}/>}
                                label={this.props.LanguageStore.language.EDIT}
                                labelPlacement="start"/>
                            {productGroups.map((productGroup, index) => (
                                <ListItem key={productGroup.id}>
                                    <ListItemAvatar>
                                        {(productGroup.product_group_main.img_url) ? (
                                            <Avatar src={productGroup.product_group_main.img_url}/>
                                        ) : (
                                            (productGroup.group_sort !== -1) ? (<Folder color={'primary'}/>) : (
                                                <Folder color={'disabled'}/>)
                                        )}
                                    </ListItemAvatar>
                                    <ListItemText className={classes.listPrimaryText}
                                        primary={productGroup.product_group_main.main_group_name}
                                        secondary={(productGroup.product_group_main.account_group_name) ? (productGroup.product_group_main.account_group_name.except_g_data) : (null)}
                                    />
                                    <ListItemText className={classes.listSecondaryText}
                                        primary={`${"Sıra"}: ${productGroup.group_sort}`}
                                        secondary={`${this.props.LanguageStore.language.PRODUCT_COUNT}: ${productGroup.products.length}`}
                                    />{this.isCenter() && (
                                    (this.state.editIcon) ? (<ListItemSecondaryAction>
                                        {/*<ListItemText primary={productGroup.group_sort}/>*/}
                                        {(productGroup.group_sort !== -1) && (<>
                                            {(productGroup.group_sort>0) &&
                                            <IconButton edge="end" onClick={() => {
                                                this.handleMoveDown(productGroup.id, productGroup.group_sort, productGroup.product_group_main.id, (productGroup.group_active) ? productGroup.group_active.id : -1)
                                            }} aria-label="sort-down">
                                                <ArrowUpward/>
                                            </IconButton>}
                                            {!(productGroup.group_sort>0) &&
                                            <IconButton edge="end" disabled={true} onClick={() => {
                                                this.handleMoveDown(productGroup.id, productGroup.group_sort, productGroup.product_group_main.id, (productGroup.group_active) ? productGroup.group_active.id : -1)
                                            }} aria-label="sort-down">
                                                <ArrowUpward/>
                                            </IconButton>}
                                            {( index!==0) && <IconButton edge="end" onClick={() => {
                                                this.handleMoveUp(productGroup.id, productGroup.group_sort, productGroup.product_group_main.id, (productGroup.group_active) ? productGroup.group_active.id : -1)
                                            }} aria-label="sort-up">
                                                <ArrowDownward/>
                                            </IconButton>}
                                        </>)}
                                        <IconButton edge="end" onClick={() => {
                                            this.handleDisable(productGroup.id, productGroup.group_sort, productGroup.product_group_main.id, (productGroup.group_active) ? productGroup.group_active.id : -1)
                                        }} aria-label="delete">
                                            {(productGroup.group_sort !== -1) ? (<Visibility color={'secondary'}/>) : (
                                                <VisibilityOff/>)}
                                        </IconButton>
                                    </ListItemSecondaryAction>) : (
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={() => this.handleGroupEdit(productGroup.id, productGroup.group_sort, productGroup.product_group_main.id, (productGroup.group_active) ? productGroup.group_active.id : -1)}>
                                                <Edit/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )
                                )}
                                </ListItem>
                            ))}
                        </List>
                        {/*Active Group update/edit modal*/}
                        <Dialog open={this.state.edit.modal} onClose={() => this.handleCloseModal} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.EDIT_PRODUCT_GROUP}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.props.LanguageStore.language.EDIT_ACTIVE_GROUP_DESC}
                                </DialogContentText>
                                {(this.props.ProductGroupStore.activeGroups.length > 0) && (
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.ACTIVE_GROUP}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.edit.activeGroup}
                                            onChange={(e) =>
                                                this.setState({
                                                    edit: {
                                                        id: this.state.edit.id,
                                                        modal: this.state.edit.modal,
                                                        mGroup: this.state.edit.mGroup,
                                                        activeGroup: e.target.value,
                                                        sort: this.state.edit.sort
                                                    }
                                                })}
                                            label={this.props.LanguageStore.language.ACTIVE_GROUP}
                                        >
                                            <MenuItem value={-1}>
                                                <em>None</em>
                                            </MenuItem>
                                            {this.props.ProductGroupStore.activeGroups.map((activeG) => (
                                                <MenuItem key={activeG.id}
                                                          value={activeG.id}>{activeG.active_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    this.handleCloseModal()
                                }} color="primary">
                                    {this.props.LanguageStore.language.CANCEL}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.handleModalConfirm()
                                    }} color="primary">
                                    {this.props.LanguageStore.language.SAVE}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});

export default withStyles(useStyles, {withTheme: true})(GroupManagement);
