import React, {Component} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {inject, observer} from "mobx-react";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/core/styles";

@inject('TicketStore', 'LanguageStore')
@observer
class TicketDialog extends Component {

    state = {
        subjectId: "",
        subjects: [
            {id: 386, title: "WEB YemekArena Geliştirme"},
            {id: 405, title: "Teknik destek bekleme"},
        ],
        email: "",
        subject: "",
        message: ""
    };

    componentDidMount() {
        this.setState({
            email: this.props.TicketStore.email,
            subjectId:this.props.TicketStore.subjectId,
            subject: this.props.TicketStore.subject,
            message: this.props.TicketStore.message,
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.email!==this.props.TicketStore.email){
            this.setState({
                email: this.props.TicketStore.email,
                subjectId:this.props.TicketStore.subjectId,
                subject: this.props.TicketStore.subject,
                message: this.props.TicketStore.message,
            });
        }
    }

    handleSubmit() {
        this.props.TicketStore.insertActivity(this.state.message, this.state.email, this.state.subject, this.state.subjectId).then(r => this.props.TicketStore.toggleDialog());
    }

    render() {
        const {classes} = this.props;
        return (
            <Dialog open={this.props.TicketStore.ticketDialog} onClose={() => this.props.TicketStore.toggleDialog()}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.SUPPORT_TICKET}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText>*/}
                    <FormControl variant={"outlined"} margin={"dense"} className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            margin={"dense"}
                            value={this.state.subjectId}
                            onChange={(e) => this.setState({subjectId: e.target.value})}
                        >
                            <MenuItem value={""}>None</MenuItem>
                            {this.state.subjects.map((subject, index) => (
                                <MenuItem key={index} value={subject.id}>{subject.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant={"outlined"} className={classes.formControl}>
                        <TextField
                            autoFocus
                            id="email"
                            margin={"dense"}
                            label="Email Address"
                            type="email"
                            value={this.state.email}
                            onChange={(e)=>this.setState({email:e.target.value})}
                            variant={"outlined"}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl variant={"outlined"} className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="subject"
                            label="Subject"
                            type="text"
                            value={this.state.subject}
                            onChange={(e)=>this.setState({subject:e.target.value})}
                            variant={"outlined"}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl variant={"outlined"} className={classes.formControl}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            multiline
                            value={this.state.message}
                            rows={4}
                            onChange={(e)=>this.setState({message:e.target.value})}
                            placeholder="Enter your message here..."
                            variant="outlined"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.TicketStore.toggleDialog()} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button disabled={(this.state.message==="" || this.state.email==="" || this.state.subjectId==="")} onClick={() => this.handleSubmit()} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>);
    }
}

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

export default withStyles(useStyles, {withTheme: true})(TicketDialog);
