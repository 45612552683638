import {action, observable, runInAction} from "mobx";
import LayoutStore from "./LayoutStore";
import AsyncStorage from "@callstack/async-storage/lib";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_ALL_ORDERS, GET_CUMULATIVE_CREDIT} from "../graphql/Queries";

class AdminStore {
    @observable loading=false;
    @observable orders=[];

    @action
    async getAllOrders(start, end) {
        try {
            this.loading = true;
            const token = await AsyncStorage.getItem('token');
            if (!token){
                this.loading = false;
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_ALL_ORDERS(start, end)}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    LayoutStore.enqueueSnackbar({
                        message: "OOPS. Something went wrong on: Admin at getAllOrders!",
                        options: {
                            variant: 'error',
                        },
                    });
                });
                return false;
            }
            this.orders = data.data.all_orders_by_date;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: Admin at getAllOrders!",
                    options: {
                        variant: 'error',
                    },
                });
            });
        }
    }
}
export default new AdminStore();
