import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_QUESTION_TYPES, GET_SURVEY, GET_SURVEY_GROUPS, GET_SURVEY_QUESTIONS} from "../graphql/Queries";
import LayoutStore from "./LayoutStore";
import {
    ADD_QUESTION,
    ADD_QUESTION_ITEM,
    ADD_SURVEY, ADD_SURVEY_GROUP, DELETE_QUESTION,
    DELETE_SURVEY,
    UPDATE_QUESTION,
    UPDATE_SURVEY
} from "../graphql/Mutations";

class SurveyStore{
    @observable groups=[];
    @observable loading=false;
    @observable survey=[];
    @observable questionTypes=[];
    @observable questions=[];

    @action async getGroups(){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_SURVEY_GROUPS(parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.groups=data.data.survey_groups;
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async getSurvey(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_SURVEY(parseInt(id))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.survey=data.data.survey;
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async getQuestionTypes(){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_QUESTION_TYPES}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.questionTypes=data.data.survey_q_types;
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async getSurveyQuestions(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_SURVEY_QUESTIONS(id)}}`
                }
            });
            if (data.errors){
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading=false;
            this.questions=data.data.survey_questions;
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async addSurvey(brand,group, title, description, active, start, end){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_SURVEY(brand, parseInt(accountId), group, title, description, active, start, end)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);
            await this.getGroups();
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Survey Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateSurvey(id, group, title, description, active, start, end){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_SURVEY(id, group, title, description, active, start, end)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);
            await this.getGroups();
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Survey Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addQuestion(type,label, title, placeholder, error, description, min, max, surveyID){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_QUESTION(parseInt(accountId), type,label, title, placeholder, error, description, min, max, surveyID)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Question Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateQuestion(id, type,label, title, placeholder, error, description, min, max, surveyID){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${UPDATE_QUESTION(id, parseInt(accountId), type,label, title, placeholder, error, description, min, max, surveyID)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            await this.getSurveyQuestions(surveyID);
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Question Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async deleteQuestion(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${DELETE_QUESTION(id, parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;

            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Question Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async addOption(question, value, point){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_QUESTION_ITEM(parseInt(accountId), question, value, point)}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Question Option Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async deleteSurvey(id){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${DELETE_SURVEY(id, parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);
            await this.getGroups();
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Survey Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async addGroup(name, depend){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                headers: {
                    'Authorization': token,
                },
                data:{
                    query: `mutation {${ADD_SURVEY_GROUP(name, depend, parseInt(accountId))}}`
                }
            });
            if (data.errors){
                //alert("OOPS. Something went wrong");
                this.loading=false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            //console.log("product groups", data);
            await this.getGroups();
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Survey Group Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        }catch (e) {
            console.log(e);
            //alert("OOPS. Something went wrong!");
            this.loading=false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }



}

export default new SurveyStore();
