import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import {IconButton} from "@material-ui/core";
import {Language} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import French from "../../assets/flags/france.svg";
import Turkish from "../../assets/flags/turkey.svg";
import German from "../../assets/flags/germany.svg";
import English from "../../assets/flags/united-kingdom.svg";
import {inject, observer} from "mobx-react";

@inject("LanguageStore")
@observer
class LanguageSelector extends Component{
    state={
        lang:"tr",
        svg:Turkish,
        name:"Turkish",
        id:"language",
        open:false,
        anchorEl:null,
    };
    async handleChange(lang, svg, name) {
        this.setState({lang: lang, svg: svg, name: name, open: false});
        console.log("new lan is", this.state.lang);
        await this.props.LanguageStore.setLanguageManuel(lang);
    }
    handleClick(e){
        console.log("clicked");
        this.setState({anchorEl:e.currentTarget, open:true});
    }
    handleClose(){
        this.setState({anchorEl:null, open:false});
    }
    render() {
        const {classes}=this.props;
        return(
            <div className={classes.langControl}>
                <IconButton aria-describedby={this.state.id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e)}>
                    <Avatar alt={this.state.name} src={this.state.svg}><Language /></Avatar>
                </IconButton>
                <Popover
                    id={this.state.id}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={()=>this.handleClose()}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <ListItem button onClick={()=>this.handleChange("tr", Turkish, "Turkish")}>
                        <ListItemAvatar>
                            <Avatar alt={"Turkish"} src={Turkish}><Language /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={"Turkish"}/>
                    </ListItem>
                    <ListItem button onClick={()=>this.handleChange("en", English, "English")}>
                        <ListItemAvatar>
                            <Avatar alt={"English"} src={English}><Language /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={"English"}/>
                    </ListItem>
                    <ListItem button onClick={()=>this.handleChange("fr", French, "French")}>
                        <ListItemAvatar>
                            <Avatar alt={"French"} src={French}><Language /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={"French"}/>
                    </ListItem>
                    <ListItem button onClick={()=>this.handleChange("de", German, "German")}>
                        <ListItemAvatar>
                            <Avatar alt={"Deutsch"} src={German}><Language /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={"Deutsch"}/>
                    </ListItem>
                </Popover>
            </div>
            );
    }
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    langControl: {
        margin: theme.spacing(1),
        zIndex: theme.zIndex.drawer + 1,
        position:"fixed",
        right:0,
        bottom:0
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

export default withStyles(useStyles, {withTheme: true})(LanguageSelector);
