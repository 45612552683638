import React, {Component} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {Add, ArrowDownward, ArrowUpward, Clear, Delete, Done, Sort} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Typography} from "@material-ui/core";
import {toJS} from "mobx";

@inject('AddProductStore', 'LayoutStore','LanguageStore')
@observer
class MenuChoicesTable extends Component {
    state = {
        addProductModal: false,
        editPrice: false,
        price: null,
        editRowId: null,
        type: 1,
        mainProductId:null,
        productId:null,
        menuId: null,
    }

    handleDoubleClick(id, price, menuId, mainProductId, productId) {
        this.setState({editPrice: true, editRowId: id, price: price, mainProductId:mainProductId, productId:productId, menuId: menuId});
    }

    handleSubmit() {
        console.log(this.state);
        this.props.AddProductStore.updateProductMenuItem(this.state.editRowId, this.state.menuId, this.state.type, this.state.mainProductId, this.state.productId, this.state.price).then(r => this.setState({
            editPrice: false,
            price: null,
            editRowId: null,
            menuId: null,
            productId:null,
            mainProductId:null
        }));
    }

    async removeProduct(id) {
        console.log(id);
       await this.props.AddProductStore.deleteProductMenuItem(id, this.props.match.params.id);
        await this.props.AddProductStore.getProduct(parseInt(this.props.match.params.id));

    }

    async moveUpward(id, sort) {
        console.log("upward", id, sort);
        await this.props.AddProductStore.sortProductMenu(id, sort-1);
        await  this.props.AddProductStore.getProduct(this.props.productId);
    }
    async moveDownward(id, sort) {
        console.log("downward", id, sort);
        await this.props.AddProductStore.sortProductMenu(id, sort+1);
        await  this.props.AddProductStore.getProduct(this.props.productId);
    }
    async moveItemUpward(id, sort) {
        console.log("upward", id, sort);
        await this.props.AddProductStore.sortProductMenuItem(id, sort-1);
        await  this.props.AddProductStore.getProduct(this.props.productId);
    }
    async moveItemDownward(id, sort) {
        console.log("downward", id, sort);
        await this.props.AddProductStore.sortProductMenuItem(id, sort+1);
        await  this.props.AddProductStore.getProduct(this.props.productId);
    }

    render() {
        //console.log(this.props);
        const {data, productId, menuType,sort, menuId, classes} = this.props;
        //console.log(toJS(data));
        return (<>
            <TableContainer component={Paper}>
                <Button variant="contained" color="primary" size="small" className={classes.button}
                        startIcon={<Add/>} onClick={() => {
                    this.props.LayoutStore.togglePMIModal(menuId,menuType)
                }}>{this.props.LanguageStore.language.ADD_PRODUCT}
                </Button>
                <Button variant="contained" color="secondary" size="small"
                        className={classes.button}
                        startIcon={<Delete/>} onClick={() => {
                    this.props.LayoutStore.toggleDeletePMModal(menuId, data)
                }}>{this.props.LanguageStore.language.DELETE_PRODUCT_MENU}
                </Button>
                {(!sort)?(<IconButton onClick={()=>this.moveDownward(menuId, sort)} size={"small"}><Sort/></IconButton>):(<>
                    <IconButton disabled={(sort<=1)} onClick={()=>this.moveUpward(menuId, sort)} size={"small"}><ArrowUpward/></IconButton>
                    <IconButton  onClick={()=>this.moveDownward(menuId, sort)}size={"small"}><ArrowDownward/></IconButton>
                </>)}
                <Button>
                    {!sort ? "-":sort}
                </Button>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">{this.props.LanguageStore.language.PRODUCT_NUMBER}</TableCell>
                            <TableCell align="right">{this.props.LanguageStore.language.PRICE}</TableCell>
                            <TableCell align="right">{this.props.LanguageStore.language.ACTION}</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <Button>{!row.item_sort ? "-":row.item_sort}</Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {(!row.item_sort)?(<IconButton onClick={()=>this.moveItemDownward(row.id, row.item_sort)} size={"small"}><Sort/></IconButton>):(<>
                                        <IconButton disabled={(row.item_sort<=1)} onClick={()=>this.moveItemUpward(row.id, row.item_sort)} size={"small"}><ArrowUpward/></IconButton>
                                        <IconButton  onClick={()=>this.moveItemDownward(row.id, row.item_sort)}size={"small"}><ArrowDownward/></IconButton>
                                    </>)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.product.product_name}
                                </TableCell>
                                <TableCell align="right">{row.product.product_number}</TableCell>
                                <TableCell align="right">
                                    {(this.state.editPrice && row.id === this.state.editRowId) ? (
                                        <FormControl fullWidth={true} variant="outlined" style={{width:"fit-content"}} size={'small'} className={classes.priceInput}>
                                            <OutlinedInput
                                                fullWidth={true}
                                                id="outlined-adornment-price"
                                                type={'number'}
                                                value={this.state.price}
                                                onChange={(event) => this.setState({price: event.target.value})}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit price"
                                                            onClick={() => {
                                                                this.handleSubmit()
                                                            }}
                                                            size={"small"}
                                                            color={'primary'}
                                                            edge="end"
                                                        >
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel price edit"
                                                            onClick={() => this.setState({
                                                                editPrice: false,
                                                                price: null,
                                                                editRowId: null,
                                                                menuId: null,
                                                                productId:null,
                                                                mainProductId:null
                                                            })}
                                                            size={"small"}
                                                            color={'secondary'}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </FormControl>
                                    ) : (
                                        <Tooltip title={"Click to edit"}>
                                            <Chip onClick={() => this.handleDoubleClick(row.id, row.item_price, menuId, productId, row.product.id)}
                                                  label={`${parseFloat(row.item_price).toFixed(2)}`}/>
                                        </Tooltip>
                                    )}
                                </TableCell>
                                {(menuType === 3) ? (
                                    <TableCell align="right">
                                        <ButtonGroup size="small" variant={'text'}
                                                     aria-label="outlined primary button group">
                                            <Button color={'primary'} onClick={() => {
                                                this.props.history.push(`/definitions/menu-management/${row.product.id}`)
                                            }}>{this.props.LanguageStore.language.GOTO}</Button>
                                            <Button color={'secondary'} onClick={() => {
                                                this.removeProduct(row.id)
                                            }}>{this.props.LanguageStore.language.REMOVE}</Button>
                                        </ButtonGroup>
                                    </TableCell>
                                ) : (
                                    <TableCell align="right">
                                        <ButtonGroup size="small" variant={'text'}
                                                     aria-label="outlined primary button group">
                                            {/*<Link to={`/menu-management/${row.product.id}`} className={classes.buttonLink}>
                                            <Button>Goto</Button>
                                        </Link>*/}
                                            <Button color={'primary'} onClick={() => {
                                                this.props.history.push(`/definitions/menu-management/${row.product.id}`)
                                            }}>{this.props.LanguageStore.language.GOTO}</Button>
                                            <Button color={'secondary'} onClick={() => {
                                                this.removeProduct(row.id)
                                            }}>{this.props.LanguageStore.language.REMOVE}</Button>
                                        </ButtonGroup>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>);
    }
}

const useStyles = theme => ({
    table: {
        minWidth: 650,
    },
    buttonLink: {
        color: 'blue',
        textDecoration: 'none'
    },
    button: {
        margin: theme.spacing(1),
    },
    priceInput: {
        width: '50%'
    }
});

export default withRouter(withStyles(useStyles, {withTheme: true})(MenuChoicesTable));
