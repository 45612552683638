import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_CREDIT, GET_CUMULATIVE_CREDIT, GET_DAILY_CREDIT} from "../graphql/Queries";
import LayoutStore from "./LayoutStore";

class CreditStore {
    @observable cumulativeCredit={};
    @observable dailyCreditLog=[];
    @observable creditLog=[];
    @observable loading=false;

    @action async getCumulativeCredit() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                this.loading = false;
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_CUMULATIVE_CREDIT(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    LayoutStore.enqueueSnackbar({
                        message: "OOPS. Something went wrong on: CreditStore at getCumulativeCredit!",
                        options: {
                            variant: 'error',
                        },
                    });
                });
                return false;
            }
            this.cumulativeCredit = data.data.cumulative_credit_log;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: CreditStore at getCumulativeCredit!",
                    options: {
                        variant: 'error',
                    },
                });
            });
        }
    }


    @action async getDailyCredits() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_DAILY_CREDIT(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    LayoutStore.enqueueSnackbar({
                        message: "OOPS. Something went wrong on: CreditStore at getDailyCredits!",
                        options: {
                            variant: 'error',
                        },
                    });
                });
                return false;
            }
            this.dailyCreditLog = data.data.daily_credit_log;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: CreditStore at getDailyCredits!",
                    options: {
                        variant: 'error',
                    },
                });
            });
        }
    }
    @action async getCredits() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: 'post',
                headers: {
                    'Authorization': token,
                },
                data: {
                    query: `{${GET_CREDIT(parseInt(accountId))}}`
                }
            });
            this.loading = false;
            if (data.errors) {
                runInAction(() => {
                    this.loading = false;
                    LayoutStore.enqueueSnackbar({
                        message: "OOPS. Something went wrong on: CreditStore at getCredits!",
                        options: {
                            variant: 'error',
                        },
                    });
                });
                return false;
            }
            this.creditLog = data.data.credit;
        } catch (e) {
            runInAction(() => {
                this.loading = false;
                LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: CreditStore at getCredits!",
                    options: {
                        variant: 'error',
                    },
                });
            });
        }
    }
}

export default new CreditStore();
