import React, {Component} from "react";
import {observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ControlledTreeView from "../../components/menu/ControlledTreeView";
import Styles from "../../styles/Styles";
import {withStyles} from "@material-ui/core/styles";
import AddProduct from "../../components/menu/AddProduct";
import Product from "../../components/menu/Product";
import EditProduct from "../../components/menu/EditProduct";
import CopyProduct from "../../components/definitions/CopyProduct/CopyProduct";


@observer
class Menu extends Component{
    render(){
        const {classes}=this.props;
        return(
            <>
                <Grid container spacing={3}>
                    {/* Recent Orders */}
                    <Grid item xs={6} lg={4} md={4}>
                        <Paper className={classes.paper}>
                            <ControlledTreeView/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} lg={8} md={8}>
                        {/*Product*/}
                        {(this.props.match.params && this.props.match.params.id==="add") && <AddProduct />}
                        {(this.props.match.params && this.props.match.params.id==="import") && <CopyProduct />}
                        {(this.props.match.params && this.props.match.params.edit==="edit") && <EditProduct />}
                        {(this.props.match.params && this.props.match.params.id && !isNaN(Number(this.props.match.params.id)) && !this.props.match.params.edit) && <Product productId={this.props.match.params.id} />}
                    </Grid>
                </Grid>
            </>
        );
    }
}
const useStyles = theme => (Styles(theme));



export default withStyles(useStyles, { withTheme: true })(Menu);
