import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT, PRODUCT_TYPES} from "../graphql/Queries";
import {
    ADD_PRODUCT,
    ADD_PRODUCT_DETAIL,
    ADD_PRODUCT_MENU,
    ADD_PRODUCT_MENU_ITEM,
    ADD_PRODUCT_PRICE, ADD_TO_GROUP2, CLOSE_UNTIL, CLOSE_UNTIL_UPDATE,
    DELETE_PRODUCT_DETAIL,
    DELETE_PRODUCT_MENU,
    DELETE_PRODUCT_MENU_ITEM,
    SORT_PRODUCT, SORT_PRODUCT_MENU, SORT_PRODUCT_MENU_ITEM,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_DETAIL,
    UPDATE_PRODUCT_MENU_ITEM,
    UPDATE_PRODUCT_PRICE
} from "../graphql/Mutations";
import ProductGroupStore from "./ProductGroupStore";
import LayoutStore from "./LayoutStore";

class AddProductStore {
    @observable loading = false;
    @observable productTypes = [];
    @observable product = [];
    @observable history = null;

    @action
    async getProductTypes() {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            if (!accountId) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${PRODUCT_TYPES}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: getProductTypes");
                return false;
            }

            this.loading = false;
            this.productTypes = data.data.product_types;
        } catch (e) {
            console.log(e);
            alert("OOPS. Something went wrong on: getProductTypes");
        }
    }

    @action
    async addProduct(productName, productNumber, productActive, productType, mainGroupId, imageUrl, isMenu) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT(mainGroupId, parseInt(accountId), productName, parseInt(productType), Number(productActive), parseInt(productNumber), imageUrl, Boolean(productActive))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore!!!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            console.log("new product", data);
            await ProductGroupStore.getProductGroups(-1);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Successfully!',
                options: {
                    variant: 'success',
                },
            }));
            //this.history.push({pathname: `/menu-management/${data.data.add_product.id}`});
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddProductGroupStore on addProduct!!!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async updateProduct(id, group, type, name, number, active, img_url, isMenu) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRODUCT(parseInt(id), parseInt(accountId), parseInt(group), parseInt(accountId), name, parseInt(type), Number(active), parseInt(number), img_url, Boolean(isMenu))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!");
                this.loading = false;
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Product Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
                this.history.push({pathname: `/definitions/menu-management/${id}`});

            });
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action
    async addToGroup2(id, group2) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_TO_GROUP2(parseInt(id), group2)}}`
                }
            });

            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore on addToGroup2!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Product Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddProductGroupStore on addToGroup2!!!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action
    async sortProduct(id, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${SORT_PRODUCT(parseInt(id), parseInt(sort))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore on sortProduct!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Product Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
                //this.history.push({pathname: `/definitions/menu-management/${id}`});
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore on  sortProduct!");
        }
    }
    @action
    async sortProductMenu(id, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${SORT_PRODUCT_MENU(parseInt(id), parseInt(sort))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore on sortProductMenu!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Product Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
                //this.history.push({pathname: `/definitions/menu-management/${id}`});
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore on  sortProductMenu!");
        }
    }
    @action
    async sortProductMenuItem(id, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${SORT_PRODUCT_MENU_ITEM(parseInt(id), parseInt(sort))}}`
                }
            });

            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductGroupStore on updateProduct!!!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductGroupStore on sortProductMenuItem!!!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => {
                LayoutStore.enqueueSnackbar({
                    message: 'Updated Product Successfully!',
                    options: {
                        variant: 'success',
                    },
                });
                //this.history.push({pathname: `/definitions/menu-management/${id}`});
            });
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore on  sortProductMenuItem!");
        }
    }

    @action
    async getProduct(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT(id, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at getProduct()!");
                return false;
            }
            this.product = data.data.product;
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at getProduct()");
        }
    }

    @action
    async addDescription(productId, detailType, detailText) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_DETAIL(productId, detailType, detailText, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: AddProductStore at addDescription!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            await this.getProduct(productId);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Description Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: AddProductStore at addDescription!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async editDescription(id, productId, detailType, detailText) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRODUCT_DETAIL(id, parseInt(accountId), productId, detailType, detailText, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: "OOPS. Something went wrong on: AddProductStore at editDescription!",
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }

            await this.getProduct(productId);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Description Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: "OOPS. Something went wrong on: AddProductStore at editDescription!",
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async addPrice(id, price, takeaway, eatin, group) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_PRICE(id, parseFloat(price), parseFloat(takeaway), parseFloat(eatin), 1, parseInt(accountId), parseInt(group))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addPrice!");
                return false;
            }
            await this.getProduct(id);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Price Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addPrice!");
        }
    }

    @action
    async editPrice(id, product, price, takeaway, eatin) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRODUCT_PRICE(id, parseInt(accountId), parseInt(product), parseFloat(price), parseFloat(takeaway), parseFloat(eatin), 1, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                this.loading = false
                alert("OOPS. Something went wrong on: AddProductStore at editPrice!");
                return false;
            }
            await this.getProduct(product);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Price Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at editPrice!");
        }
    }

    @action
    async addProductMenu(product, name, price, menuType) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            let sort=1;
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU(parseInt(menuType), name, parseFloat(price), product, parseInt(accountId), sort)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
                return false;
            }
            await this.getProduct(product);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Menu Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
        }
    }

    @action
    async deleteProductMenuItem(id, productId) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${DELETE_PRODUCT_MENU_ITEM(parseInt(id), parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at deleteProductMenuItem!");
                return false;
            } else if (data.data.delete_product_menu_item.success !== 1) {
                //alert(data.data.data.delete_product_menu_item.message);
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: data.data.data.delete_product_menu_item.message,
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Product Menu Item Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at deleteProductMenuItem!");
        }
    }

    @action
    async deleteProductMenu(id, productId) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${DELETE_PRODUCT_MENU(parseInt(id), parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at deleteProductMenu!");
                return false;
            } else if (data.data.delete_product_menu.success !== 1) {
                //alert(data.data.data.delete_product_menu.message);
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: data.data.data.delete_product_menu.message,
                    options: {
                        variant: 'error',
                    },
                }));
            }
            await this.getProduct(parseInt(productId));
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Product Menu Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at deleteProductMenu!");
        }
    }

    @action
    async deleteProductDetail(id, productId) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${DELETE_PRODUCT_DETAIL(parseInt(id), parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at deleteProductDetail!");
                return false;
            } else if (data.data.delete_product_detail.success !== 1) {
                //alert(data.data.data.delete_product_detail.message);
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: data.data.data.delete_product_detail.message,
                    options: {
                        variant: 'error',
                    },
                }));
            }
            await this.getProduct(parseInt(productId));
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Deleted Product Detail Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at deleteProductDetail!");
        }
    }

    @action
    async addProductMenuItem(menu, type, mainProduct, productId, price) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            let sort=1;
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU_ITEM(parseInt(menu), parseInt(type), parseInt(productId), parseFloat(price), parseInt(accountId), sort)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addProductMenuItem!");
                return false;
            }
            await this.getProduct(parseInt(mainProduct));
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenuItem!");
        }
    }
    @action
    async updateProductMenuItem(id,menu, type, mainProduct, productId, price) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_PRODUCT_MENU_ITEM(id, parseInt(accountId), menu, type,productId,price,parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AddProductStore at updateProductMenuItem!");
                runInAction(() => LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: AddProductStore at updateProductMenuItem!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Product Menu Item Successfully!',
                options: {
                    variant: 'success',
                },
            }));
            await this.getProduct(parseInt(mainProduct));
        } catch (e) {
            console.log(e);
            this.loading = false
            //alert("OOPS. Something went wrong on: AddProductStore at updateProductMenuItem!");
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: AddProductStore at updateProductMenuItem!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action
    async addCloseToSale(productId,productName, productType, closeUntil) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${CLOSE_UNTIL( parseInt(accountId), productId, productName, productType, closeUntil)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addCloseToSale!");
                return false;
            }
            await this.getProduct(productId);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Except Product Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addCloseToSale!");
        }
    }
    @action
    async updateCloseToSale(id, productId, closeUntil) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${CLOSE_UNTIL_UPDATE(id, parseInt(accountId), closeUntil)}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at updateCloseToSale!");
                return false;
            }
            await this.getProduct(productId);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Updated Except Product Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at updateCloseToSale!");
        }
    }
}


export default new AddProductStore();
