export const PRODUCT_TYPES = `product_types {
        id
        type_name
        created_at
        updated_at
    }`;
export const GET_PRODUCT = (id, account) => `
  product(id: ${id},account:${account}){
      id
      product_name
      product_is_menu
      product_sort
       account_product_name{id except_p_data except_p_not_show}
      account_product_not_show{id except_p_data except_p_not_show}
      account_product_price{id except_c_takeaway except_c_eatin except_c_value }
      product_type{id type_name created_at updated_at}  
      product_detail{id detail_type detail_text}
      product_group{
            id
            group_sort
            product_group_main{id main_group_name}
          }
          product_group2{
            id
            group_sort
            product_group_main{ id main_group_name}
          }
      product_price{
           id
          price_value
          price_takeaway
          price_eatin
      }
      product_number
    img_url
      product_menu {
          id
          menu_type
          menu_sort
          menu_name
          product_items {
              id
              item_price
              item_sort
              product {
                  id
                  product_name
                img_url
                  product_number

              }
          }
      }
  }`;
export const GET_ACCOUNT_DETAILS = (id) => `account (id: ${id}) {
        id
        account_title
        account_gsm_no
        account_active
        account_order_accept
        brand {
            id
            brand_name
            brand_banner
            accounts {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_delivery
                account_takeaway
                account_eatin
                account_price_group
                account_e_mail
                account_kep
                account_tax_no
            }
        }
        delivery_point_neighborhood {
            id
            point_type
            point_fee
            point_row{
                        id
                        name
                        district{
                            id
                            name
                            town{
                                id
                                name
                                city{
                                    id
                                    name
                                    country{
                                        id
                                        name
                                        phonecode
                                    }
                                }
                            }
                        }
                    }
            point_min_pay
            point_time
        }
        delivery_point_city {
            id
            point_type
            point_fee
            point_row{id name}
            point_min_pay
            point_time
        }
        account_type
        account_opening
        account_closing
        account_center
        account_delivery
        account_takeaway
        account_eatin
        account_work_hours{
            id
            monday_opening
            monday_closing
            tuesday_opening
            tuesday_closing
            wednesday_opening
            wednesday_closing
            thursday_opening
            thursday_closing
            friday_opening
            friday_closing
            saturday_opening
            saturday_closing
            sunday_opening
            sunday_closing
        }
        address {
            id
            address_name
            address_phone
            address_text
            address_direction
            address_location
            address_lat
            address_long
            address_row
        }
        account_price_group
        account_e_mail
        account_kep
        account_tax_no
    }
`;
export const GET_POINT_SET=(id)=>`point_set(account:${id}){
  id
  set_type
  set_brand{id brand_name}
  set_account{id account_title}
  set_title
  set_percent_takeaway
  set_percent
}`;


//Groups Product, Active, Price
export const GET_PRODUCT_GROUPS = (account, sort) => `
  product_groups(group_account: ${account}, sort:${sort}) {
    id
    group_sort
    group_active{
            id
            active_name
        }
    product_group_main {
      id
      main_group_name
      account_group_name{
        except_g_data
      }
    }
    products {
      id
      product_name
      product_is_menu
      product_sort
      account_product_name{id except_p_data except_p_not_show}
      account_product_not_show{id except_p_data except_p_not_show}
      account_product_price{id except_c_takeaway except_c_eatin except_c_value }
      product_detail{
        detail_text
      }
      product_price{
        price_value
        price_takeaway
        price_eatin
      }
      product_menu {
          id
          menu_type
          menu_name
          product_items {
              id
              item_price
              product {
                  id
                  product_name
                img_url
                  product_number

              }
          }
      }
    }
  }`;
export const GET_PRODUCT_M_GROUPS = `product_m_groups {
        id
        main_group_name
        img_url
        account_group_name {
            id
            except_g_account
            except_g_row
            except_g_type
            except_g_data
            created_at
            updated_at
        }
    }`;
export const GET_PRODUCT_PRICE_GROUPS = (account) => `product_price_groups (account: ${account}) {
        id
        price_group_account
        price_group_name
        price_group_active
        created_at
        updated_at
        default
    }`;
export const GET_ACTIVE_GROUPS = (account) => `product_active_groups (account:${account} ) {
        id
        active_name
        active_true
        active_start_time
        active_end_time
        created_at
        updated_at
    }`;

//GET PRODUCT

//ORDERS
export const GET_ALL_DAILY_ORDERS = (account) => `
all_daily_orders (account: ${account}) {
        id
                order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                email_verified_at
                phone
            }
            customer_order_source
        }
        account {
            id
            account_title
            brand {
                id
                brand_name
                brand_banner
            }
            account_type
            account_opening
            account_closing
            account_center
            account_price_group
        }
        order_price
order_point_use
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }
`;
export const GET_ALL_WAITING_ORDERS = (account) => `
all_waiting_orders (account: ${account}) {
        id
                order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                email_verified_at
                phone
            }
            customer_order_source
        }
        account {
            id
            account_title
            brand {
                id
                brand_name
                brand_banner
            }
            account_type
            account_opening
            account_closing
            account_center
            account_price_group
        }
        order_price
order_point_use
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }
`;
export const GET_ALL_ACCEPTED_ORDERS = (account) => `
all_accepted_orders (account: ${account}) {
        id
                order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                phone
            }
            customer_order_source
        }
        account {
            id
            account_title
            brand {
                id
                brand_name
                brand_banner
                
            }
            account_type
            account_opening
            account_closing
            account_center
            
            account_price_group
        }
        
        order_price
order_point_use
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                brand {
                    id
                    brand_name
                    brand_banner
                }
                
                account_type
                account_opening
                account_closing
                account_center
                address {
                    id
                    address_name
                    address_phone
                    address_text
                    address_direction
                    address_location
                    address_lat
                    address_long
                    address_row
                }
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }
`;
export const GET_ALL_REFUSED_ORDERS = (account) => `
all_refused_orders (account: ${account}) {
        id
        order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                phone
            }
            customer_order_source
        }
        account {
            id
            account_title
            brand {
                id
                brand_name
                brand_banner
                
            }
            account_type
            account_opening
            account_closing
            account_center
            
            account_price_group
        }
        order_price
order_point_use
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                brand {
                    id
                    brand_name
                    brand_banner
                }
                
                account_type
                account_opening
                account_closing
                account_center
                address {
                    id
                    address_name
                    address_phone
                    address_text
                    address_direction
                    address_location
                    address_lat
                    address_long
                    address_row
                }
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }`;
export const GET_ALL_COMPLETED_ORDERS = (account) => `
all_completed_orders (account: ${account}) {
        id
        order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                phone
            }
            customer_order_source
        }
        address{
            id
        }
        account {
            id
            account_title
            account_type
            account_opening
            account_closing
            account_center
            
            account_price_group
        }
        
        order_price
order_point_use
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                
                account_type
                account_opening
                account_closing
                account_center
                address {
                    id
                    address_name
                    address_phone
                    address_text
                    address_direction
                    address_location
                    address_lat
                    address_long
                    address_row
                }
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }`;
export const GET_ORDER = (id) => `
order (id: ${id}) {
        id
        order_note{id note_text}
        customer {
            id
            customer_name
            user {
                id
                name
                email
                phone
            }
            customer_order_source
        }
        address{
            id
        }
        account {
            id
            account_title
            account_type
            account_opening
            account_closing
            account_center
            
            account_price_group
        }
        
        order_price
order_point_use
        address{
          id
          address_name
          address_phone
          address_text
          address_location
          address_direction
          citiy{id name}
          town{id name}
          district{id name}
          neighborhood{id name}
        }
        ordertype {
            id
            type_name
            created_at
            updated_at
        }
        order_source {
            id
            center_account {
                id
                account_title
                
                account_type
                account_opening
                account_closing
                account_center
                address {
                    id
                    address_name
                    address_phone
                    address_text
                    address_direction
                    address_location
                    address_lat
                    address_long
                    address_row
                }
                account_price_group
            }
            per_order_credit
            created_at
            updated_at
        }
        status {
            id
            statu_name
            created_at
            updated_at
        }
        orderpayrule {
            id
            payrule_name
            created_at
            updated_at
        }
        order_json
        order_receive_time
        order_table
        order_confirm {
            id
            created_at
            updated_at
            account {
                id
                account_title
                account_type
                account_opening
                account_closing
                account_center
                account_price_group
            }
            table
            code
            state
        }
        created_at
        updated_at
    }`;
export const GET_CANCEL_REASONS = (id) => `
order_cancel_reasons(order_id:${id}){
  id 
  cancel_r_type{id cancel_t_name}
  cancel_r_order_statu{id statu_name}
}`;


//Restaurant inner design Room/Salon and Table
export const GET_ACCOUNT_ROOMS = (account) => `account_rooms(account: ${account}){
  id
  room_name
  room_active
  room_tables{
    id
    room_table_name
    room_table_room{id room_name room_active}
    room_table_x
    room_table_y
    room_table_active
    room_table_order{id order_price
order_point_use status{id statu_name}}
    created_at
    updated_at   
  }}`;

//SURVEY || ANKET
export const GET_SURVEY_GROUPS = (account) => `survey_groups(account:${account}){
  id
  group_name
  group_brand
  subgroups{id group_name surveys{id survey_title}}
  group_depend
  surveys{
    id 
    survey_title 
    survey_brand 
    survey_account
    survey_description
    survey_active
    survey_group
    survey_start
    survey_end
    survey_questions{id}
    created_at
    updated_at
  }
}`;
export const GET_SURVEY = (id) => `survey(id:${id}){
  id
  survey_brand
  survey_account
  survey_group
  survey_title
  survey_description
  survey_active
  survey_start
  survey_end
  survey_questions{
    id
    question_label
    question_text
    question_p_holder
    question_er_msg
    question_description
    question_min_length
    question_max_length
    question_type{id name active}
    question_survey
    question_items{
      id
      q_item_question
      q_item_value
      q_item_point
    }
    created_at
    updated_at
  }
  created_at
  updated_at
}`;
export const GET_QUESTION_TYPES = `survey_q_types{id name active}`;
export const GET_SURVEY_QUESTIONS = (survey) => `survey_questions(survey:${survey}){
   id
    question_label
    question_text
    question_p_holder
    question_er_msg
    question_description
    question_min_length
    question_max_length
    question_survey
    question_type{id name active}
    question_items{
      id
      q_item_question
      q_item_value
      q_item_point
    }
    created_at
    updated_at
}`;

//ACCOUNT PAYRULES

export const GET_ACCOUNT_PAYRULES = (account) => `account_payrules (payrule_account: ${account}) {
        id
    payrule_eatin
  payrule_delivery
  payrule_takeaway
  orderpayrule{id payrule_name payrule_type}
        created_at
        updated_at
    }`;
export const GET_PAYRULES = () => `order_payrules {
        id
        payrule_name
        payrule_type
        created_at
        updated_at
    }`;

//GET CITIES
export const GET_CITIES = `cities {
        id
        country {
            id
            binarycode
            triplecode
            name
            phonecode
        }
        towns {
            id
            name
        }
        name
        plate
        phonecode
        imagepath
        isactive
        sortby
    }`;
export const GET_CITY = (id) => `city (id: ${id}) {
        id
        towns {
            id
            name
            districts {
              id
              name
            }
        }
        name
        plate
        phonecode
        imagepath
        isactive
        sortby
    }`;
export const GET_DISTRICT = (id) => `district (id: ${id}) {
        id
        name
        neighborhoods {
            id
            name
            zipcode
        }
    }`;

//GET CREDIT
export const GET_CUMULATIVE_CREDIT = (account) => `cumulative_credit_log(account:${account}){
  id
  incoming
  outgoing
  account
  created_at
  updated_at
}`;

export const GET_DAILY_CREDIT = (account) => `daily_credit_log(account:${account}){
  id
  incoming
  outgoing
  account
  date
}`;
export const GET_CREDIT = (account) => `credit(account:${account}){
  id
  account
  source
  incoming
  outgoing
  source
  info
}`;

export const GET_STATUSES=`statuses{
  id 
  statu_name
}`;

//GET NEAR PLACES
export const GET_NEAR_PLACES = (account) => `account_near_places(account:${account}){
  id
  place{
    id 
    address_text
    address_direction
    address_name
    address_lat
    address_type{id type_name}
    citiy{id name}
    town{id name}
    district{id name}
    neighborhood{id name}
    address_long
    address_location
  }
}`;

//ADDRESSES
export const GET_ADDRESSES=(type)=>`addresses(type:${type}){
  id
  address_name
  address_text
  address_lat
  address_long
  address_type{id type_name}
  address_phone
  address_location
  citiy{id name}
  town{id name}
  district{id name}
  neighborhood{id name}
  address_direction
}`;
export const GET_ADDRESS_TYPES=`address_types{id type_name}`;

//DOCUMENT
export const GET_DOCUMENT_TYPES=`document_types{id document_type_name}`;
export const GET_DOCUMENTS=(account)=>`documents(account:${account}){
  id 
  document_type{id document_type_name}
  document_url
  document_expiry
  created_at
}`;

//GET ALL ACCOUNTS
export const GET_ACCOUNTS=`
    accounts{
      id
      account_title
      account_center
      brand{id brand_name}
    }
`;

//GET ALL BRANDS
export const GET_BRANDS=`brands{
  id
  brand_name
  brand_banner
  accounts{id account_type account_title account_center}
}`;

//EXTERNAL SOURCES
export const GET_EXTERNAL_SOURCES=`external_sources{
  id
  api
  center_account
}`;

//-------- ADMIN ---------//
//GET ALL ORDERS
export const GET_ALL_ORDERS=(start, end)=>`all_orders_by_date(start_date:"${start}" end_date:"${end}"){
  id
  customer{id customer_name}
  account{id account_title brand{id brand_name}}
  address{address_name address_phone}
  order_price
  order_json
  ordertype{id type_name}
  orderpayrule{id payrule_name}
  order_receive_time
  order_confirm{id table state code}
  order_note{id note_text}
  status{id statu_name}
  created_at
  updated_at
}`;
