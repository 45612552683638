import React, {Component} from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Card, CardActions, List, ListItem, ListItemText, Slide} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";

class NavCards extends Component {
    state={
        swipeUp:false
    }
    render() {
        const {classes} = this.props;
        return (
            <Card className={classes.root} style={{backgroundColor: this.props.color}} onMouseEnter={()=>this.setState({swipeUp:true})} onMouseLeave={()=>this.setState({swipeUp:false})} onClick={this.props.onClick}>
                <CardContent style={{height:'75%'}}>
                    <Slide direction="down" in={!this.state.swipeUp} mountOnEnter unmountOnExit>
                        <Grid className={classes.IconContainer} container  direction="row" justify="center"
                              alignItems="center" spacing={2}>
                            <Grid item>
                                {this.props.icon}
                            </Grid>
                        </Grid>
                    </Slide>
                    <Slide direction="up" in={this.state.swipeUp} mountOnEnter unmountOnExit>
                        <Grid className={classes.IconContainer} container  direction="row" justify="center"
                              alignItems="center" spacing={2}>
                            <Grid item>
                                <List className={classes.InfoList} dense>
                                    {this.props.info.map((inf, i)=>(
                                        <ListItem key={i}><ListItemText primary={inf} /></ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Slide>
                </CardContent>
                <CardActions className={classes.NameContainer} style={{backgroundColor: this.props.secondaryColor}}>
                    <Typography variant="h6" style={{margin:"auto"}}>
                        {this.props.name}
                    </Typography>
                </CardActions>
            </Card>
        );
    }
}

const useStyles = theme => ({

    root: {
        width: 202,
        height: 202,
    },
    IconContainer: {
        height: '100%',
    },
    NameContainer: {
        height: '25%',
        position: 'relative',
        textAlign:"center",
        color:grey[50],
        left:0,
        right:0,
        bottom:0,
    },
    InfoList: {
        height: '25%',
        position: 'relative',
        textAlign:"center",
        color:grey[50],
        overflowY:"hidden",
        left:0,
        right:0,
        bottom:0,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
export default withStyles(useStyles, {withTheme: true})(NavCards);
