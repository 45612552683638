import {observable, runInAction} from 'mobx';
import AsyncStorage from "@callstack/async-storage";
import {LANGUAGE} from "../constants";
import enData from '../languages/en.json';
import trData from '../languages/tr.json';
import deData from '../languages/de.json';
import frData from '../languages/fr.json';

const en = enData;
const tr = trData;
const fr = frData;
const de = deData;


class LanguageStore{

    language_code = null;
    all_languages = {tr,en,fr, de};
    @observable language = this.firstLng();

    async firstLng(){
        let lng = await AsyncStorage.getItem("language_code");
        return lng === "tr" ? {...tr} : lng === "fr" ? {...fr}: lng === "de" ? {...de} : {...en};
    }
    constructor(props) {
        this.setLanguage().then(r => console.log('lang set to ', LANGUAGE));
    }
    async setLanguage(){
        this.language_code =  await AsyncStorage.getItem("language_code");
        if(!this.language_code){
            await AsyncStorage.setItem("language_code",LANGUAGE);
            this.language_code =  await AsyncStorage.getItem("language_code");
        }
        runInAction(()=>{
            this.language = this.all_languages[this.language_code];
        });
    }
    async setLanguageManuel(lang){
        this.language_code =  await AsyncStorage.getItem("language_code");
        if(true){
            await AsyncStorage.setItem("language_code",lang);
            this.language_code =  await AsyncStorage.getItem("language_code");
        }
        runInAction(()=>{
            this.language = this.all_languages[this.language_code];
        });
    }

    @observable async changeLanguage(lang_code){
        await AsyncStorage.setItem("language_code",lang_code);
        runInAction(()=>{
            this.language_code = lang_code;
            this.language = this.all_languages[this.language_code];
        })
    }

}

export default new LanguageStore();
