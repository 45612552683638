import {action, observable} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {ADD_PRODUCT_GROUP} from "../graphql/Mutations";
import ProductGroupStore from "./ProductGroupStore";

class AddProductGroupStore {
    @observable loading = false;

    @action async addProductGroup(group_main, group_sort,group_active) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            //console.log("accountId", accountId);
            if(!accountId || !token){
                return false;
            }
            const {data}= await axios({
                url:`${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method:"post",
                data:{
                    query:`mutation {${ADD_PRODUCT_GROUP(group_main, group_sort, parseInt(accountId), group_active)}}`
                }
            });
            if (data.errors){
                alert("OOPS. Something went wrong on: AddProductGroupStore!");
                return false;
            }

            await ProductGroupStore.getProductGroups(-1);
            //console.log("product groups", data);

            this.loading=false;

        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductGroupStore!");
        }
    }
}

export default new AddProductGroupStore();
