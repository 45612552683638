import React, {Component} from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import {Business} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {inject} from "mobx-react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {toJS} from "mobx";

@inject("DataStore", "RegistrationStore", "LanguageStore")
class CreateAccount extends Component {

    state = {
        order_accept: 0,
        title: "",
        brand: "",
        accounts:[],
        type: 1,
        brands:[],
        center: "",
        delivery: false,
        takeaway: false,
        eatin: false,
        price_group: null,
        email: "",
        kep: "",
        tax_no: "",
        gsm_no: "",
    };
    setAccounts(brandID){
        this.setState({accounts:this.props.DataStore.brands.find((brand)=>brand.id===brandID).accounts})
    }
    account_types=[
        {id:1,  name:"Restaurant"},
        {id:2,  name:"Catering"},
        {id:3,  name:"Büfe"},
        {id:4,  name:"Market"},
        {id:5,  name:"Merkez"}];

    async componentDidMount() {
        await this.props.DataStore.getBrands();
        if (this.props.RegistrationStore.brand){
            this.setState({brand: this.props.RegistrationStore.brand.id})
        }
        this.setState({brands:this.props.DataStore.brands});
    }


    validateForm() {
        return this.state.title.length > 3 && this.state.email.length > 7 && this.state.brand && this.state.type;
    }

    async handleSubmit() {
        await this.props.RegistrationStore.createAccount(this.state.title, 0, this.state.order_accept, this.state.brand, this.state.type, (this.state.center)?(this.state.center):(-1), this.state.delivery, this.state.takeaway, this.state.eatin, this.state.price_group, this.state.email, this.state.kep, this.state.tax_no, this.state.gsm_no);
        await this.setState({order_accept: 0,
            title: "",
            brand: "",
            type: "",
            center: "",
            delivery: false,
            takeaway: false,
            eatin: false,
            price_group: null,
            email: "",
            kep: "",
            tax_no: "",
            gsm_no: "",});
    }

    render() {
        const {classes} = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Business/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create Account
                    </Typography>
                    <Typography component="p" variant="body1" color={"secondary"}>
                        *Create Account
                    </Typography>
                    <form className={classes.form} onSubmit={async (event) => {
                        event.preventDefault();
                        await this.handleSubmit();
                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label={this.props.LanguageStore.language.NAME}
                            name="title"
                            autoComplete="title"
                            value={this.state.title}
                            onChange={e => this.setState({title: e.target.value})}
                            autoFocus
                        />
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel htmlFor="outlined-age-native-simple">{this.props.LanguageStore.language.BRAND}</InputLabel>
                            <Select
                                value={this.state.brand}
                                onChange={(e)=>{
                                    this.setState({brand:e.target.value});
                                    this.setAccounts(e.target.value);
                                }}
                                label={this.props.LanguageStore.language.BRAND}
                            >
                                <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                                {this.state.brands.map((brand, index)=>(
                                    <MenuItem key={index} value={brand.id}>{brand.brand_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl} style={{marginTop:10}} fullWidth={true}>
                            <InputLabel htmlFor="outlined-age-native-simple">{this.props.LanguageStore.language.TYPE}</InputLabel>
                            <Select
                                value={this.state.type}
                                onChange={(e)=>this.setState({type:e.target.value})}
                                label={this.props.LanguageStore.language.TYPES}
                            >
                                <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                                {this.account_types.map((type, index)=>(
                                    <MenuItem key={index} value={type.id}>{type.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl} style={{marginTop:10}} fullWidth={true}>
                            <InputLabel htmlFor="outlined-age-native-simple">{"Account Center"}</InputLabel>
                            <Select
                                value={this.state.center}
                                onChange={(e)=>this.setState({center:e.target.value})}
                                label={"Account Center"}
                            >
                                <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                                {this.state.accounts.map((account, index)=>(
                                    <MenuItem key={index} value={account.id}>{account.account_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={this.props.LanguageStore.language.ACCOUNT_EMAIL}
                            name="email"
                            type={"email"}
                            autoComplete="email"
                            value={this.state.email}
                            onChange={e => this.setState({email: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="kep"
                            label={this.props.LanguageStore.language.ACCOUNT_KEP}
                            name="kep"
                            type={"email"}
                            autoComplete="kep"
                            value={this.state.kep}
                            onChange={e => this.setState({kep: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="tax"
                            label={this.props.LanguageStore.language.ACCOUNT_TAX_NO}
                            name="tax"
                            type={"number"}
                            autoComplete="tax"
                            value={this.state.tax_no}
                            onChange={e => this.setState({tax_no: e.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label={this.props.LanguageStore.language.GSM_NO}
                            name="phone"
                            type={"number"}
                            autoComplete="phone"
                            value={this.state.gsm_no}
                            onChange={e => this.setState({gsm_no: e.target.value})}
                            autoFocus
                        />
                        <FormControlLabel
                            value={this.state.order_accept}
                            control={<Switch color="secondary" onChange={(e) => {
                                this.setState({order_accept: e.target.value})
                            }}/>}
                            label={"Order Accept"}
                            labelPlacement="start"/>
                        <FormControlLabel
                            value={this.state.delivery}
                            control={<Switch color="secondary" onChange={(e) => {
                                this.setState({delivery: e.target.value})
                            }}/>}
                            label={this.props.LanguageStore.language.DELIVERY}
                            labelPlacement="start"/>
                        <FormControlLabel
                            value={this.state.takeaway}
                            control={<Switch color="secondary" onChange={(e) => {
                                this.setState({takeaway: e.target.value})
                            }}/>}
                            label={this.props.LanguageStore.language.TAKEAWAY}
                            labelPlacement="start"/>
                        <FormControlLabel
                            value={this.state.eatin}
                            control={<Switch color="secondary" onChange={(e) => {
                                this.setState({eatin: e.target.value})
                            }}/>}
                            label={this.props.LanguageStore.language.EATIN}
                            labelPlacement="start"/>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!this.validateForm()}
                        >
                            Create
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

export default withStyles(useStyles, {withTheme: true})(CreateAccount);
