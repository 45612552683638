import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import {GET_PRODUCT, GET_PRODUCT_GROUPS} from "../graphql/Queries";
import {ADD_PRODUCT_MENU, ADD_PRODUCT_MENU_ITEM} from "../graphql/Mutations";
import LayoutStore from "./LayoutStore";

class CopyMenuStore{
    @observable productGroups=[];
    @observable loading=false;
    @observable product=[];
    @observable justAddedMenuId=null;

    @action async getProductGroups(sort){
        try{
            this.loading=true;
            const accountId = await AsyncStorage.getItem('accountNo');
            //console.log("accountId", accountId);
            if(!accountId){
                return false;
            }
            const {data}=await axios({
                url:`${API_BASE}`,
                method:"post",
                data:{
                    query: `{${GET_PRODUCT_GROUPS(parseInt(accountId),parseInt(sort))}}`
                }
            });
            if (data.errors){
                alert("OOPS. Something went wrong");
                return false;
            }
            //console.log("product groups", data);

            this.loading=false;
            this.productGroups=data.data.product_groups;
        }catch (e) {
            console.log(e);
            alert("OOPS. Something went wrong!");
        }
    }

    @action async getProduct(id) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                method: "post",
                data: {
                    query: `{${GET_PRODUCT(id, parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at getProduct()!");
                this.loading = false;
                return false;
            }
            this.product = data.data.product;
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at getProduct()");
        }
    }

    @action
    async addProductMenu(product, name, price, menuType, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            if (sort===null){
                sort=0;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU(parseInt(menuType), name, parseFloat(price), product, parseInt(accountId), parseInt(sort))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
                return false;
            }
            this.justAddedMenuId = data.data.add_product_menu.id;
            //console.log('from store',this.justAddedMenuId, data.data.add_product_menu.id);
            //await this.getProduct(product);
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Menu Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenu!");
        }
    }

    @action
    async addProductMenuItem(menu, type, mainProduct, productId, price, sort) {
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            if (sort===null){
                sort=0;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_PRODUCT_MENU_ITEM(parseInt(menu), parseInt(type), parseInt(productId), parseFloat(price), parseInt(accountId), parseInt(sort))}}`
                }
            });
            if (data.errors) {
                alert("OOPS. Something went wrong on: AddProductStore at addProductMenuItem!");
                return false;
            }
            await this.getProduct(parseInt(mainProduct));
            this.loading = false;
            runInAction(() => LayoutStore.enqueueSnackbar({
                message: 'Added Product Menu Item Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            alert("OOPS. Something went wrong on: AddProductStore at addProductMenuItem!");
        }
    }
}

export default new CopyMenuStore();
