import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {inject, observer} from "mobx-react";
import grey from "@material-ui/core/colors/grey";
import Orders from "../../components/Home/Orders";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



@inject('LanguageStore')
@observer
class Home extends Component {

    state = {
        value: 0
    }
    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                {/*<AppBar position="static" className={classes.HomeTabs}>
                    <Tabs value={this.state.value} onChange={(event, value)=>{this.handleChange(event, value)}} aria-label="Home">
                        <Tab label={this.props.LanguageStore.language.ORDER_MANAGEMENT} {...a11yProps(0)} />
                        <Tab label={this.props.LanguageStore.language.ROOM_AND_TABLE} {...a11yProps(1)} />
                        <Tab label={this.props.LanguageStore.language.FAST_SELL} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <Orders/>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <RoomAndTable/>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <FastSell/>
                </TabPanel>*/}
                <Orders/>
            </div>
        );
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    HomeTabs:{
        backgroundColor: grey[700],
    },
});

export default withStyles(useStyles, {withTheme: true})(Home);
