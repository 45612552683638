import React, {Component} from "react";
import {
    Avatar, Badge, Button, Card, CardActions, CardContent,
    Grid, InputLabel, MenuItem,
    Paper, Select, Step, StepLabel, Stepper, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs,
    Typography
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {Print} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import DDlingSFX from "../../audio/ddling.mp3";
import moment from "moment/moment";
import ReactToPrint from "react-to-print";
import OrderToPrint from "./OrderToPrint";
import AsyncStorage from "@callstack/async-storage/lib";
import Client from "../../ApolloSetup";
import gql from "graphql-tag";
import {Skeleton} from "@material-ui/lab";
import {toJS} from "mobx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";

function SourceIcon(props) {
    const {source}=props;
    if (source==="ya"){
        return(<Avatar style={{backgroundColor:"red"}}>YA</Avatar>);
    }else if(source==="getir"){
        return(<Avatar style={{backgroundColor:"#6954c6"}}>G</Avatar>);
    }else if(source==="ys"){
        return(<Avatar style={{backgroundColor:"#cc1c24"}}>YS</Avatar>);
    }else {
        return(<Avatar>?</Avatar>);
    }
}
let setOrder = null;
@inject('AccountStore', 'AuthStore', 'LayoutStore', 'ProductGroupStore', 'LanguageStore','DataStore')
@observer
class Orders extends Component {
    notificationAudio = new Audio(DDlingSFX);
    state={
        activeTab:0,
        activeStep:0,
        cancelOrderModal:false,
        steps:[
            {
                "id": 1,
                "statu_name": "Sipariş alındı"
            },
            {
                "id": 2,
                "statu_name": "Restaurant siparişi aldı"
            },
            {
                "id": 3,
                "statu_name": "Restaurant siparişi kabul etti"
            },
            {
                "id": 4,
                "statu_name": "Restaurant siparişi iptal etti"
            },
            {
                "id": 5,
                "statu_name": "Sipariş teslim edildi"
            }],
        selectedOrderId:null,
        selectedOrderIndex:null,
        selectedOrder:null,
        selectedReason:'',
        printOrder:null,
    }

    async componentDidMount() {
        setOrder = this.setOrder.bind(this);
        await this.props.DataStore.getStatuses();
        await this.props.AccountStore.getAllDailyOrders();
        let accountNo = await AsyncStorage.getItem('accountNo');
        accountNo &&
        Client.subscribe({
            query: gql`
                subscription{
                    postWebQrOrder(account:${accountNo}){
                        id
                    }
                }`,
            variables: {}
        }).subscribe({
            async next(data) {
                //console.log(this.props, data.data.postWebQrOrder.id);
                setOrder(data.data.postWebQrOrder.id);
            }
        });
    }
    formatTimestamp(time){
        let date = moment(parseInt((time)?(time):(0)));
        return date.calendar();
    }

    async setOrder(id) {
        await this.notificationAudio.play();
        this.props.LayoutStore.enqueueSnackbar({
            message: `${(await this.props.LanguageStore.language).NEW_ORDER}!!! #${id}`,
            options: {
                variant: 'info',
            },
        });
        this.setState({activeTab:0});
        await this.props.AccountStore.getAllDailyOrders();
    }

    isRecent(time){
        let date=parseInt(time);
        let diff=parseInt(Date.now())-date;
        return diff <= 60000;
    }
    async handleTabChange(newValue) {
        this.setState({activeTab: newValue});
        if (newValue === 0) {
            await this.props.AccountStore.getAllDailyOrders();
        }else if (newValue===1){
            await this.props.AccountStore.getAllWaitingOrders();
        }else if (newValue===2){
            await this.props.AccountStore.getAllAcceptedOrders();
        }else if (newValue===3){
            await this.props.AccountStore.getAllCompletedOrders();
        }else if (newValue===4){
            await this.props.AccountStore.getAllCancelledOrders();
        }
    }
    handleOrderSelect(id, selectedOrder, index){
        console.log("order selected", id, selectedOrder, index);
        this.setState({selectedOrderId:id, selectedOrder:selectedOrder, selectedOrderIndex:index})
    }

    cancelOrderModal(){
        this.setState({cancelOrderModal:true});
        this.props.AccountStore.getCancelReasons(this.state.selectedOrderId).then(r => console.log(toJS(this.props.AccountStore.cancelReasons)));
    }
    async acceptOrder() {
        await this.props.AccountStore.acceptOrder(this.state.selectedOrderId, this.state.selectedOrder, this.state.selectedOrderIndex);
        await this.updateList();
    }
    async cancelOrder() {
        await this.props.AccountStore.cancelOrder(this.state.selectedOrderId, this.state.selectedOrder, this.state.selectedOrderIndex, this.state.selectedReason);
        this.setState({cancelOrderModal:false, selectedReason:''});
        await this.updateList();
    }
    async completeOrder() {
        await this.props.AccountStore.completeOrder(this.state.selectedOrderId, this.state.selectedOrder, this.state.selectedOrderIndex);
        await this.updateList();
    }
    async updateList(){
        if (this.state.activeTab === 0) {
            await this.props.AccountStore.getAllDailyOrders();
        }else if (this.state.activeTab===1){
            await this.props.AccountStore.getAllWaitingOrders();
        }else if (this.state.activeTab===2){
            await this.props.AccountStore.getAllAcceptedOrders();
        }else if (this.state.activeTab===3){
            await this.props.AccountStore.getAllCompletedOrders();
        }
        await this.props.AccountStore.getOrder(this.state.selectedOrderId);
        this.setState({selectedOrder:this.props.AccountStore.order});
    }
    render() {
        const {classes}=this.props;
        console.log(toJS(this.props.AccountStore.allWaitingOrders));
        return (
            <Grid container={true} style={{height: "100%"}}>
                <Grid item={true} xs={7} md={7} lg={7}>
                    <Paper className={classes.root}>
                        <Tabs
                            value={this.state.activeTab} indicatorColor="primary" textColor="primary"
                            onChange={(e,newValue)=>this.handleTabChange(newValue)}
                            aria-label="Orders">
                            <Tab label={this.props.LanguageStore.language.DAILY} />
                            <Tab label={this.props.LanguageStore.language.WAITING} />
                            <Tab label={this.props.LanguageStore.language.ACCEPTED} />
                            <Tab label={this.props.LanguageStore.language.COMPLETED} />
                            <Tab label={this.props.LanguageStore.language.CANCELLED}  textColor={"secondary"}/>
                        </Tabs>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{this.props.LanguageStore.language.TYPE}</TableCell>
                                        <TableCell>{this.props.LanguageStore.language.ORDER}</TableCell>
                                        <TableCell>{this.props.LanguageStore.language.COSTUMER}</TableCell>
                                        <TableCell>{this.props.LanguageStore.language.AMOUNT}</TableCell>
                                        <TableCell>{this.props.LanguageStore.language.STATUS}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.AccountStore.loading && (<>
                                        <TableRow hover role="checkbox"  tabIndex={-1}>
                                            <TableCell>
                                                <Skeleton variant="circle" width={40} height={40} />
                                            </TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Typography variant={"body1"}><Skeleton /></Typography>
                                                <Typography variant={"caption"} ><Skeleton /></Typography></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                        </TableRow><TableRow hover role="checkbox"  tabIndex={-1}>
                                            <TableCell>
                                                <Skeleton variant="circle" width={40} height={40} />
                                            </TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Typography variant={"body1"}><Skeleton /></Typography>
                                                <Typography variant={"caption"} ><Skeleton /></Typography></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                        </TableRow><TableRow hover role="checkbox"  tabIndex={-1}>
                                            <TableCell>
                                                <Skeleton variant="circle" width={40} height={40} />
                                            </TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Typography variant={"body1"}><Skeleton /></Typography>
                                                <Typography variant={"caption"} ><Skeleton /></Typography></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                            <TableCell><Skeleton /></TableCell>
                                        </TableRow></>)}
                                    {(!this.props.AccountStore.loading && this.state.activeTab===0) && this.props.AccountStore.allDailyOrders?.map((order,index)=>(
                                        <TableRow key={order.id} hover role="checkbox" onClick={()=>this.handleOrderSelect(order.id, order, index)} tabIndex={-1} selected={(this.state.selectedOrderId===order.id)}>
                                            <TableCell>
                                                {(this.isRecent(order.created_at))?(
                                                    <Badge badgeContent={this.props.LanguageStore.language.NEW} color="primary">
                                                        <SourceIcon source={"ya"}/>
                                                    </Badge>
                                                ):(
                                                        <SourceIcon source={"ya"}/>
                                                )}
                                                </TableCell>
                                            <TableCell>{order.id}</TableCell>
                                            <TableCell>{order.ordertype.type_name}</TableCell>
                                            <TableCell>{this.formatTimestamp(order.created_at)}</TableCell>
                                            <TableCell>
                                                <Typography variant={"body1"}>{order.customer?.customer_name}</Typography>
                                                <Typography variant={"caption"} >{order.customer?.user?.phone}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {(parseFloat(order.order_point_use)!==0)?(
                                                    <><Typography variant={"body1"}>{(parseFloat(order.order_price)- parseFloat(order.order_point_use)).toFixed(2)}</Typography>
                                                        <Typography variant={"caption"} color={"secondary"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                    </>
                                                ):(
                                                    <Typography variant={"body1"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                    )}
                                                </TableCell>
                                            <TableCell>{(order.status?.statu_name)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {(!this.props.AccountStore.loading && this.state.activeTab===1) && this.props.AccountStore.allWaitingOrders?.map((order,index)=>(
                                        <TableRow key={order.id} hover role="checkbox" onClick={()=>this.handleOrderSelect(order.id, order,index)} tabIndex={-1} selected={(this.state.selectedOrderId===order.id)}>
                                            <TableCell>
                                                {(this.isRecent(order.created_at))?(
                                                    <Badge badgeContent={this.props.LanguageStore.language.NEW} color="primary">
                                                        <SourceIcon source={"ya"}/>
                                                    </Badge>
                                                ):(
                                                        <SourceIcon source={"ya"}/>
                                                )}
                                                </TableCell>
                                            <TableCell>{order.id}</TableCell>
                                            <TableCell>{order.ordertype.type_name}</TableCell>
                                            <TableCell>{this.formatTimestamp(order.created_at)}</TableCell>
                                            <TableCell><Typography variant={"body1"}>{order.customer?.customer_name}</Typography>
                                                <Typography variant={"caption"} >{order.customer?.user?.phone}</Typography></TableCell>
                                            <TableCell>{(parseFloat(order.order_point_use)!==0)?(
                                                <><Typography variant={"body1"}>{(parseFloat(order.order_price)- parseFloat(order.order_point_use)).toFixed(2)}</Typography>
                                                    <Typography variant={"caption"} color={"secondary"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                </>
                                            ):(
                                                <Typography variant={"body1"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                            )}</TableCell>
                                            <TableCell>{(order.status?.statu_name)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {(!this.props.AccountStore.loading && this.state.activeTab===2) && this.props.AccountStore.allAcceptedOrders?.map((order,index)=>(
                                        <TableRow key={order.id} hover role="checkbox" onClick={()=>this.handleOrderSelect(order.id, order,index)} tabIndex={-1} selected={(this.state.selectedOrderId===order.id)}>
                                            <TableCell>
                                                {(this.isRecent(order.created_at))?(
                                                    <Badge badgeContent={this.props.LanguageStore.language.NEW} color="primary">
                                                        <SourceIcon source={"ya"}/>
                                                    </Badge>
                                                ):(
                                                        <SourceIcon source={"ya"}/>
                                                )}
                                                </TableCell>
                                            <TableCell>{order.id}</TableCell>
                                            <TableCell>{order.ordertype.type_name}</TableCell>
                                            <TableCell>{this.formatTimestamp(order.created_at)}</TableCell>
                                            <TableCell><Typography variant={"body1"}>{order.customer?.customer_name}</Typography>
                                                <Typography variant={"caption"} >{order.customer?.user?.phone}</Typography></TableCell>
                                            <TableCell>{(parseFloat(order.order_point_use)!==0)?(
                                                <><Typography variant={"body1"}>{(parseFloat(order.order_price)- parseFloat(order.order_point_use)).toFixed(2)}</Typography>
                                                    <Typography variant={"caption"} color={"secondary"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                </>
                                            ):(
                                                <Typography variant={"body1"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                            )}</TableCell>                                            <TableCell>{(order.status?.statu_name)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {(!this.props.AccountStore.loading && this.state.activeTab===3) && this.props.AccountStore.allCompletedOrders?.map((order,index)=>(
                                        <TableRow key={order.id} hover role="checkbox" onClick={()=>this.handleOrderSelect(order.id, order, index)} tabIndex={-1} selected={(this.state.selectedOrderId===order.id)}>
                                            <TableCell>
                                                {(this.isRecent(order.created_at))?(
                                                    <Badge badgeContent={this.props.LanguageStore.language.NEW} color="primary">
                                                        <SourceIcon source={"ya"}/>
                                                    </Badge>
                                                ):(
                                                        <SourceIcon source={"ya"}/>
                                                )}
                                                </TableCell>
                                            <TableCell>{order.id}</TableCell>
                                            <TableCell>{order.ordertype.type_name}</TableCell>
                                            <TableCell>{this.formatTimestamp(order.created_at)}</TableCell>
                                            <TableCell><Typography variant={"body1"}>{order.customer?.customer_name}</Typography>
                                                <Typography variant={"caption"} >{order.customer?.user?.phone}</Typography></TableCell>
                                            <TableCell>{(parseFloat(order.order_point_use)!==0)?(
                                                <><Typography variant={"body1"}>{(parseFloat(order.order_price)- parseFloat(order.order_point_use)).toFixed(2)}</Typography>
                                                    <Typography variant={"caption"} color={"secondary"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                </>
                                            ):(
                                                <Typography variant={"body1"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                            )}</TableCell>                                            <TableCell>{(order.status?.statu_name)}</TableCell>
                                        </TableRow>
                                    ))}
                                    {(!this.props.AccountStore.loading && this.state.activeTab===4) && this.props.AccountStore.allCancelledOrders?.map((order,index)=>(
                                        <TableRow key={order.id} hover role="checkbox" onClick={()=>this.handleOrderSelect(order.id, order, index)} tabIndex={-1} selected={(this.state.selectedOrderId===order.id)}>
                                            <TableCell>
                                                {(this.isRecent(order.created_at))?(
                                                    <Badge badgeContent={this.props.LanguageStore.language.NEW} color="primary">
                                                        <SourceIcon source={"ya"}/>
                                                    </Badge>
                                                ):(
                                                        <SourceIcon source={"ya"}/>
                                                )}
                                                </TableCell>
                                            <TableCell>{order.id}</TableCell>
                                            <TableCell>{order.ordertype.type_name}</TableCell>
                                            <TableCell>{this.formatTimestamp(order.created_at)}</TableCell>
                                            <TableCell><Typography variant={"body1"}>{order.customer?.customer_name}</Typography>
                                                <Typography variant={"caption"} >{order.customer?.user?.phone}</Typography></TableCell>
                                            <TableCell>{(parseFloat(order.order_point_use)!==0)?(
                                                <><Typography variant={"body1"}>{(parseFloat(order.order_price)- parseFloat(order.order_point_use)).toFixed(2)}</Typography>
                                                    <Typography variant={"caption"} color={"secondary"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                                </>
                                            ):(
                                                <Typography variant={"body1"} >{parseFloat(order.order_price).toFixed(2)}</Typography>
                                            )}</TableCell>                                            <TableCell>{(order.status?.statu_name)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item={true} xs={5} md={5} lg={5}>
                    {(this.state.selectedOrder)&& (
                        <Card className={classes.card} raised={false}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    YemekArena
                                </Typography>
                                <Typography variant="h5" component="h2" align={"center"}>
                                    {this.state.selectedOrder?.customer?.customer_name}
                                </Typography>
                                <Typography variant="caption" component={"p"} align={"center"}>
                                    {this.state.selectedOrder?.customer?.user?.phone}
                                </Typography>
                                <Typography variant="h6" component="h6" align={"left"}>
                                    {this.props.LanguageStore.language.ORDER}:
                                </Typography>
                                <Typography variant="body2" component="div" className={classes.orderContent}>
                                    <table width={"100%"} style={{backgroundColor:"#f0e8db"}}>
                                        <tbody>
                                        {
                                            this.state.selectedOrder?.order_json &&
                                            this.state.selectedOrder?.order_json.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.piece} x {item.product_name}
                                                            {
                                                                (item.selected).length > 0 &&
                                                                <span style={{width: '100%', padding:0, margin:0}} key={item.id + "c"} role={undefined}>
                                            {
                                                item.selected.map((i, index) => {
                                                    let b;
                                                    if (i.menu_type === 1) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0].product.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 5) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0].product.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 2 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 4 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p className={"lineThrough"}>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 3) {
                                                        let isl = i.selected;

                                                        if (isl[0]?.id > 0) {
                                                            let xb = i.selected[0].product.product_name + " " || " ";

                                                            let m = i.selected[0].selected?.map((ss) => {
                                                                let mm = "";
                                                                if (ss.menu_type === 1) {
                                                                    mm = ss.selected[0] != null ? ss.selected[0].product.product_name + " " : " ";
                                                                }
                                                                if (ss.menu_type === 2) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " ";
                                                                    })
                                                                }
                                                                if (ss.menu_type === 4) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " istemiyorum";
                                                                    })
                                                                }
                                                                return mm;
                                                            });
                                                            b = <div style={{width: '100%', padding:0, margin:0}} >
                                                                <p>- {xb}</p>
                                                                <p>{m + " "}</p>
                                                            </div>

                                                        }

                                                    }
                                                    return <div style={{width: '100%', padding:0, margin:0}}
                                                                key={index + 'sepet'}>{b}</div>
                                                })
                                            }
                                        </span>
                                                            }</td>
                                                        <td>{parseFloat(item.total).toFixed(2)}</td>
                                                        {/*<Divider />*/}
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </Typography>
                                {(parseFloat(this.state.selectedOrder?.order_point_use)!==0)?(
                                    <>
                                        <Typography align={"right"} variant={"body1"} color={"secondary"} >{parseFloat(this.state.selectedOrder?.order_price).toFixed(2)}</Typography>
                                        <Typography align={"right"} variant={"body1"}>-{parseFloat(this.state.selectedOrder?.order_point_use).toFixed(2)} yepara</Typography>
                                        <Typography variant={"h5"} align={"right"}>{(parseFloat(this.state.selectedOrder?.order_price)- parseFloat(this.state.selectedOrder?.order_point_use)).toFixed(2)}</Typography>
                                    </>
                                ):(
                                    <Typography variant={"h5"} align={"right"} >{parseFloat(this.state.selectedOrder?.order_price).toFixed(2)}</Typography>
                                )}
                                <Typography color="textSecondary">
                                    {this.props.LanguageStore.language.ADDRESS}: {this.state.selectedOrder?.address?.address_text},{this.state.selectedOrder?.address?.neighborhood?.name},{this.state.selectedOrder?.address?.district?.name},{this.state.selectedOrder?.address?.town?.name}
                                </Typography>
                                <Typography color="textSecondary">
                                    {this.props.LanguageStore.language.DIRECTION}: {this.state.selectedOrder?.address?.address_direction}
                                </Typography>
                                <Typography color="textSecondary">
                                    {this.props.LanguageStore.language.NOTES}:{this.state.selectedOrder.order_note?.map((note, index)=>(
                                    <p className={"note"} key={index}>{note.note_text}</p>
                                ))}
                                </Typography>
                                <Stepper activeStep={this.state.selectedOrder?.status?.id} alternativeLabel>
                                    <Step key={1}><StepLabel>{this.state.steps[0].statu_name}</StepLabel></Step>
                                    <Step key={2}><StepLabel>{this.state.steps[1].statu_name}</StepLabel></Step>
                                    {(this.state.selectedOrder?.status?.id!==4)&&(<Step key={3}><StepLabel>{this.state.steps[2].statu_name}</StepLabel></Step>)}
                                    {(this.state.selectedOrder?.status?.id===4)&&(<Step key={4}><StepLabel error={true}>{this.state.steps[3].statu_name}</StepLabel></Step>)}
                                    {(this.state.selectedOrder?.status?.id!==4)&&(<Step key={5}><StepLabel>{this.state.steps[4].statu_name}</StepLabel></Step>)}
                                </Stepper>
                            </CardContent>
                            <CardActions>
                                <ReactToPrint
                                    trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return(<Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<Print />}
                                        >
                                            {this.props.LanguageStore.language.PRINT}
                                        </Button>)
                                    }}
                                    content={() => this.state.printOrder}
                                />
                                <OrderToPrint data={this.state.selectedOrder} index={this.state.selectedOrderIndex}  ref={el => (this.state.printOrder = el)}/>
                                {/*{(this.state.selectedOrder.status.id===1 ||this.state.selectedOrder.status.id===2)&&(
                                    <Button size="large" variant={"contained"} color={"secondary"}>{this.props.LanguageStore.language.CANCEL}</Button>
                                )}*/}
                                <Button size="large" disabled={!(this.state.selectedOrder.status.id===1 ||this.state.selectedOrder.status.id===2)} variant={"contained"} color={"secondary"} onClick={async () => this.cancelOrderModal()}>{this.props.LanguageStore.language.CANCEL}</Button>

                                <Button size="large" disabled={!(this.state.selectedOrder.status.id===1 || this.state.selectedOrder.status.id===2)} variant={"contained"} color={"primary"} onClick={async () => this.acceptOrder()}>{this.props.LanguageStore.language.ACCEPT}</Button>
                                <Button size="large" disabled={(this.state.selectedOrder.status.id!==3)} variant={"contained"} color={"primary"} onClick={async () => this.completeOrder()}>{this.props.LanguageStore.language.COMPLETE}</Button>
                            </CardActions>
                        </Card>
                    )}
                </Grid>
                {/*Cancel Order Dialog/Model*/}
                <Dialog open={this.state.cancelOrderModal} fullWidth={true} onClose={()=>this.setState({cancelOrderModal:false, selectedReason:''})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.CANCEL_REASON_MSG}
                        </DialogContentText>
                        <FormControl className={classes.formControl} variant="outlined">
                            <InputLabel id="demo-simple-select-label">{this.props.LanguageStore.language.CANCEL_REASON}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedReason}
                                onChange={(e)=>this.setState({selectedReason:e.target.value})}
                                label={this.props.LanguageStore.language.REASON}
                            >
                                <MenuItem value={''}>None</MenuItem>
                                {(this.props.AccountStore.cancelReasons.length>0)&&(this.props.AccountStore.cancelReasons.map((reason)=>(
                                    <MenuItem key={reason.id} value={reason.cancel_r_type.id}>{reason.cancel_r_type.cancel_t_name}</MenuItem>
                                )))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> {this.setState({cancelOrderModal:false, selectedReason:''})}} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={!this.state.selectedOrderId || !this.state.selectedReason} onClick={async ()=> {await this.cancelOrder()}} color="primary">
                            {this.props.LanguageStore.language.ACCEPT}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>);
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
    },
    card: {
        width: '100%',
        margin:12,
        marginTop:6,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 544,
    },
    container: {
        maxHeight: 440,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    orderContent:{
        minHeight:200,
        maxHeight: 300,
        overflowY:"scroll"
    }
});

export default withStyles(useStyles, {withTheme: true})(Orders);
