import React, {useEffect} from 'react';
import './App.css';
import store from './store';
import {Provider} from 'mobx-react';
import Routes from './route';
import {SnackbarProvider} from "notistack";
import {APP_NAME} from "./constants";



function App() {

    useEffect(()=>{
        document.title=`${APP_NAME} Restaurant Management Panel`;
    });
  return (
      <Provider {...store}>
          <SnackbarProvider>
              <Routes />
          </SnackbarProvider>
      </Provider>
  );
}

export default App;
