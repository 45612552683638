import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {Add, ExpandMore} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

@inject('LayoutStore', 'AccountRoomAndTableStore', 'LanguageStore')
@observer
class RoomManagement extends Component {

    state = {
        expanded: "",
        addTableDialog:false,
        editTableDialog:false,
        addRoomDialog:false,
        editRoomDialog:false,
        active:true,
        tableId:null,
        tableName:"",
        roomId:null,
        roomName:""
    }

    handleChange(panel) {
        if (this.state.expanded === panel) {
            this.setState({expanded: ""});
        } else {
            this.setState({expanded: panel});
        }
    }

    async componentDidMount() {
        await this.props.AccountRoomAndTableStore.getRooms();
    }
    addTable(room){
        this.setState({addTableDialog:true, roomId:room});
    }
    updateTable(table,name, room, active){
        console.log(table, name, room, active);
        this.setState({editTableDialog:true, roomId:room, tableId:table, tableName:name, active:active});
    }
    async addTableSubmit() {
        await this.props.AccountRoomAndTableStore.addRoomTable(this.state.tableName,this.state.roomId, 1, 1, this.state.active);
        this.setState({addTableDialog: false, roomId: null, tableName: ""});
    }
    async addRoomSubmit() {
        await this.props.AccountRoomAndTableStore.addRoom(this.state.roomName, this.state.active);
        this.setState({addRoomDialog: false, roomId: null, roomName: ""});
    }
    async editRoomSubmit() {
        await this.props.AccountRoomAndTableStore.updateRoom(this.state.roomId,this.state.roomName, this.state.active);
        this.setState({editRoomDialog: false, roomId: null, roomName: ""});
    }
    async editTableSubmit() {
        await this.props.AccountRoomAndTableStore.updateRoomTable(this.state.tableId, this.state.tableName,this.state.roomId, 1, 1, this.state.active);
        this.setState({editTableDialog: false, roomId: null, tableId:null, tableName: ""});
    }
    render() {
        //console.log(toJS(this.props.AccountRoomAndTableStore.rooms));
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography variant={'h5'}>{this.props.LanguageStore.language.ROOM}:</Typography>
                <br/>
                <Backdrop className={classes.backdrop} open={this.props.AccountRoomAndTableStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Button variant="contained" color="primary" className={classes.button} onClick={()=>this.setState({addRoomDialog:true})} size="small" startIcon={<Add/>}>
                    {this.props.LanguageStore.language.ADD_ROOM}
                </Button>
                {(this.props.AccountRoomAndTableStore.rooms.map((room, index) => (
                    <Accordion expanded={this.state.expanded === room.id} key={index}
                               onChange={() => this.handleChange(room.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>{room.room_name}</Typography>
                            <Typography className={classes.secondaryHeading}>{room.room_tables.length}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Button variant="contained" color="primary" size="small" onClick={()=>this.addTable(room.id)} className={classes.button} startIcon={<Add/>}>
                                        {this.props.LanguageStore.language.ADD_TABLE}
                                    </Button>
                                    <Button variant="contained" color="secondary" size="small" onClick={()=>this.setState({editRoomDialog:true, roomId:room.id, roomName:room.room_name})} className={classes.button}>{this.props.LanguageStore.language.EDIT_ROOM}</Button>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid container spacing={1}>
                                        {(room.room_tables.map((table, index) => (
                                            <Grid item xs={6} md={4} lg={3} key={index}>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Typography className={classes.title} color="textSecondary"
                                                                    gutterBottom>
                                                            #{table.id}
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            {table.room_table_name}
                                                        </Typography>
                                                        <Typography className={classes.pos} color="textSecondary">
                                                            {this.props.LanguageStore.language.TOTAL}
                                                        </Typography>
                                                        <Typography variant="h5" component="h5">
                                                            {(table.room_table_order.reduce(function (a, b){return a+Number(b.order_price);},0))}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small" onClick={()=>this.updateTable(table.id, table.room_table_name, room.id, table.room_table_active)}>{this.props.LanguageStore.language.EDIT}</Button>
                                                        <Button size="small">{this.props.LanguageStore.language.DISABLE}</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )))}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                )))}

                {/*ADD ROOM*/}
                <Dialog open={this.state.addRoomDialog} onClose={()=>this.setState({addRoomDialog:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_ROOM}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.ADD_ROOM_DESC}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            value={this.state.roomName}
                            onChange={(e)=>this.setState({roomName:e.target.value})}
                            type="text"
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active}
                                    onChange={(event)=>this.setState({active:event.target.checked})}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={this.props.LanguageStore.language.ACTIVE}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({addRoomDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={(this.state.roomName.length<1)} onClick={()=>this.addRoomSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*EDIT ROOM*/}
                <Dialog open={this.state.editRoomDialog} onClose={()=>this.setState({editRoomDialog:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.EDIT_ROOM}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.EDIT_ROOM_DESC}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            value={this.state.roomName}
                            onChange={(e)=>this.setState({roomName:e.target.value})}
                            type="text"
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active}
                                    onChange={(event)=>this.setState({active:event.target.checked})}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={this.props.LanguageStore.language.ACTIVE}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({editRoomDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={(this.state.roomName.length<1)} onClick={()=>this.editRoomSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*ADD TABLE*/}
                <Dialog open={this.state.addTableDialog} onClose={()=>this.setState({addTableDialog:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_TABLE}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.ADD_TABLE_DESC}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            value={this.state.tableName}
                            onChange={(e)=>this.setState({tableName:e.target.value})}
                            type="text"
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active}
                                    onChange={(event)=>this.setState({active:event.target.checked})}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={this.props.LanguageStore.language.ACTIVE}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({addTableDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={(this.state.tableName.length<1)} onClick={()=>this.addTableSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*EDIT TABLE*/}
                <Dialog open={this.state.editTableDialog} onClose={()=>this.setState({editTableDialog:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.EDIT_TABLE}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.EDIT_TABLE_DESC}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={this.props.LanguageStore.language.NAME}
                            value={this.state.tableName}
                            onChange={(e)=>this.setState({tableName:e.target.value})}
                            type="text"
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active}
                                    onChange={(event)=>this.setState({active:event.target.checked})}
                                    name="active"
                                    color="primary"
                                />
                            }
                            label={this.props.LanguageStore.language.ACTIVE}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({editTableDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={(this.state.tableName.length<1)} onClick={()=>this.editTableSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: {
        margin: theme.spacing(2, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    button: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
    },
    pos: {
        marginBottom: 0,
    },

});

export default withStyles(useStyles, {withTheme: true})(RoomManagement);
