import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import 'date-fns';
import {getTime} from "date-fns";
import { fromUnixTime } from 'date-fns';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

@inject('SurveyStore', 'AccountStore','LanguageStore')
@observer
class SurveyList extends Component {
    state = {
        groupName:"",
        depend:0,
        addSurvey: false,
        updateSurvey: false,
        addGroup:false,
        title: "",
        surveyId:null,
        deleteDialog:false,
        start: new Date(),
        end: new Date(Date.now() + ( 3600 * 1000 * 24)),
        active: true,
        group: "-1",
        description: ""
    }

    async componentDidMount() {
        await this.props.SurveyStore.getGroups();
        await this.props.AccountStore.getAccount();
    }

    format_time(s) {
        return new Date(s).toLocaleDateString();
    }
    handleSurveyDelete(id){
        this.props.SurveyStore.deleteSurvey(id);
        this.setState({surveyId:null, deleteDialog:false});
    }
    addGroup(){
            console.log(this.state);
            this.props.SurveyStore.addGroup(this.state.groupName, this.state.depend);
            this.setState({addGroup:false, groupName:"", depend:0});
    }
    dialogSubmit() {
        console.log(this.state);
        this.props.SurveyStore.addSurvey(this.props.AccountStore.brand.id, this.state.group, this.state.title, this.state.description, this.state.active, format(this.state.start, "yyyy-MM-dd"), format(this.state.end, "yyyy-MM-dd"));
        this.setState({addSurvey: false,
            title: "",
            start: new Date(),
            end: new Date(Date.now() + ( 3600 * 1000 * 24)),
            active: true,
            group: "-1",
            description: ""});

    }

    updateDialogSubmit(){
        console.log(this.state);
        this.props.SurveyStore.updateSurvey(this.state.surveyId, this.state.group, this.state.title, this.state.description, this.state.active, format(this.state.start, "yyyy-MM-dd"), format(this.state.end, "yyyy-MM-dd"));
        this.setState({updateSurvey: false,
            title: "",
            start: new Date(),
            surveyId:null,
            end: new Date(Date.now() + ( 3600 * 1000 * 24)),
            active: true,
            group: "-1",
            description: ""});
    }
    render() {
        const {classes} = this.props;
        //console.log(toJS(this.props.SurveyStore.groups));
        const handleStartDateChange = (date) => {
            this.setState({start:getTime(date)});
        };
        const handleEndDateChange = (date) => {
            this.setState({end:getTime(date)});
        };
        return (
            <Paper className={classes.root}>
                <Backdrop className={classes.backdrop} open={this.props.SurveyStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <TableContainer className={classes.container}>
                    <Button variant={"contained"} color={"primary"} className={classes.addSurvey}
                            onClick={() => this.setState({addSurvey: true})}>{this.props.LanguageStore.language.ADD_SURVEY}</Button>
                    <Button variant={"contained"} color={"primary"} className={classes.addSurvey}
                            onClick={() => this.setState({addGroup: true})}>{this.props.LanguageStore.language.ADD_SURVEY_GROUP}</Button>
                    <Table stickyHeader aria-label="sticky table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{this.props.LanguageStore.language.GROUP}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.SURVEY}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.QUESTION}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.ACTIVE}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.START}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.END}</TableCell>
                                <TableCell>{this.props.LanguageStore.language.ACTION}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.SurveyStore.groups.map((group => (
                                Array.isArray(group.surveys) && group.surveys.map((survey) => (
                                    <TableRow hover role="checkbox" tabIndex={-1}
                                              key={survey.id}>
                                        <TableCell onClick={() => this.props.history.push(`/surveys/${survey.id}`)}>{group.group_name}</TableCell>
                                        <TableCell onClick={() => this.props.history.push(`/surveys/${survey.id}`)}>{survey.survey_title}</TableCell>
                                        <TableCell>{survey.survey_questions.length}</TableCell>
                                        <TableCell>{(survey.survey_active) ? (this.props.LanguageStore.language.ACTIVE) : ('')}</TableCell>
                                        <TableCell>{this.format_time(Number(survey.survey_start))}</TableCell>
                                        <TableCell>{this.format_time(Number(survey.survey_end))}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="edit"
                                                        onClick={() => this.setState({updateSurvey: true,
                                                            surveyId:survey.id,
                                                            title: survey.survey_title,
                                                            description:survey.survey_description,
                                                            group: survey.survey_group,
                                                            start: fromUnixTime(survey.survey_start/1000),
                                                            end: fromUnixTime(survey.survey_end/1000)
                                                        })}>
                                                <Edit/>
                                            </IconButton><IconButton aria-label="delete"
                                                                     onClick={()=>this.setState({deleteDialog:true, surveyId:survey.id})}>
                                            <Delete/>
                                        </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ))))}

                        </TableBody>
                    </Table>
                </TableContainer>
                {/*ADD SURVEY*/}
                <Dialog open={this.state.addSurvey} onClose={() => this.setState({addSurvey: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_SURVEY}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.PLEASE_FILL}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.GROUP}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.group}
                                onChange={(event, child) => this.setState({group: event.target.value})}
                                label={this.props.LanguageStore.language.TYPE}
                            >
                                <MenuItem value="-1">
                                    <em>None</em>
                                </MenuItem>
                                {(this.props.SurveyStore.groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>{group.group_name}</MenuItem>
                                )))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="title"
                            value={this.state.title}
                            onChange={(e) => this.setState({title: e.target.value})}
                            label={this.props.LanguageStore.language.TITLE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label={this.props.LanguageStore.language.DESCRIPTION}
                            variant={"outlined"}
                            value={this.state.description}
                            onChange={(e) => this.setState({description: e.target.value})}
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="start-date-picker-dialog"
                                    label={this.props.LanguageStore.language.START}
                                    format="dd/MM/yyyy"
                                    value={this.state.start}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="end-date-picker-dialog"
                                    label={this.props.LanguageStore.language.END}
                                    format="dd/MM/yyyy"
                                    value={this.state.end}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({addSurvey: false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button
                            disabled={!(this.state.title && this.state.description && this.state.group !== "-1")}
                            onClick={() => this.dialogSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*UPDATE SURVEY*/}
                <Dialog open={this.state.updateSurvey} onClose={() => this.setState({updateSurvey: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.UPDATE_SURVEY}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.PLEASE_FILL}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.GROUP}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.group}
                                onChange={(event, child) => this.setState({group: event.target.value})}
                                label={this.props.LanguageStore.language.TYPE}
                            >
                                <MenuItem value="-1">
                                    <em>None</em>
                                </MenuItem>
                                {(this.props.SurveyStore.groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>{group.group_name}</MenuItem>
                                )))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="title"
                            value={this.state.title}
                            onChange={(e) => this.setState({title: e.target.value})}
                            label={this.props.LanguageStore.language.TITLE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label={this.props.LanguageStore.language.DESCRIPTION}
                            variant={"outlined"}
                            value={this.state.description}
                            onChange={(e) => this.setState({description: e.target.value})}
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="start-date-picker-dialog"
                                    label={this.props.LanguageStore.language.START}
                                    format="dd/MM/yyyy"
                                    value={this.state.start}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="end-date-picker-dialog"
                                    label={this.props.LanguageStore.language.END}
                                    format="dd/MM/yyyy"
                                    value={this.state.end}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({updateSurvey: false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button
                            disabled={!(this.state.title && this.state.description && this.state.group !== "-1")}
                            onClick={() => this.updateDialogSubmit()} color="primary">
                            {this.props.LanguageStore.language.UPDATE}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*ADD SURVEY GROUP*/}
                <Dialog open={this.state.addGroup} onClose={() => this.setState({addGroup: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_QUESTION_GROUP}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.PLEASE_FILL}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.DEPENDENT}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.group}
                                onChange={(event, child) => this.setState({group: event.target.value})}
                                label={this.props.LanguageStore.language.TYPE}
                            >
                                <MenuItem value="-1">
                                    <em>None</em>
                                </MenuItem>
                                {(this.props.SurveyStore.groups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>{group.group_name}</MenuItem>
                                )))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="title"
                            value={this.state.groupName}
                            onChange={(e) => this.setState({groupName: e.target.value})}
                            label={this.props.LanguageStore.language.TITLE}
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({addGroup: false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button
                            disabled={!(this.state.groupName)}
                            onClick={() => this.addGroup()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*DELETE CONFIRM*/}
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.deleteDialog}
                >
                    <DialogTitle id="confirmation-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant={"body1"}>{this.props.LanguageStore.language.DELETE_SURVEY_DESC}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={()=>this.setState({deleteDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={()=>this.handleSurveyDelete(this.state.surveyId)} color="primary">
                            {this.props.LanguageStore.language.DELETE}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    addSurvey: {
        margin: "10px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        maxHeight: '100%',
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(SurveyList));
