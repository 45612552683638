import React, {Component} from "react";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Footer from "../../components/layout/Footer";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";

@inject("AuthStore","LanguageStore")
@observer
class ForgotPassword extends Component{
    state={
        email:""
    }
    validateForm() {
        return this.state.email.length > 0;
    }
    async handleSubmit() {
        await this.props.AuthStore.forgotPassword(this.state.email);
        this.props.history.push({pathname: `/update-password/${this.state.email}`});
        //await this.setState({email:""});
    }
    render() {
        const {classes}=this.props;
        return(
            <Container component="main" maxWidth="xs">
                <Backdrop className={classes.backdrop} open={this.props.AuthStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {this.props.LanguageStore.language.FORGOT_PASSWORD}
                    </Typography>
                    <Typography color={'primary'} component={'p'}>{this.props.AuthStore.message}</Typography>
                    {this.props.AuthStore.errors?.map((error, i)=>(
                        <Typography key={i} color={'secondary'} component={'p'}>{error.message}</Typography>
                    ))}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={this.props.LanguageStore.language.EMAIL}
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={e => this.setState({email: e.target.value})}
                            autoFocus
                        />
                        <Button fullWidth variant="contained" color="primary" onClick={()=>this.handleSubmit()} className={classes.submit} disabled={!this.validateForm()}>
                            {this.props.LanguageStore.language.SEND}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#/login" variant="body2">
                                    Login
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                </div>
                <Box mt={8}>
                    <Footer/>
                </Box>
            </Container>
        );
    }
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
export default withRouter(withStyles(useStyles, { withTheme: true })(ForgotPassword));
