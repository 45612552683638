import React, {Component} from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {toJS} from "mobx";
import moment from "moment";
import {inject, observer} from "mobx-react";
import {Skeleton} from "@material-ui/lab";

@inject("AccountStore","LanguageStore")
@observer
class CollapsableRow extends Component {
    state = {
        open: false
    }

    async componentDidMount() {
        await this.props.AccountStore.getOrder(this.props.order.id);

    }

    render() {
        const {classes, rowData, rowMeta} = this.props;
        const order = this.props.AccountStore.order;
        console.log(toJS(order));
        return (
            <TableRow>
                <TableCell colSpan={(rowData.length + 1)}>
                    {this.props.AccountStore.loading ? (<Loading/>) : (<Box margin={5} width={"max-content"}>
                        <Typography variant="h6" gutterBottom component="div">
                            Order Details
                        </Typography>
                        <Typography variant="body2" gutterBottom component="p">
                            Costumer name: <b>{order?.customer?.customer_name}</b>
                        </Typography><Typography variant="body2" gutterBottom component="p">
                        Address name: {order?.address?.address_name}
                    </Typography>
                        <Typography variant="body2" gutterBottom component="p">
                            Phone: <b>{order?.address?.address_phone}</b>
                        </Typography>
                        <Table width={"100%"} style={{backgroundColor: "#f0e8db", marginRight: 10}}>
                            <TableBody>
                                {
                                    order?.order_json &&
                                    order?.order_json.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.piece} x {item.product_name}
                                                    {
                                                        (item.selected).length > 0 &&
                                                        <span style={{width: '100%', padding: 0, margin: 0}}
                                                              key={item.id + "c"} role={undefined}>
                                            {
                                                item.selected.map((i, index) => {
                                                    let b;
                                                    if (i.menu_type === 1) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0]?.product?.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 5) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0]?.product?.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 2 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 4 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p className={"lineThrough"}>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 3) {
                                                        let isl = i.selected;

                                                        if (isl[0]?.id > 0) {
                                                            let xb = i.selected[0].product.product_name + " " || " ";

                                                            let m = i.selected[0].selected?.map((ss) => {
                                                                let mm = "";
                                                                if (ss.menu_type === 1) {
                                                                    mm = ss.selected[0] != null ? ss.selected[0].product.product_name + " " : " ";
                                                                }
                                                                if (ss.menu_type === 2) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " ";
                                                                    })
                                                                }
                                                                if (ss.menu_type === 4) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " istemiyorum";
                                                                    })
                                                                }
                                                                return mm;
                                                            });
                                                            b = <div style={{width: '100%', padding: 0, margin: 0}}>
                                                                <p>- {xb}</p>
                                                                <p>{m + " "}</p>
                                                            </div>

                                                        }

                                                    }
                                                    return <div style={{width: '100%', padding: 0, margin: 0}}
                                                                key={index + 'sepet'}>{b}</div>
                                                })
                                            }
                                        </span>
                                                    }</TableCell>
                                                <td>{parseFloat(item.total).toFixed(2)} ₺</td>
                                                {/*<Divider />*/}
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        <Typography color="textSecondary">
                            {this.props.LanguageStore.language.ADDRESS}: {order?.address?.address_text},{order?.address?.neighborhood?.name},{order?.address?.district?.name},{order?.address?.town?.name}
                        </Typography>
                        <Typography color="textSecondary">
                            {this.props.LanguageStore.language.DIRECTION}: {order?.address?.address_direction}
                        </Typography>
                        <Typography color="textSecondary">
                            {this.props.LanguageStore.language.NOTES}:{order.order_note?.map((note, index)=>(
                            <p className={"note"} key={index}>{note.note_text}</p>
                        ))}
                        </Typography>
                    </Box>)}
                </TableCell>
            </TableRow>
        );
    }
}

function Loading() {
    return (<>
        <Skeleton variant="text" width={210}/>
        <Skeleton variant="text" width={210}/>
        <Skeleton variant="text" width={210}/>
        <Skeleton variant="text" width={210}/>
        <Skeleton variant="rect" width={210} height={118}/>
    </>);
}

const useStyles = ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default withStyles(useStyles, {withTheme: true})(CollapsableRow);
