import React, {Component} from "react";
import moment from "moment/moment";
import {inject, observer} from "mobx-react";
import {LANGUAGE} from "../../constants";
import {Typography} from "@material-ui/core";

@inject("LanguageStore")
@observer
class OrderToPrint extends Component{
    render() {
        let {data}=this.props;
        //console.log(toJS(data));
        let date = moment(parseInt((data.order_receive_time)?(data.order_receive_time):(0)));
        //console.log(date.format("DD/MM/YYYY"));
        return(<div className={"print-order"}>
            <ul className={"without-bullet"}>
                <li><b>{this.props.LanguageStore.language.DATE}</b>: {date.format("DD/MM/YYYY")}</li>
                <li><b>{this.props.LanguageStore.language.TIME}</b>: {date.format("HH:mm:ss")}</li>
                <li><b>{this.props.LanguageStore.language.RECEIPT_NO}</b>: {data.id}</li>
                <li><b>{this.props.LanguageStore.language.NAME}</b>: {data.customer?.customer_name}</li>
                <li><b>{this.props.LanguageStore.language.CASHIER}</b>:</li>
            </ul>
            <hr/>
            <table width={"100%"}>
                <tbody>
                {
                    data.order_json &&
                    data.order_json.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.piece} x {item.product_name}
                                    {
                                        (item.selected).length > 0 &&
                                        <span style={{width: '100%', padding:0, margin:0}} key={item.id + "c"} role={undefined}>
                                            {
                                                item.selected.map((i, index) => {
                                                    let b;
                                                    if (i.menu_type === 1) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0].product.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 5) {
                                                        b = <p>
                                                            - {i.selected != null ? i.selected[0].product.product_name + " " : " "}
                                                        </p>;
                                                    }
                                                    if (i.menu_type === 2 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 4 && i.selected.length > 0) {
                                                        let yb = i.selected.map((s) => {
                                                            return s.product.product_name + " ";
                                                        });
                                                        b = <p className={"lineThrough"}>- {yb}</p>
                                                    }
                                                    if (i.menu_type === 3) {
                                                        let isl = i.selected;

                                                        if (isl[0]?.id > 0) {
                                                            let xb = i.selected[0].product.product_name + " " || " ";

                                                            let m = i.selected[0].selected?.map((ss) => {
                                                                let mm = "";
                                                                if (ss.menu_type === 1) {
                                                                    mm = ss.selected[0] != null ? ss.selected[0].product.product_name + " " : " ";
                                                                }
                                                                if (ss.menu_type === 2) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " ";
                                                                    })
                                                                }
                                                                if (ss.menu_type === 4) {
                                                                    mm = ss.selected.map((mms) => {
                                                                        return mms.product.product_name + " istemiyorum";
                                                                    })
                                                                }
                                                                return mm;
                                                            });
                                                            b = <div style={{width: '100%', padding:0, margin:0}} >
                                                                <p>- {xb}</p>
                                                                <p>{m + " "}</p>
                                                            </div>

                                                        }

                                                    }

                                                    return <div style={{width: '100%', padding:0, margin:0}}
                                                                key={index + 'sepet'}>{b}</div>
                                                })
                                            }
                                        </span>
                                    }</td>
                                <td>{parseFloat(item.total).toFixed(2)}</td>
                                {/*<Divider />*/}
                            </tr>
                        )
                    })
                }
                </tbody>
                <tfoot style={{fontWeight:"bold"}}>

                {(parseFloat(data?.order_point_use)!==0)?(
                    <>
                        <tr>
                            <td>{this.props.LanguageStore.language.PRICE}:</td>
                            <td>{parseFloat(data.order_price).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>{this.props.LanguageStore.language.DISCOUNT}:</td>
                            <td>-{parseFloat(data?.order_point_use).toFixed(2)} yepara</td>
                        </tr>
                        <tr>
                            <td>{this.props.LanguageStore.language.TOTAL}:</td>
                            <td style={{fontSize:"14"}}>{(parseFloat(data?.order_price)- parseFloat(data?.order_point_use)).toFixed(2)}</td>
                        </tr>
                    </>
                ):(
                    <tr>
                        <td>{this.props.LanguageStore.language.TOTAL}:</td>
                        <td>{parseFloat(data.order_price).toFixed(2)}</td>
                    </tr>
                )}
                </tfoot>
            </table>
            <hr/>
            <h5>Yemekarena - {data.customer?.customer_name} - {data.customer?.user.phone}</h5>
            <h6>{this.props.LanguageStore.language.PAYMENT_TYPE}: {data.orderpayrule?.payrule_name}</h6>
            <h6>{this.props.LanguageStore.language.ADDRESS}:</h6>
            {(data.address) && (<p className={"address"}>{data.address?.address_text}, {data.address?.neighborhood.name}, {data.address?.district.name}, {data.address?.town.name}</p>)}
            <h6>{this.props.LanguageStore.language.DIRECTION}:</h6>
            {(data.address) && (<p className={"direction"}>{data.address?.address_direction}</p>)}
            <h6>{this.props.LanguageStore.language.NOTES}:</h6>
            {data.order_note?.map((note, index)=>(
                <p className={"note"} key={index}>{note.note_text}</p>
            ))}
            <hr/>
            <h5 style={{width:"100%",textAlign:"center"}}>{this.props.LanguageStore.language.THANKS}</h5>
            {(LANGUAGE==="tr")&&(<h5 style={{width:"100%",textAlign:"center"}}>YEMEKARENA - FastRest - www.begumyazilim.comtr</h5>)}
        </div>);
    }
}

export default OrderToPrint;
