import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";

@inject('LayoutStore', 'AddProductStore','LanguageStore')
@observer
class DeleteProductMenu extends Component{
    handleClose(){
        this.props.LayoutStore.toggleDeletePMModal(null);
    }
    async handleConfirm() {
        console.log(this.props.LayoutStore.PMId);
        await Promise.all(this.props.LayoutStore.PMItems.map(async (item, index)=>{
            await this.props.AddProductStore.deleteProductMenuItem(item.id, this.props.productId);
        }));
        await this.props.AddProductStore.deleteProductMenu(this.props.LayoutStore.PMId, this.props.productId);
        this.props.LayoutStore.toggleDeletePMModal(null, []);
    }
    render() {
        return(<Dialog
                open={this.props.LayoutStore.deletePMModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.LanguageStore.language.DELETE_PRODUCT_MENU}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.LanguageStore.language.DELETE_PRODUCT_MENU_DESC}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.handleClose()}} disabled={this.props.AddProductStore.loading} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button onClick={()=>{this.handleConfirm()}} disabled={this.props.AddProductStore.loading} color="secondary" autoFocus>
                        {this.props.LanguageStore.language.DELETE}
                    </Button>
                </DialogActions>
            </Dialog>);
    }
}

export default DeleteProductMenu;
