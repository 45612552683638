import React, {Component} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {ExpandMore, FiberManualRecord, Grain, MenuOpen, RemoveCircle, SelectAll} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import MenuChoicesTable from "./MenuChoicesTable";
import {inject, observer} from "mobx-react";

function MenuIconAssigner({type}) {
    if (type===1){
        return (<FiberManualRecord/>);
    }else if(type===2){
        return (<Grain/>);
    }else if(type===3){
        return (<MenuOpen/>);
    }else if(type===4){
        return (<RemoveCircle/>);
    }else if(type===5){
        return (<SelectAll/>);
    }
}
@inject("LanguageStore")
@observer
class ProductMenu extends Component {
    render() {
        //console.log(this.props);
        const {classes} = this.props;
        const {data}=this.props;
        return (
            <div className={classes.root}>
                {(data && data.length>0) && data.map((menu)=>(
                <Accordion key={`${menu.id}`}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <MenuIconAssigner type={menu.menu_type}/>&nbsp;
                        <Typography className={classes.heading}>{menu.menu_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MenuChoicesTable productId={this.props.productId} sort={menu.menu_sort} data={menu.product_items} menuId={menu.id} menuType={menu.menu_type}/>
                    </AccordionDetails>
                </Accordion>))}
            </div>);
    }
}

const useStyles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});


export default withStyles(useStyles, {withTheme: true})(ProductMenu);
