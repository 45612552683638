import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {toJS} from "mobx";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {inject, observer} from "mobx-react";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {AppBar, Dialog, IconButton, Slide} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {Close} from "@material-ui/icons";
import ImageUpload from "./ImageUpload";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
@inject('ProductGroupStore', 'AddProductStore', 'LanguageStore','CDNStore')
@observer
class EditProduct extends Component {

    state = {
        productId: null,
        productName: "",
        productNumber: "",
        productActive: true,
        isMenu:false,
        productType: "",
        mainGroupName: "",
        mainGroupId: "-1",
        imageUrl: "https://dummyimage.com/600x400/000/fff"
    }

    componentDidMount() {
        this.props.ProductGroupStore.getProductGroups(-1);
        this.props.AddProductStore.getProductTypes();
        this.props.AddProductStore.getProduct(this.props.match.params.id).then(r => {
            this.setState({
                productName: this.props.AddProductStore.product.product_name,
                productNumber: this.props.AddProductStore.product.product_number,
                productType: this.props.AddProductStore.product.product_type.id,
                imageUrl: this.props.AddProductStore.product.img_url,
                isMenu: this.props.AddProductStore.product.product_is_menu,
                productId: this.props.match.params.id,
                mainGroupId: this.props.AddProductStore.product?.product_group?.id
            })
        });

        this.props.AddProductStore.history = this.props.history;

    }

    handleSubmit() {
        console.log(this.state);
        this.props.AddProductStore.updateProduct(this.state.productId, this.state.mainGroupId, this.state.productType, this.state.productName, Number(this.state.productNumber), this.state.productActive, (this.props.CDNStore.imageUploaded)?(this.props.CDNStore.imageUploaded):(this.state.imageUrl), this.state.isMenu).then(r => {
            this.props.CDNStore.resetImageUploaded();
            this.props.ProductGroupStore.getProductGroups(-1);
        });
    }


    render() {
        const {classes} = this.props;
        return (
            <div>
                <Backdrop className={classes.backdrop} open={this.props.AddProductStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={3}>
                            <Card className={classes.productImg} elevation={0} onClick={()=>this.props.CDNStore.toggleDialog()}>
                                {(this.props.CDNStore.imageUploaded)?(
                                    <CardMedia
                                        component="img"
                                        alt={`${this.props.AddProductStore.product.product_name}`}
                                        image={`${this.props.CDNStore.imageUploaded}`}
                                        title={`${this.props.AddProductStore.product.product_name}`}
                                    />
                                ):(
                                    <CardMedia
                                        component="img"
                                        alt={`${this.props.AddProductStore.product.product_name}`}
                                        image={`${(this.props.AddProductStore.product.img_url) ? (this.props.AddProductStore.product.img_url) : ("https://dummyimage.com/240x180/fff/aaa")}`}
                                        title={`${this.props.AddProductStore.product.product_name}`}
                                    />
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={8} lg={9}>
                            {/*ProductForm*/}
                            <Typography variant="overline" display="inline">{this.state.mainGroupName}</Typography>
                            <List component="nav"
                                  subheader={this.props.LanguageStore.language.PLEASE_FILL}>
                                <ListItem>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel id="product-group-label">{this.props.LanguageStore.language.PRODUCT_GROUP}</InputLabel>
                                        <Select
                                            labelId="product-group-label"
                                            id="product-group"
                                            label={this.props.LanguageStore.language.PRODUCT_GROUP}
                                            value={(this.state.mainGroupId) ? (this.state.mainGroupId) : "-1"}
                                            onChange={(event) => {

                                                this.setState({mainGroupId: event.target.value});
                                            }}
                                            fullWidth={true}
                                        >
                                            <MenuItem value={"-1"}>
                                                <em>None</em>
                                            </MenuItem>
                                            {((toJS(this.props.ProductGroupStore.productGroups)) && ((Array.isArray(toJS(this.props.ProductGroupStore.productGroups))) ? (
                                                toJS(this.props.ProductGroupStore.productGroups).map((group) => (
                                                    <MenuItem key={group.id}
                                                              value={group.product_group_main.id}>{group.product_group_main.main_group_name}</MenuItem>
                                                ))
                                            ) : null))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <br/>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel id="product-type-label">{this.props.LanguageStore.language.PRODUCT_TYPE}</InputLabel>
                                        <Select
                                            labelId="product-type-label"
                                            id="product-type"
                                            label={this.props.LanguageStore.language.PRODUCT_TYPE}
                                            value={this.state.productType}
                                            onChange={(event) => {

                                                this.setState({productType: event.target.value});
                                            }}
                                            fullWidth={true}
                                        >
                                            {((toJS(this.props.AddProductStore.productTypes)) && ((Array.isArray(toJS(this.props.AddProductStore.productTypes))) ? (
                                                toJS(this.props.AddProductStore.productTypes).map((type) => (
                                                    <MenuItem key={type.id} value={type.id}>{type.type_name}</MenuItem>
                                                ))
                                            ) : null))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        label={this.props.LanguageStore.language.NAME}
                                        id="name"
                                        autoFocus={true}
                                        value={this.state.productName}
                                        placeholder={this.props.LanguageStore.language.PRODUCT_NAME}
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            this.setState({productName: event.target.value});
                                        }}
                                        fullWidth={true}
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        label={this.props.LanguageStore.language.PRODUCT_NUMBER}
                                        id="product-number"
                                        value={this.state.productNumber}
                                        placeholder={this.props.LanguageStore.language.PRODUCT_NUMBER}
                                        variant="outlined"
                                        size="small"
                                        type={'number'}
                                        onChange={(event) => {
                                            this.setState({productNumber: event.target.value});
                                        }}
                                        fullWidth={true}
                                    />
                                </ListItem>
                                <ListItem>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.isMenu} onChange={(event) => {
                                                this.setState({isMenu: event.target.checked});
                                            }} name="is_menu"/>}
                                            label={"Menu"}/>
                                    </FormGroup>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.productActive} onChange={(event) => {
                                                this.setState({productActive: event.target.checked});
                                            }} name="product_active"/>}
                                            label={this.props.LanguageStore.language.ACTIVE}/>
                                    </FormGroup>
                                </ListItem>
                                <ListItem>
                                    <Button variant="outlined"
                                            disabled={!(this.state.productName && this.state.mainGroupId && this.state.productType)}
                                            onClick={() => {
                                                this.handleSubmit()
                                            }}
                                            fullWidth>{this.props.LanguageStore.language.SAVE}</Button>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/*More details*/}
                    </Grid>
                </Grid>
                <Dialog fullScreen open={this.props.CDNStore.imageUploadDialog} onClose={()=>this.props.CDNStore.toggleDialog()} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={()=>this.props.CDNStore.toggleDialog()} aria-label="close">
                                <Close />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Image Upload
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container>
                        <Grid item xs={10} md={10} lg={10}>
                            <ImageUpload/>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
        );
    }
}

const useStyles = theme => ({
    productImg: {
        maxWidth: 240,
        paddingLeft: "10px"
    },
    imageUpload: {
        padding: "6px"
    },
    dropzoneText: {
        fontSize: "small"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

export default withRouter(withStyles(useStyles, {withTheme: true})(EditProduct));
