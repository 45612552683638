import {action, observable, runInAction} from "mobx";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import {API_BASE} from "../constants";
import LayoutStore from "./LayoutStore";
import {GET_ACTIVE_GROUPS} from "../graphql/Queries";
import {ADD_ACTIVE_GROUP, UPDATE_ACTIVE_GROUP} from "../graphql/Mutations";

class ActiveGroupStore{
    @observable loading=false;
    @observable activeGroups=[];

    @action async getActiveGroups(){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `{${GET_ACTIVE_GROUPS(parseInt(accountId))}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: AccountStore at toggleActiveGroup!");
                runInAction(()=>LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: ActiveGroupStore at getActiveGroups!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            this.activeGroups=data.data.product_active_groups;
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false
            //alert("OOPS. Something went wrong on: AccountStore at toggleActiveGroup!");
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: ActiveGroupStore at getActiveGroups!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }

    @action async addActiveGroup(name, active=0, start, end){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${ADD_ACTIVE_GROUP(name, parseInt(accountId),active, start,end)}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: ActiveGroup at addActiveGroup!");
                runInAction(()=>LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: ActiveGroup at addActiveGroup!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            await this.getActiveGroups();
            this.loading = false;
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'Added Active Group Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            //alert("OOPS. Something went wrong on: ActiveGroup at addActiveGroup!");
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: ActiveGroup at addActiveGroup!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
    @action async updateActiveGroup(id,name, start, end){
        try {
            this.loading = true;
            const accountId = await AsyncStorage.getItem('accountNo');
            const token = await AsyncStorage.getItem('token');
            if (!accountId || !token) {
                return false;
            }
            const {data} = await axios({
                url: `${API_BASE}`,
                headers: {
                    'Authorization': token,
                },
                method: "post",
                data: {
                    query: `mutation {${UPDATE_ACTIVE_GROUP(parseInt(id),name, parseInt(accountId), start,end)}}`
                }
            });
            if (data.errors) {
                //alert("OOPS. Something went wrong on: ActiveGroup at addActiveGroup!");
                runInAction(()=>LayoutStore.enqueueSnackbar({
                    message: 'OOPS. Something went wrong on: ActiveGroup at updateActiveGroup!',
                    options: {
                        variant: 'error',
                    },
                }));
                return false;
            }
            await this.getActiveGroups();
            this.loading = false;
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'Updated Active Group Successfully!',
                options: {
                    variant: 'success',
                },
            }));
        } catch (e) {
            console.log(e);
            this.loading = false
            //alert("OOPS. Something went wrong on: ActiveGroup at addActiveGroup!");
            runInAction(()=>LayoutStore.enqueueSnackbar({
                message: 'OOPS. Something went wrong on: ActiveGroup at updateActiveGroup!',
                options: {
                    variant: 'error',
                },
            }));
        }
    }
}

export default new ActiveGroupStore();
