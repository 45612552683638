import React, {Component} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import {
    Add,
    CheckCircle,
    CreditCard,
    Fastfood,
    HighlightOff,
    Money,
    RemoveCircle,
    Schedule,
    Web
} from "@material-ui/icons";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import * as PropTypes from "prop-types";
import {toJS} from "mobx";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import {
    FormControl, FormControlLabel,
    FormGroup, FormLabel,
    Paper, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function conditionalIcons(id) {
    switch (id) {
        case "1":
            return (<Money/>);
        case "2":
            return (<CreditCard/>);
        case "3":
            return (<Fastfood/>);
        case "4":
            return (<Web/>);
        case "5":
            return (<Schedule/>);
        default:
            return (<Money/>)
    }
}
function conditionalNames(id, language){
    switch (id) {
        case 0:
            return(language.ALL);
        case 1:
            return (language.PARCEL);
        case 2:
            return (language.TAKEAWAY);
        case 3:
            return (language.EATIN);
    }
}
class DeleteIcon extends Component {
    render() {
        return null;
    }
}

DeleteIcon.propTypes = {fontSize: PropTypes.string};

@inject('AccountStore', 'LanguageStore', 'TicketStore')
@observer
class PayRule extends Component {

    state = {
        value: 0,
        addPayRuleModal: false,
        removePayRuleModal: false,
        payRuleId: null,
        eatin:true,
        takeaway:true,
        delivery:true,
        id: null,
        payRules: [],
        suggestThis: "",
    }

    async componentDidMount() {
        await this.props.AccountStore.getPayRules();
        this.setState({payRules: this.props.AccountStore.payRules})
        await this.props.AccountStore.getAccountPayRules();
    }

    suggestTicket(value) {
        console.log("please create ticket", value);
        this.props.TicketStore.suggestedTicket(`"${value.inputValue}"`, "Yeni ödeme yöntemi ekleme isteği", 386);
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    handleCloseModal() {
        this.setState({addPayRuleModal: false});
    }

    objectPropInArray(list, val) {
        if (list.length > 0) {
            for (let i in list) {
                if (list[i].orderpayrule.id === val) {
                    return true;
                }
            }
        }
        return false;
    }

    async handleAddModalConfirm() {
        //console.log("payrules", toJS(this.props.AccountStore.accountPayRules));
        //console.log("is it",this.objectPropInArray(toJS(this.props.AccountStore.accountPayRules),`${(this.state.payRuleId)}`));

        if (!this.objectPropInArray(toJS(this.props.AccountStore.accountPayRules), `${(this.state.payRuleId)}`)) {
            await this.props.AccountStore.addPayRule(this.state.payRuleId, this.state.delivery | 0 , this.state.takeaway| 0 , this.state.eatin|0 ).then(r => this.setState({
                addPayRuleModal: false,
                payRuleId: null
            }));
            await this.props.AccountStore.getAccountPayRules();
        } else {
            this.setState({
                addPayRuleModal: false,
                payRuleId: null
            });
            await this.props.AccountStore.getAccountPayRules();
        }
    }

    async handleRemoveModalConfirm() {

        await this.props.AccountStore.removePayRule(this.state.id).then(r => this.setState({
            removePayRuleModal: false,
            id: null
        }));
        await this.props.AccountStore.getAccountPayRules();

    }

    render() {
        const {classes} = this.props;
        const filter = createFilterOptions();
        console.log(toJS(this.props.AccountStore.accountPayRules));
        return (<>
            <Button
                    onClick={() => this.setState({addPayRuleModal: true})} startIcon={<Add/>}>
                {this.props.LanguageStore.language.ADD}
            </Button>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">{this.props.LanguageStore.language.PAYMENT_TYPE}</TableCell>
                            <TableCell align="center">{this.props.LanguageStore.language.DELIVERY}</TableCell>
                            <TableCell align="center">{this.props.LanguageStore.language.TAKEAWAY}</TableCell>
                            <TableCell align="center">{this.props.LanguageStore.language.EATIN}</TableCell>
                            <TableCell align="right">{"İşlem"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.AccountStore.accountPayRules?.map((payRule, i) => (
                            <TableRow key={i}>
                                {/*<TableCell component="th" align={"center"} scope="row">{conditionalNames(payRule.orderpayrule.payrule_type, this.props.LanguageStore.language)}</TableCell>*/}
                                <TableCell align="left">
                                    <Button
                                        className={classes.button}
                                        startIcon={conditionalIcons(payRule.orderpayrule.id)}>
                                        {payRule.orderpayrule.payrule_name}
                                    </Button>
                                </TableCell>
                                <TableCell align="center">
                                    {payRule.payrule_delivery ? (
                                        <Button
                                            className={classes.button} color={"primary"}
                                            startIcon={<CheckCircle/>}>
                                        </Button>
                                    ):(
                                        <Button
                                            className={classes.button} color={"secondary"}
                                            startIcon={<RemoveCircle/>}>
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {payRule.payrule_takeaway ? (
                                        <Button
                                            className={classes.button} color={"primary"}
                                            startIcon={<CheckCircle/>}>
                                        </Button>
                                    ):(
                                        <Button
                                            className={classes.button} color={"secondary"}
                                            startIcon={<RemoveCircle/>}>
                                        </Button>
                                    )}
                                </TableCell><TableCell align="center">
                                    {payRule.payrule_eatin ? (
                                        <Button
                                            className={classes.button} color={"primary"}
                                            startIcon={<CheckCircle/>}>
                                        </Button>
                                    ):(
                                        <Button
                                            className={classes.button} color={"secondary"}
                                            startIcon={<RemoveCircle/>}>
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="delete" onClick={() => this.setState({
                                        id: payRule.id,
                                        removePayRuleModal: true
                                    })} className={classes.margin}>
                                        <HighlightOff fontSize="small"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*Add PayRule Dialog*/}
            <Dialog open={this.state.addPayRuleModal} onClose={() => this.handleCloseModal}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_PAYMENT_METHOD}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.ADD_PAYMENT_METHOD}
                    </DialogContentText>
                    {(this.state.payRules?.length > 0) && (
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.state.payRules}
                            getOptionLabel={(option) => option.payrule_name}
                            style={{width: 300}}
                            //groupBy={(option) => conditionalNames(option.payrule_type, this.props.LanguageStore.language)}
                            getOptionSelected={(option, value) => value.id === option.id}
                            onChange={(event, newValue) => {
                                console.log("new value", newValue);
                                if (newValue?.inputValue && typeof newValue.inputValue === 'string') {
                                    //console.log("add now", newValue);
                                    this.setState({suggestThis: newValue});
                                    this.suggestTicket(newValue);
                                } else if (newValue?.id) {
                                    this.setState({payRuleId: newValue.id});
                                } else if (newValue === null) {
                                    this.setState({payRuleId: null});
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // Suggest the creation of a new value
                                if (params.inputValue !== "") {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        payrule_name: `Ekleme isteği bildir "${params.inputValue}"`
                                    });
                                }
                                return filtered;
                            }}
                            freeSolo
                            clearOnBlur
                            renderInput={(params) => <TextField {...params}
                                                                label={`${this.props.LanguageStore.language.NAME}`}
                                                                variant="outlined"/>}
                        />
                    )}
                    <FormControl component="fieldset" style={{marginTop:10} }>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={this.state.delivery} onChange={(e)=>this.setState({delivery:e.target.checked})} name="delivery" />}
                                label={this.props.LanguageStore.language.DELIVERY}
                            />
                            <FormControlLabel
                                control={<Switch checked={this.state.takeaway} onChange={(e)=>this.setState({takeaway:e.target.checked})} name="takeaway" />}
                                label={this.props.LanguageStore.language.TAKEAWAY}
                            />
                            <FormControlLabel
                                control={<Switch checked={this.state.eatin} onChange={(e)=>this.setState({eatin:e.target.checked})} name="eatin" />}
                                label={this.props.LanguageStore.language.EATIN}
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.handleCloseModal()
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button
                        disabled={!this.state.payRuleId}
                        onClick={() => {
                            this.handleAddModalConfirm()
                        }} color="primary">
                        {this.props.LanguageStore.language.SAVE}
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Remove PayRule Dialog*/}
            <Dialog open={this.state.removePayRuleModal} onClose={() => this.setState({removePayRuleModal: false})}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.LanguageStore.language.AYS}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({removePayRuleModal: false})
                    }} color="primary">
                        {this.props.LanguageStore.language.CANCEL}
                    </Button>
                    <Button
                        disabled={!this.state.id}
                        onClick={() => {
                            this.handleRemoveModalConfirm()
                        }} color="secondary">
                        {this.props.LanguageStore.language.CONFIRM}
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    }
}

const useStyles = theme => ({
    listPrimaryText: {
        width: "60%"
    },
    listSecondaryText: {
        width: "40%"
    },
});

export default withStyles(useStyles, {withTheme: true})(PayRule);
