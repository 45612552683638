import AuthStore from "./AuthStore";
import LayoutStore from "./LayoutStore";
import ProductMGroupStore from "./ProductMGroupStore";
import ProductGroupStore from "./ProductGroupStore";
import AddProductGroupStore from "./AddProductGroupStore";
import AddProductStore from "./AddProductStore";
import VariablesStore from "./VariablesStore";
import PriceGroupStore from "./PriceGroupStore";
import AccountStore from "./AccountStore";
import ActiveGroupStore from "./ActiveGroupStore";
import CopyMenuStore from "./CopyMenuStore";
import AccountRoomAndTableStore from "./AccountRoomAndTableStore";
import SurveyStore from "./SurveyStore";
import LanguageStore from "./LanguageStore";
import CopyProductStore from "./CopyProductStore";
import TicketStore from "./TicketStore";
import DataStore from "./DataStore";
import CDNStore from "./CDNStore";
import CreditStore from "./CreditStore";
import AddressStore from "./AddressStore";
import SettingsStore from "./SettingsStore";
import DocumentStore from "./DocumentStore";
import RegistrationStore from "./RegistrationStore";
import AdminStore from "./AdminStore";

export default {
    AuthStore,
    LayoutStore,
    ProductMGroupStore,
    ProductGroupStore,
    AddProductGroupStore,
    AddProductStore,
    VariablesStore,
    PriceGroupStore,
    AccountStore,
    ActiveGroupStore,
    LanguageStore,
    CopyMenuStore,
    AccountRoomAndTableStore,
    SurveyStore,
    CopyProductStore,
    TicketStore,
    CDNStore,
    DataStore,
    CreditStore,
    AddressStore,
    SettingsStore,
    DocumentStore,
    RegistrationStore,
    AdminStore
}
