import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import {Add, Delete, Edit} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {inject, observer} from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Rating from "@material-ui/lab/Rating";


@inject("SurveyStore", "LanguageStore")
@observer
class Question extends Component {
    renderQOptions(type, options, question) {
        switch(type.id) {
            case 1:
                return(<RadioGroup aria-label="quiz" name="quiz">
                    {(options.map((option)=>(<div key={option.id}>
                        <FormControlLabel  value={option.q_item_point} control={<Radio color="primary" />} label={option.q_item_value} />
                        <Typography variant="caption" gutterBottom>
                            +{option.q_item_point} points
                        </Typography></div>
                    )))}
                </RadioGroup>);
                case 2:
                return(<FormGroup >
                    {(options.map((option)=>(<div key={option.id}>
                            <FormControlLabel
                                control={<Checkbox checked={false}  name="quiz" />}
                                label={option.q_item_value}
                            />
                        <Typography variant="caption" gutterBottom>
                            +{option.q_item_point} points
                        </Typography></div>
                    )))}
                </FormGroup>);
            case 3:
                return (<div>
                    <TextField
                    margin="dense"
                    variant={"outlined"}
                    id={"q_"+question.id}
                    value={""}
                    label={question.question_label}
                    type="text"
                    placeholder={question.question_p_holder}
                    fullWidth
                />
                    <Typography variant="subtitle2" gutterBottom>
                        +{(options.q_item_point)?(options.q_item_point):(0)} points
                    </Typography>
                </div>);
            case 4:
                return (<div>
                    <TextField
                    margin="dense"
                    variant={"outlined"}
                    id={"q_"+question.id}
                    value={""}
                    multiline
                    inputProps={{maxLength: question.question_max_length}}
                    helperText={`0/${question.question_max_length}`}
                    rows={4}
                    label={question.question_label}
                    type="text"
                    placeholder={question.question_p_holder}
                    fullWidth
                />
                    <Typography variant="subtitle2" gutterBottom>
                        +{(options.q_item_point)?(options.q_item_point):(0)} points
                    </Typography>
                </div>);
            case 5:
                return (<Rating name="size-large" defaultValue={0} size="large" />);
            default:
                return 'foo';
        }
    }

    state={
        addOption:false,
        editQuestion:false,
        questionId:null,
        deleteDialog:false,
        title:"",
        description:"",
        label:"",
        placeholder:"",
        type:"-1",
        minLength:1,
        maxLength:10,
        errorMessage:"Error",
        value:"",
        point:0,
    }

    async handleSubmit() {
        console.log(this.state);
        await this.props.SurveyStore.addOption(this.props.question.id, this.state.value, this.state.point);
        await this.props.SurveyStore.getSurveyQuestions(this.props.survey.id);
        this.setState({addOption: false, value: "", point: 0});
    }
    handleUpdate(){
            console.log(this.state);
            this.props.SurveyStore.updateQuestion(this.state.questionId, this.state.type,this.state.label, this.state.title, this.state.placeholder, this.state.errorMessage, this.state.description, this.state.minLength, this.state.maxLength, this.props.survey.id);
            this.setState({
                addOption:false,
                editQuestion:false,
                questionId:null,
                deleteDialog:false,
                title:"",
                description:"",
                label:"",
                placeholder:"",
                type:"-1",
                minLength:1,
                maxLength:10,
                errorMessage:"Error",
                value:"",
                point:0,
            });
    }
    handleQuestionDelete(){
        console.log(this.state);
        this.props.SurveyStore.deleteQuestion(this.state.questionId).then(r => this.props.SurveyStore.getSurveyQuestions(this.props.survey.id));
        this.setState({addOption:false,
            editQuestion:false,
            questionId:null,
            deleteDialog:false,
            title:"",
            description:"",
            label:"",
            placeholder:"",
            type:"-1",
            minLength:1,
            maxLength:10,
            errorMessage:"Error",
            value:"",
            point:0,});
    }
    render() {
        const {classes} = this.props;
        return (<>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        #{this.props.index + 1}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {this.props.question.question_text}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {this.props.question.question_description}
                    </Typography>
                    <div>
                        {this.renderQOptions(this.props.question.question_type, this.props.question.question_items, this.props.question)}
                    </div>
                </CardContent>
                <CardActions>
                    {(this.props.question.question_type.id===1 || this.props.question.question_type.id===2)&&(<Button size="small" onClick={()=>this.setState({addOption:true})}><Add/>{this.props.LanguageStore.language.ADD_OPTION}</Button>)}
                    {<Button size="small"
                             onClick={()=>this.setState({editQuestion:true,
                                 questionId:this.props.question.id,
                                 type:this.props.question.question_type.id,
                                 title:this.props.question.question_text,
                                 description:this.props.question.question_description,
                                 label:this.props.question.question_label,
                                 placeholder:this.props.question.question_p_holder,
                                 minLength:this.props.question.question_min_length,
                                 maxLength:this.props.question.question_max_length,
                                 errorMessage:this.props.question.question_er_msg,
                             })}><Edit/>{this.props.LanguageStore.language.EDIT_QUESTION}</Button>}
                    {<Button size="small" onClick={()=>this.setState({deleteDialog:true, questionId: this.props.question.id})}><Delete/>{this.props.LanguageStore.language.DELETE_QUESTION}</Button>}
                </CardActions>
            </Card>
                {/*UPDATE QUESTION*/}
                <Dialog open={this.state.editQuestion} onClose={() => this.setState({editQuestion: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.EDIT_QUESTION}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.EDIT_QUESTION_DESC}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="title"
                            value={this.state.title}
                            onChange={(e)=>this.setState({title:e.target.value})}
                            label={this.props.LanguageStore.language.TITLE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label={this.props.LanguageStore.language.DESCRIPTION}
                            variant={"outlined"}
                            value={this.state.description}
                            onChange={(e)=>this.setState({description:e.target.value})}
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="label"
                            value={this.state.label}
                            onChange={(e)=>this.setState({label:e.target.value})}
                            label={this.props.LanguageStore.language.LABEL}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="placeholder"
                            value={this.state.placeholder}
                            onChange={(e)=>this.setState({placeholder:e.target.value})}
                            label={this.props.LanguageStore.language.PLACEHOLDER}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="error"
                            value={this.state.errorMessage}
                            onChange={(e)=>this.setState({errorMessage:e.target.value})}
                            label={this.props.LanguageStore.language.ERROR_MESSAGE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="min_length"
                            label={this.props.LanguageStore.language.MIN_LENGTH}
                            value={this.state.minLength}
                            onChange={(e)=>this.setState({minLength:e.target.value})}
                            type="number"

                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="max_length"
                            label={this.props.LanguageStore.language.MAX_LENGTH}
                            value={this.state.maxLength}
                            onChange={(e)=>this.setState({maxLength:e.target.value})}
                            type="number"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({editQuestion: false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={!(this.state.title && this.state.description && this.state.placeholder && this.state.errorMessage && this.state.label)}
                                onClick={() => this.handleUpdate()} color="primary">
                            {this.props.LanguageStore.language.UPDATE}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*ADD QUESTION OPTION*/}
                <Dialog open={this.state.addOption} onClose={()=>this.setState({addOption:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_OPTION}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.PLEASE_FILL}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="value"
                            value={this.state.value}
                            onChange={(e)=>this.setState({value:e.target.value})}
                            label="Value/Label"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="point"
                            value={this.state.point}
                            onChange={(e)=>this.setState({point:e.target.value})}
                            label={this.props.LanguageStore.language.POINT}
                            type="number"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({addOption:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={!(this.state.value.length>0)} onClick={()=>this.handleSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*DELETE CONFIRM*/}
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.deleteDialog}
                >
                    <DialogTitle id="confirmation-dialog-title">{this.props.LanguageStore.language.AYS}</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant={"body1"}>{this.props.LanguageStore.language.DELETE_QUESTION_DESC}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={()=>this.setState({deleteDialog:false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button onClick={()=>this.handleQuestionDelete(this.state.questionId)} color="primary">
                            {this.props.LanguageStore.language.DELETE}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const useStyles = theme => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

export default withStyles(useStyles, {withTheme: true})(Question);
