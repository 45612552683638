import { WebSocketLink } from '@apollo/client/link/ws';
import {ApolloClient, InMemoryCache} from "@apollo/client";
import {API_WS_URL} from "./constants";

const wsLink = new WebSocketLink({
    uri: `${API_WS_URL}`,
    options: {
        reconnect: true
    }
});

export default new ApolloClient({
    cache: new InMemoryCache(),
    link:wsLink,
});
