import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Question from "./Question";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {toJS} from "mobx";

@inject('SurveyStore', 'LanguageStore')
@observer
class Survey extends Component {

    state = {
        surveyId: null,
        addQuestion: false,
        type:"-1",
        minLength:1,
        maxLength:10,
        label:"",
        questions:[],
        errorMessage:"Error",
        title:"",
        description:"",
        placeholder:"",
    };

    sortResults(prop="id", asc=true) {
        let q;
        q=this.state.questions.sort(function(a, b) {
            if (asc) {
                return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
            } else {
                return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
            }
        });
        this.setState({questions: q});
    }
    async componentDidMount() {
        await this.props.SurveyStore.getSurvey(this.props.surveyId);
        await this.props.SurveyStore.getSurveyQuestions(this.props.surveyId);
        await this.props.SurveyStore.getQuestionTypes();
        this.setState({surveyId: this.props.surveyId, questions: this.props.SurveyStore.questions});
        //this.sortResults();
    }


    async handleDialogSubmit() {
        console.log(this.state);
        await this.props.SurveyStore.addQuestion(this.state.type, this.state.label, this.state.title, this.state.placeholder, this.state.errorMessage, this.state.description, this.state.minLength, this.state.maxLength, this.state.surveyId);
        this.setState({
            addQuestion: false,
            type: "-1",
            minLength: 1,
            maxLength: 10,
            label: "",
            errorMessage: "Error",
            title: "",
            description: "",
            placeholder: "",
        });
        await this.props.SurveyStore.getSurveyQuestions(this.props.surveyId);

    }

    render() {
        console.log(toJS(this.state.questions));
        const {classes} = this.props;
        return (
            <Grid container justify="center" alignItems="center" spacing={2}>
                <Backdrop className={classes.backdrop} open={this.props.SurveyStore.loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography align={"center"}
                                variant={'h5'}>{this.props.SurveyStore.survey.survey_title}</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography align={"center"}
                                variant={'body1'}>{this.props.SurveyStore.survey.survey_description}</Typography>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Button variant="outlined" size="large" fullWidth color="primary"
                                    onClick={() => this.setState({addQuestion: true})} className={classes.margin}>
                                {this.props.LanguageStore.language.ADD_QUESTION}
                            </Button>
                        </Grid>
                        {(this.props.SurveyStore.questions.map((question, index) => (
                            <Grid item xs={12} md={12} key={question.id}>
                                <Question question={question} index={index} survey={this.props.SurveyStore.survey}/>
                            </Grid>
                        )))}
                    </Grid>
                </Grid>

                {/*ADD QUESTION*/}
                <Dialog open={this.state.addQuestion} onClose={() => this.setState({addQuestion: false})}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.LanguageStore.language.ADD_QUESTION}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.LanguageStore.language.PLEASE_FILL}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{this.props.LanguageStore.language.TYPE}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.type}
                                onChange={(event, child)=>this.setState({type:event.target.value})}
                                label={this.props.LanguageStore.language.TYPE}
                            >
                                <MenuItem value="-1">
                                    <em>None</em>
                                </MenuItem>
                                {(this.props.SurveyStore.questionTypes.map((type)=>(
                                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                )))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="title"
                            value={this.state.title}
                            onChange={(e)=>this.setState({title:e.target.value})}
                            label={this.props.LanguageStore.language.TITLE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label={this.props.LanguageStore.language.DESCRIPTION}
                            variant={"outlined"}
                            value={this.state.description}
                            onChange={(e)=>this.setState({description:e.target.value})}
                            multiline
                            rows={4}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="label"
                            value={this.state.label}
                            onChange={(e)=>this.setState({label:e.target.value})}
                            label={this.props.LanguageStore.language.LABEL}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="placeholder"
                            value={this.state.placeholder}
                            onChange={(e)=>this.setState({placeholder:e.target.value})}
                            label={this.props.LanguageStore.language.PLACEHOLDER}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="error"
                            value={this.state.errorMessage}
                            onChange={(e)=>this.setState({errorMessage:e.target.value})}
                            label={this.props.LanguageStore.language.ERROR_MESSAGE}
                            type="text"
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="min_length"
                            label={this.props.LanguageStore.language.MIN_LENGTH}
                            value={this.state.minLength}
                            onChange={(e)=>this.setState({minLength:e.target.value})}
                            type="number"

                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            variant={"outlined"}
                            id="max_length"
                            label={this.props.LanguageStore.language.MAX_LENGTH}
                            value={this.state.maxLength}
                            onChange={(e)=>this.setState({maxLength:e.target.value})}
                            type="number"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({addQuestion: false})} color="primary">
                            {this.props.LanguageStore.language.CANCEL}
                        </Button>
                        <Button disabled={!(this.state.title && this.state.description && this.state.type!=="-1" && this.state.placeholder && this.state.errorMessage && this.state.label)}
                                onClick={() => this.handleDialogSubmit()} color="primary">
                            {this.props.LanguageStore.language.ADD}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const useStyles = theme => ({
    productImg: {
        maxWidth: 240,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    editBtn: {
        marginLeft: "90%"
    }
});

export default withStyles(useStyles, {withTheme: true})(Survey);
