import React from 'react'
import {
    HashRouter as Router,
    Route,
    Switch,
} from 'react-router-dom'
import history from './../history';
import Home from "../pages/home/Home";
import Login from "../pages/auth/Login";
import Layout from "../components/layout/Layout";
import Definitions from "../pages/home/Definitions";
import Menu from "../pages/home/Menu";
import Account from "../pages/account/Account";
import Surveys from "../pages/home/Surveys";
import NewLayout from "../components/layout/new/NewLayout";
import NewWelcome from "../pages/home/NewWelcome";
import NewDefinitions from "../pages/home/NewDefinitions";
import GroupManagement from "../components/definitions/GroupManagement";
import PriceGroupManagement from "../components/definitions/PriceGroup/PriceGroupManagement";
import RoomManagement from "../components/definitions/RoomAndTable/RoomManagement";
import Settings from "../pages/settings/Settings";
import CreateUser from "../pages/auth/CreateUser";
import ForgotPassword from "../pages/auth/ForgotPassword";
import UpdatePassword from "../pages/auth/UpdatePassword";
import RegistrationSteps from "../pages/Registration/RegistrationSteps";
import AllOrders from "../pages/admin/AllOrders";


export default () => (
    <Router history={history}>
        <Switch>
            <Route exact path='/login' render={() => <Layout toolbar={false} navbar={false} children={<Login/>}/>}/>
            <Route exact path='/forgot-password' render={() => <Layout toolbar={false} navbar={false} children={<ForgotPassword/>}/>}/>
            <Route exact path='/update-password/:email' render={() => <Layout toolbar={false} navbar={false} children={<UpdatePassword/>}/>}/>
            <Route exact path='/update-password/' render={() => <Layout toolbar={false} navbar={false} children={<UpdatePassword/>}/>}/>
            <Route exact path='/old' render={() => <Layout toolbar={true} navbar={true} children={<Home/>}/>}/>
            <Route exact path='/old/definitions' render={() => <Layout toolbar={true} navbar={true} children={<Definitions/>}/>}/>
            <Route exact path='/old/surveys' render={(props) => <Layout toolbar={true} navbar={true} children={<Surveys {...props} />}/>}/>
            <Route exact path='/old/surveys/:id' render={(props) => <Layout toolbar={true} navbar={true} children={<Surveys {...props}/>}/>}/>
            <Route exact path='/old/menu-management' render={(props) => <Layout toolbar={true} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/old/menu-management/:id' render={(props) => <Layout toolbar={true} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/old/menu-management/:id/:edit' render={(props) => <Layout toolbar={true} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/old/account' render={(props) => <Layout toolbar={true} navbar={true} children={<Account {...props}/>}/>}/>


            {/*NEW DESIGN*/}
            {/*<Route exact path='/signup-31415926543' render={() => <NewLayout toolbar={false} navbar={true} children={<CreateUser/>}/>}/>*/}
            <Route exact path='/' render={() => <NewLayout toolbar={false} navbar={true} children={<NewWelcome/>}/>}/>
            <Route exact path='/orders' render={() => <NewLayout toolbar={false} navbar={true} children={<Home/>}/>}/>
            <Route exact path='/surveys' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Surveys {...props} />}/>}/>
            <Route exact path='/surveys/:id' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Surveys {...props}/>}/>}/>
            <Route exact path='/definitions' render={() => <NewLayout toolbar={false} navbar={true} children={<NewDefinitions/>}/>}/>
            <Route exact path='/definitions/product-group-management' render={() => <NewLayout toolbar={false} navbar={true} children={<GroupManagement/>}/>}/>
            <Route exact path='/definitions/price-group-management' render={() => <NewLayout toolbar={false} navbar={true} children={<PriceGroupManagement/>}/>}/>
            <Route exact path='/definitions/room-management' render={() => <NewLayout toolbar={false} navbar={true} children={<RoomManagement/>}/>}/>

            <Route exact path='/definitions/menu-management' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/definitions/menu-management/:id' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/definitions/menu-management/:id/:edit' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Menu {...props}/>}/>}/>
            <Route exact path='/account' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Account {...props}/>}/>}/>
            <Route exact path='/settings' render={(props) => <NewLayout toolbar={false} navbar={true} children={<Settings {...props}/>}/>}/>
            <Route exact path='/registration' render={(props) => <Layout toolbar={true} navbar={true} children={<RegistrationSteps {...props}/>}/>}/>
            <Route exact path='/admin' render={(props) => <Layout toolbar={true} navbar={true} children={<AllOrders/>}/>}/>

            {/*
            <Route exact path="/admin" render={props => <Dashboard {...props} />} />
            <Route exact path="/restaurant/:id" render={props => <Menu {...props} />} />
            <Route exact path="/restaurant/product/:id" render={props => <Product {...props} />} />
            <Route exact path="/restaurant/product/option/:id" render={props => <ProductTypeTree {...props} />} />
            <Route exact path="/menu/:id/:code" render={props => <Menu {...props} />} />
            <Route exact path="/menu/:id/product/:id" render={props => <Product {...props} />} />
            <Route exact path="/menu/:id/product/option/:id" render={props => <ProductTypeTree {...props} />} />
            <Route exact path="/cart" render={props => <Cart {...props} />} />*/}
        </Switch>
    </Router>
)
