import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {
    Button,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";

@inject("RegistrationStore", "DataStore")
@observer
class AssignRole extends Component{
    async handleAssign() {
        await this.props.RegistrationStore.assignRole(2);
    }
    render() {
        return(
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{"ID"}</TableCell>
                        <TableCell align="center">{"Name"}</TableCell>
                        <TableCell align="center">{"Role"}</TableCell>
                        <TableCell align="center">{"Assign"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align={"center"}>{this.props.RegistrationStore.user?.id}</TableCell>
                        <TableCell align={"center"}>{this.props.RegistrationStore.user?.name}</TableCell>
                        <TableCell align="center">{"Account Admin"}</TableCell>
                        <TableCell align={"center"}><Button color={"primary"} variant={"contained"} onClick={()=>this.handleAssign()}>Assign</Button></TableCell>
                    </TableRow>
                </TableBody>
            </Table></TableContainer>
        );
    }
}
export default AssignRole;
