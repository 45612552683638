import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import QRCode from "qrcode.react";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {CloudDownload, Print} from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import Paper from "@material-ui/core/Paper";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


@inject('AccountStore', 'LanguageStore')
@observer
class RestaurantQrs extends Component{
    state = {
        value: 0,
        qrType:1,
    }
    handleChange = (event, newValue) => {
        this.setState({value:newValue, qrType:newValue+1});
    };

    downloadQR (id, name, type) {
        const canvas = document.getElementById("123456"+id+this.state.qrType);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${name}-${type}-QR.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    render() {
        const {classes}=this.props;
        console.log(this.props.AccountStore.account.delivery_point_neighborhood);
        return(
            <Paper className={classes.paper}>
                <Typography variant="overline"
                            gutterBottom>Restaurant QR</Typography>
                <Grid container>
                    {this.props.AccountStore.account?.delivery_point_neighborhood.length>0 && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Tabs value={this.state.value} onChange={(event, value) => {
                                this.handleChange(event, value)
                            }} aria-label="Settings">
                                {Boolean(this.props.AccountStore.account.account_delivery) && (<Tab label={this.props.LanguageStore.language.PARCEL} {...a11yProps(0)} />)}
                                {Boolean(this.props.AccountStore.account.account_takeaway) && (<Tab label={this.props.LanguageStore.language.TAKEAWAY} {...a11yProps(1)} />)}
                                {Boolean(this.props.AccountStore.account.account_eatin) && (<Tab label={this.props.LanguageStore.language.EATIN} {...a11yProps(2)} />)}
                            </Tabs>
                            <TabPanel value={this.state.value} index={0}>
                                <Grid container spacing={3}>
                                    {[this.props.AccountStore.account?.delivery_point_neighborhood[0]]?.map((neighborhood,i)=>(
                                        <Grid key={i} item>
                                            <Card  variant={"outlined"} className={classes.QRCard}>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i,this.props.AccountStore.account.account_title, this.props.LanguageStore.language.PARCEL)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                                <CardActionArea ref={el => (this.orderRefQr = el)}>
                                                    <CardContent>
                                                        <div className={classes.media} title="Print this">
                                                            <QRCode id={"123456"+i+this.state.qrType} className={classes.qrCode} size={512}
                                                                    value={`{"app_account_id":${this.props.AccountStore.account.id}, "qr_type":${this.state.qrType}, "delivery_point":${neighborhood.id}}`}/>
                                                        </div>
                                                    </CardContent>
                                                </CardActionArea>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i, this.props.AccountStore.account.account_title, this.props.LanguageStore.language.PARCEL)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <Grid container spacing={3}>
                                    {[this.props.AccountStore.account?.delivery_point_neighborhood[0]]?.map((neighborhood,i)=>(
                                        <Grid key={i} item>
                                            <Card  variant={"outlined"} className={classes.QRCard}>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i,this.props.AccountStore.account.account_title, this.props.LanguageStore.language.TAKEAWAY)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                                <CardActionArea ref={el => (this.orderRefQr = el)}>
                                                    <CardContent>
                                                        <div className={classes.media} title="Print this">
                                                            <QRCode id={"123456"+i+this.state.qrType} className={classes.qrCode} size={512}
                                                                    value={`{"app_account_id":${this.props.AccountStore.account.id}, "qr_type":${this.state.qrType}, "delivery_point":${neighborhood.id}}`}/>
                                                        </div>
                                                    </CardContent>
                                                </CardActionArea>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i,this.props.AccountStore.account.account_title, this.props.LanguageStore.language.TAKEAWAY)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={2}>
                                <Grid container spacing={3}>
                                    {[this.props.AccountStore.account?.delivery_point_neighborhood[0]]?.map((neighborhood,i)=>(
                                        <Grid key={i} item>
                                            <Card  variant={"outlined"} className={classes.QRCard}>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i,this.props.AccountStore.account.account_title, this.props.LanguageStore.language.EATIN)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                                <CardActionArea ref={el => (this.orderRefQr = el)}>
                                                    <CardContent>
                                                        <div className={classes.media} title="Print this">
                                                            <QRCode id={"123456"+i+this.state.qrType} className={classes.qrCode} size={512}
                                                                    value={`{"app_account_id":${this.props.AccountStore.account.id}, "qr_type":${this.state.qrType}, "delivery_point":${neighborhood.id}}`}/>
                                                        </div>
                                                    </CardContent>
                                                </CardActionArea>
                                                <CardActions>
                                                    <Button size="small" color="default"
                                                            startIcon={<CloudDownload />}
                                                            onClick={()=>this.downloadQR(i, this.props.AccountStore.account.account_title, this.props.LanguageStore.language.EATIN)}
                                                    >
                                                        {this.props.LanguageStore.language.DOWNLOAD}
                                                    </Button>
                                                    <ReactToPrint
                                                        trigger={() => {
                                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                            // to the root node of the returned component as it will be overwritten.
                                                            return <Button
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<Print />}
                                                            >
                                                                {this.props.LanguageStore.language.PRINT}
                                                            </Button>;
                                                        }}
                                                        content={() => this.orderRefQr}
                                                    />
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        );
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    fixedHeight: {
        height: 576,
    },
    margin: {
        margin: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    QRCard:{
        maxWidth:600,
    },
    qrCode:{
        margin:1
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default  withStyles(useStyles, {withTheme: true})(RestaurantQrs);
