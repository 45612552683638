import React, {Component} from "react";
import {Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import AsyncStorage from "@callstack/async-storage/lib";
import {toJS} from "mobx";
import {Autocomplete} from "@material-ui/lab";
import {PersonAdd, SupervisorAccount} from "@material-ui/icons";
import {withRouter} from "react-router-dom";

@inject("DataStore", "LanguageStore",'VariablesStore')
@observer
class UserType extends  Component{
    state={
        account:null,
        accounts:[],
    }
    async componentDidMount() {
        if (this.props.role === "SUPER_ADMIN") {
            await this.props.DataStore.getAccounts().then(() => this.setState({account: this.props.DataStore.accounts.find((acc)=>acc.id===parseInt(this.props.account))}))
        }
    }


    async handleAccountChange(account) {
        this.setState({account: account})
        if (account){
            await AsyncStorage.setItem('accountNo', account.id.toString());
        }
        this.refreshPage();
    }
     refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 500);
        console.log('page to reload')
    }
    render() {
        //console.log(this.state.account);
        const {classes}=this.props;
        return(
            <Grid item xs={12} md={8} lg={8}>
                <Paper style={{padding:10}}>
                    <Grid container justify={"space-evenly"}>
                        <Grid container item xs={6} lg={4} md={4} alignContent={"center"}>
                            <Typography variant={"h6"}>This is Super Admin</Typography>
                        </Grid>
                        <Grid container item alignContent={"center"} xs={6} lg={4} md={4}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={()=>this.props.history.push(`/admin`)}
                                className={classes.button}
                                startIcon={<SupervisorAccount />}
                            >
                                Administration
                            </Button>
                        </Grid>
                        <Grid item>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    value={this.state.account}
                                    onChange={(e,value)=>this.handleAccountChange(value)}
                                    options={this.props.DataStore.accounts}
                                    groupBy={(account) => account.brand.brand_name}
                                    getOptionLabel={(account) => account.account_title}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label={this.props.LanguageStore.language.ACCOUNT} variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}
const useStyles = theme => ({
    CardsContainer:{
        marginTop:90
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 160,
    },
});
export default withRouter(withStyles(useStyles, {withTheme: true})(UserType));
