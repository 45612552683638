import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {inject, observer} from "mobx-react";
import {AppBar, Button, Dialog, Grid, IconButton, Slide, TextField, Toolbar} from "@material-ui/core";
import {PinDrop} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import AddAddress from "./AddAddress";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const mapStyles={ position: "relative", height: "60vh", margin: "20px"};

@inject("LanguageStore","AddressStore","AccountStore")
@observer
class Address extends Component{

    state={
        mapModal:false,
        addAddressModal:false,
        lat:0.0,
        long:0.0
    }
    componentDidMount() {
        if (this.props.address){
            this.setState({lat:this.props.address?.address_lat, long:this.props.address?.address_long})
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.address){
            if (prevProps.address?.address_lat!==this.props.address?.address_lat){
                this.setState({lat:this.props.address?.address_lat, long:this.props.address?.address_long})
            }
        }
    }
    handleMap(){
        this.setState({mapModal:true});
    }

    handleMapClose(){
        this.setState({mapModal:false});
    }
    handleAddAddressClose(){
        this.setState({addAddressModal:false});
    }
    addMarker(location, map){
        this.setState({lat:location.lat(), long:location.lng()});
        map.panTo(location);
    }
    async handleSave() {
        await this.props.AddressStore.updateMap(this.props.address.id, this.state.lat, this.state.long).then(r => this.setState({
            mapModal: false,
            lat: 0.0,
            long: 0.0
        }));
        await this.props.AccountStore.getAccount()
    }


    modifyState = (modal) =>{
        this.setState({addAddressModal:modal})
    }

    render() {
        //console.log(toJS(this.props.address));
        const {classes}=this.props;
        return(
            <Paper className={classes.paper}>
                <Typography variant="overline"
                            gutterBottom>{this.props.LanguageStore.language.ADDRESS}</Typography>
                {this.props.address ? (
                    <List>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.NAME}:`}/>
                            <ListItemText className={classes.listPrimaryText} primary={this.props.address?.address_name}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.PHONE}:`}/>
                            <ListItemText className={classes.listPrimaryText} primary={this.props.address?.address_phone}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.ADDRESS}:`}/>
                            <ListItemText className={classes.listPrimaryText} primary={this.props.address?.address_text}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.DIRECTION}:`}/>
                            <ListItemText className={classes.listPrimaryText} primary={this.props.address?.address_direction}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.FULL_ADDRESS}:`}/>
                            <ListItemText className={classes.listPrimaryText}
                                          primary={`${this.props.address?.address_text} (${this.props.address?.address_direction})`}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listSecondaryText}  secondary={`${this.props.LanguageStore.language.COORDINATES}:`}/>
                            <ListItemText className={classes.listPrimaryText}>
                                <Button onClick={()=>this.handleMap()} startIcon={<PinDrop/>}>{`[${this.props.address?.address_long}, ${this.props.address?.address_lat}]`}</Button>
                            </ListItemText>
                        </ListItem>
                    </List>
                ):(
                    <Button variant={"outlined"} onClick={()=>this.setState({addAddressModal:true})}>ADD ADDRESS</Button>
                )}
                {/*Update Map Coordinates*/}
                <Dialog fullScreen open={this.state.mapModal} onClose={()=>this.handleMapClose()} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={()=>this.handleMapClose()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.props.LanguageStore.language.MAP}
                            </Typography>
                            <Button autoFocus color="inherit" onClick={()=>this.handleSave()}>
                                {this.props.LanguageStore.language.SAVE}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Grid container justify={"center"}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container justify={"center"} style={{padding:20}}>
                                <Grid item xs={12} md={6} lg={6}><TextField id="lat" label="Latitude" value={this.state.lat}  /></Grid>
                                <Grid item xs={12} md={6} lg={6}><TextField id="long" label="Longitude" value={this.state.long}  /></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Map
                                style={mapStyles}
                                google={this.props.google}
                                zoom={12}
                                containerStyle={{width:"100%", height:"60vh"}}
                                onClick={(t, map, c) => this.addMarker(c.latLng, map)}
                                initialCenter={{lat: (this.state.lat), lng: (this.state.long)}}
                                center={{lat: (this.state.lat), lng: (this.state.long)}}
                            >
                                <Marker
                                    onClick={this.onMarkerClick}
                                    name={this.props.account}
                                    position={{lat: (this.state.lat),
                                        lng: (this.state.long)}}
                                />
                            </Map>
                        </Grid>
                    </Grid>
                </Dialog>

                {/*Add Address*/}
                <Dialog fullScreen open={this.state.addAddressModal} onClose={()=>this.handleAddAddressClose()} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={()=>this.handleAddAddressClose()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.props.LanguageStore.language.ADDRESS}
                            </Typography>
                            {/*<Button autoFocus color="inherit" onClick={()=>this.handleSave()}>
                                {this.props.LanguageStore.language.SAVE}
                            </Button>*/}
                        </Toolbar>
                    </AppBar>
                    <AddAddress modifyParentState={this.modifyState}/>
                </Dialog>

            </Paper>
        );
    }
}
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
    },
    fixedHeight: {
        height: 576,
    },
    appBar: {
        position: 'relative',
        marginBottom:40
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    listPrimaryText:{
        width: "50%"
    },
    listSecondaryText:{
        width: "50%"
    },
});
export default GoogleApiWrapper({apiKey: "AIzaSyCGrB_QEfZdWojfjFtCFTpBC66eid47zZw"})(withStyles(useStyles, {withTheme: true})(Address));
